import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseError } from 'make-error';
import { Modal, ModalProps } from 'components/Modal';
import { Control, ControlProps } from 'components/Control/Control';
import { RenderCallback, useRenderContent, WithContentOuterProps } from 'hocs';
import { isFunction } from 'utils/helpers';

export type ModalControlBindings = {
  hide: () => void;
  setVisible: (visible: boolean) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: BaseError) => void;
};

export type ModalControlOverwriteProps = {
  initialVisible?: boolean;
  footer?: RenderCallback<ModalControlBindings> | React.ReactNode;
};

export type ModalControlModalProps = Omit<ModalProps, keyof (WithContentOuterProps & ModalControlOverwriteProps)> & ModalControlOverwriteProps;

export type ModalControlProps = ControlProps & {
  modal?: ModalControlModalProps & WithContentOuterProps<ModalControlBindings>;
};

export const ModalControl: React.FC<ModalControlProps> = (props) => {

  const { modal, ...controlProps } = props;

  const [renderContent, { initialVisible, footer, ...modalProps }] = useRenderContent(modal || {});

  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<BaseError>(null);

  useEffect(() => {
    if (initialVisible) {
      setVisible(true);
    }
  });

  const hide = useCallback(() => {
    props.modal?.onCancel?.(undefined);
    setVisible(false);
  }, [visible, setVisible]);

  const renderArgs = useMemo(() => ({ hide, setLoading, setError, setVisible }), [hide]);

  const onClick = useCallback((e: any) => {
    setVisible(true);
    setError(null);
    props.onClick?.(e);
  }, [props.onClick]);

  const title = useMemo(() => modal?.title !== undefined ? modal.title : controlProps.label, [modal?.title, controlProps.label]);

  const renderedFooter = useMemo(() => isFunction(footer) ? footer(renderArgs) : footer, [footer]);

  return (
    <>
      <Control
        {...controlProps}
        onClick={onClick}
      />
      <Modal
        {...modalProps}
        title={title}
        open={visible}
        confirmLoading={loading}
        onCancel={hide}
        destroyOnClose
        error={error}
        footer={renderedFooter}
        children={() => renderContent(renderArgs)}
      />
    </>
  );

};
