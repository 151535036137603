import React, { useEffect, useState } from 'react';
import { useSocketConnection } from '../SocketProvider';
import { useEnv } from 'store/components/injectEnv';
import makeError from 'make-error';
import constate from 'constate';
import { Maintenance } from './Maintenance';
import { useNetworkProvider } from 'providers/NetworkCheckProvider';

export const MaintenanceError = makeError('MaintenanceError');

const maintenanceContext = () => {

  const { connection, error } = useSocketConnection();
  const [isMaintenance, setIsMaintenance] = useState<boolean>(false);

  const { isConnected } = useNetworkProvider();

  const { CHECK_WEBSOCKET_MAINTENANCE, isDevelopment, isNative } = useEnv();
  const enabled = !isNative && (CHECK_WEBSOCKET_MAINTENANCE || isDevelopment);

  useEffect(() => {
    if (error && enabled && isConnected) {
      setIsMaintenance(true);
    }
  }, [error]);

  useEffect(() => {
    if (isMaintenance && error && connection) {
      window.location.reload();
    }
  }, [connection, isMaintenance, error]);

  return { isMaintenance };
};

const [MaintenanceInternalProvider, useMaintenanceProvider] = constate(maintenanceContext);

export {
  MaintenanceInternalProvider,
  useMaintenanceProvider,
};

export const MaintenanceProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <MaintenanceInternalProvider>
      <Maintenance>
        {children}
      </Maintenance>
    </MaintenanceInternalProvider>
  );
};
