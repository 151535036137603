import React, { useEffect, useState } from 'react';
import './Embed.less';
import { Alert, Container, Control, Loader } from 'components';
import cx from 'classnames';
import { downloadFile, FileType, getFileIcon, getFileType } from 'utils/file';
import messages from 'messages';
import { useIntl } from 'providers';
import { faFileDownload } from '@fortawesome/pro-light-svg-icons';
import { filter } from 'lodash';

export type EmbedProps = {
  file?: File | Promise<File>;
  className?: string;
  forceIframe?: boolean;
};

export const Embed: React.FC<EmbedProps> = (props) => {

  const { translate } = useIntl();

  const [loading, setLoading] = useState<boolean>(true);
  const [file, setFile] = useState<File>();
  const [error, setError] = useState<Error>();
  const [src, setSrc] = useState<string>(null);

  useEffect(() => {
    Promise.resolve(props.file).then(setFile).catch(setError).finally(() => setLoading(false));
  }, [props.file]);

  const renderFile = () => {

    const fileType = getFileType(file);

    const reader = new FileReader();

    reader.onload = (e) => {
      setSrc(reader.result.toString());
    };

    reader.onerror = (e) => {
      setError(reader.error);
      reader.abort();
    };

    if (props.forceIframe) {
      reader.readAsDataURL(file);
      return <embed src={src} className={'iframe'} type={'application/pdf'}/>;
    } else if (fileType === FileType.Image) {

      reader.readAsDataURL(file);

      return (
        <Container grow center className={'embedded-media'}>
          {src && <img alt={file.name} src={src}/>}
        </Container>
      );
    } else if (fileType === FileType.LDT) {
      reader.readAsText(file);

      const ldtGroups: string[][] = [[]];

      filter(src?.split(/\r?\n/)).map((line) => {
        if (line.substring(3, 4) === '8000') {
          ldtGroups.push([]);
        }

        ldtGroups[ldtGroups.length - 1].push(line);
      });

      return (
        <Container grow scrollY className={'embedded-ldt'}>
          <div className={'embedded-page'}>
            {ldtGroups.map((group, i) => (
              <div key={i} className={'embedded-block'}>
                {group.map((line, j) => (
                  <p key={j}>
                    <span>{line.substring(0, 3)}</span>
                    <span>{line.substring(3, 7)}</span>
                    <span>{line.substring(7)}</span>
                  </p>
                ))}
              </div>
            ))}
          </div>
        </Container>
      );
    } else if (fileType === FileType.HL7) {

      reader.readAsBinaryString(file);

      return (
        <Container grow scrollY scrollX className={'embedded-hl7'}>
          <Container className={'embedded-page'}>
            <Container grow scrollX className={'embedded-block'}>
              {filter(src?.replace(/\r/g, '\n').split(/\r?\n/)).map((line, i) => {
                const firstPart = line.indexOf('|');
                return (
                  <p key={i}>
                    <span>{line.substring(0, firstPart)}</span>
                    <span>{line.substring(firstPart)}</span>
                  </p>
                );
              })}
            </Container>
          </Container>
        </Container>
      );

    } else {
      return (
        <Container center>
          <Control
            button={{}}
            key={'download'}
            className={cx('download-link')}
            icon={getFileIcon(file)}
            hoverIcon={faFileDownload}
            label={<span dangerouslySetInnerHTML={{ __html: translate(messages.general.downloadFilename, { FILE: file.name }) }}/>}
            onClick={() => downloadFile(file)}
          />
        </Container>
      );
    }

  };

  return (
    <Container grow center className={cx('embed-container', props.className)} reset>
      {loading && <Loader/>}
      {error && <Alert showIcon error={error}/>}
      {file && !error && renderFile()}
    </Container>
  );

};
