import { Color } from 'interfaces';
import { OrderDigitalSignature, OrderStatus, RequirementAggregationType } from 'interfaces/api';
import { IconProps } from 'components';
import { GuardConfig } from 'containers';

export enum MissingRequirementOption {
  Form,
  Analyses,
  LeftRight,
  Intraoperative,
  Origin,
  Localization,
}

export const OrderStatusColor = {
  [OrderStatus.Canceled]: Color.LightGray,
  [OrderStatus.CanceledPool]: Color.LightGray,
  [OrderStatus.Incorrect]: Color.Red,
  [OrderStatus.ExportErrorLDT]: Color.Red,
  [OrderStatus.Saved]: Color.Gray,
  [OrderStatus.Queued]: Color.Yellow,
  [OrderStatus.Executed]: Color.Green,
  [OrderStatus.Processing]: Color.Salmon,
  [OrderStatus.PrePrint]: Color.SkyBlue,
  [OrderStatus.Amendment]: Color.Beige,
  [OrderStatus.AmendmentQueued]: [Color.Beige],
  [OrderStatus.AmendmentApproved]: [Color.Green, Color.Beige],
  [OrderStatus.PreExecution]: Color.Gray,
};

export const OrderDigitalSignatureColor = {
  [OrderDigitalSignature.NotDefined]: Color.Gray,
  [OrderDigitalSignature.Signed]: Color.Green,
  [OrderDigitalSignature.Unsigned]: Color.Yellow,
  [OrderDigitalSignature.None]: Color.Gray,
};

export type OrderAggregationConfig = {
  color: Color;
  icon: IconProps;
  type: RequirementAggregationType;
  guard?: GuardConfig;
  className?: string;
};
