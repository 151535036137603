import React from 'react';
import {
  useAnamnesisContext,
  useMandatoryDiagnoses,
  useMandatoryDialysis,
  useMandatoryWorkstation,
  useMissingFields,
  useMissingFreetext,
  useShowHints,
} from 'modules/orders/providers';
import { Modal } from 'components';
import * as Actions from './Actions';
import { useInvalidFields } from 'modules/orders/providers/OrderRulesProvider/useInvalidFields.tsx';

export const OrderRuleModal = () => {

  const { showHints } = useShowHints();
  const { missingFields } = useMissingFields();
  const { missingFreetext } = useMissingFreetext();
  const { diagnosisErrors } = useMandatoryDiagnoses();
  const { anamnesisErrors } = useAnamnesisContext();
  const { hasMissingWorkstationError } = useMandatoryWorkstation();
  const { hasMissingDialysisError } = useMandatoryDialysis();
  const { invalidFields } = useInvalidFields();

  const visible = !!showHints
    || !!missingFields
    || !!invalidFields
    || anamnesisErrors
    || !!missingFreetext
    || !!diagnosisErrors
    || hasMissingWorkstationError
    || hasMissingDialysisError
  ;

  return (
    <Modal
      open={!!visible}
      footer={null}
      closable={false}
      children={() => {
        if (missingFields) {
          return <Actions.MissingFields/>;
        } else if (anamnesisErrors) {
          return <Actions.Anamnesis/>;
        } else if (invalidFields) {
          return <Actions.InvalidFields/>;
        } else if (showHints) {
          return <Actions.Hints/>;
        } else if (missingFreetext) {
          return <Actions.Freetext/>;
        } else if (diagnosisErrors) {
          return <Actions.Diagnoses/>;
        } else if (hasMissingWorkstationError) {
          return <Actions.Workstation/>;
        } else if (hasMissingDialysisError) {
          return <Actions.BeforeAfterDialysis/>;
        }
        return null;
      }}
    />
  );

};
