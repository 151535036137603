import React from 'react';
import { withContent, WithContentInnerProps, WithContentOuterProps } from 'hocs';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApiRequest, ApiRequestUpdateBinding, ErrorBoundary, ListLayoutArgs, ListLayoutContext, ListLayoutUpdater, ResponseType } from 'containers';
import { Container, ControlGroup, ControlGroupProps, Header, HeaderProps } from 'components';
import { Message } from 'interfaces';
import { Translate } from 'providers';
import { TopBarBackLink, TopBarControlMenu } from 'containers/App/TopBar/useTopBar';
import cx from 'classnames';
import { filter } from 'lodash';

export type ListLayoutHeaderControlGroup<C> = Omit<ControlGroupProps, 'children'> & {
  controls?: ListLayoutHeaderControl<C>[];
};
export type ListLayoutHeaderControl<C> = React.ComponentType<ListLayoutArgs<C>> | ListLayoutHeaderControlGroup<C>;

export type ListLayoutDetailHeaderProps<C> = Omit<HeaderProps, 'controls'> & {
  controls?: ListLayoutHeaderControl<C>[];
};

export type ListLayoutDetailSectionProps = {
  title?: Message;
  className?: any;
} & WithContentOuterProps;

const controlIsGroup = (control: ListLayoutHeaderControl<any>): control is ListLayoutHeaderControlGroup<any> => {
  return (control as ListLayoutHeaderControlGroup<any>).controls !== undefined;
};

export type ListLayoutHeader<C, E> = (args: ListLayoutDetailRenderProps<C, E>) => ListLayoutDetailHeaderProps<C>;

export type ListLayoutDetailProps<C extends ListLayoutContext, R> = {
  request: (id: number, args?: C) => R;
  onLoaded?: (args: ListLayoutDetailRenderProps<C, ResponseType<R>>) => void;
  context?: C;
  bindUpdateItem?: (update: ListLayoutUpdater) => void;
  bindReload?: (binding: () => void) => void;
  withDelay?: boolean;
  className?: string;
  baseUrl?: string;
  topBarTitle?: Message;
  header: ListLayoutHeader<C, ResponseType<R>>;
} & WithContentOuterProps<ListLayoutDetailRenderProps<C, ResponseType<R>>>;

export type ListLayoutDetailRenderProps<Context, R = void> = {
  data: R;
} & ListLayoutArgs<Context>;

type Props<C, R> =
  & ListLayoutArgs<C>
  & ListLayoutDetailProps<C, R>
  & WithContentInnerProps<ListLayoutDetailRenderProps<C, ResponseType<R>>>
  & RouteComponentProps<{ id: string }>
  ;

export const ListLayoutDetailSectionsHorizontal = (props: { sections: ListLayoutDetailSectionProps[] }) => {
  return filter(props.sections).length > 0
    ? (
      <Container horizontal className={'list-layout-detail-sections-horizontal'}>
        {filter(props.sections).map((s, idx) => (<ListLayoutDetailSection key={idx} {...s}/>))}
      </Container>
    )
    : null;
};

export const ListLayoutDetailSection = withContent((props: ListLayoutDetailSectionProps & WithContentInnerProps) => {
  return (
    <div className={cx('list-layout-detail-section', props.className)}>
      {props.title && <h2><Translate message={props.title}/></h2>}
      {props.renderContent()}
    </div>
  );
});

class ListLayoutDetailClass<C, DetailRequest> extends React.PureComponent<Props<C, DetailRequest>> {

  _reload: () => void;
  _updateData: ApiRequestUpdateBinding<ResponseType<any>>;

  componentDidMount() {
    const { bindReload, bindUpdateItem } = this.props;
    bindUpdateItem && bindUpdateItem(this.updateItem);
    bindReload && bindReload(() => this._reload());
  }

  updateItem = (id: number, item: any) => {
    this._updateData(data => ({ ...data, ...item }));
  };

  render() {

    const { context, bindings, request, onLoaded, header, className, baseUrl, renderContent, match, withDelay, topBarTitle } = this.props;

    return (
      <TopBarBackLink backLink={{ title: topBarTitle, path: baseUrl }}>
        <ApiRequest
          bindUpdateData={(updateData) => {
            this._updateData = updateData;
          }}
          delay={withDelay ? 220 : 0}
          key={match.params.id}
          request={() => request(parseInt(match.params.id), context)}
          onLoaded={(data) => {
            onLoaded?.({ data, context, bindings });
          }}
          bindReload={(reload) => {
            this._reload = reload;
          }}
          children={({ data }) => {

            const renderArgs = { data, context, bindings };

            const mapControls = (controls: ListLayoutHeaderControl<C>[]) => (controls || []).filter(c => !!c).map((Control, index) => {
              if (controlIsGroup(Control)) {
                const { controls: childControls, ...rest } = Control;
                return <ControlGroup key={index} {...rest}>{mapControls(childControls)}</ControlGroup>;
              } else {
                return <Control key={index} {...renderArgs}/>;
              }
            });

            const { controls, ...computedHeaderProps } = header(renderArgs);
            const mappedControls = mapControls(controls);

            const headerProps: HeaderProps = {
              ...computedHeaderProps,
              backUrl: baseUrl,
              controls: mappedControls,
              hideControlsMobile: true,
            };

            return (
              <TopBarControlMenu controls={mappedControls} maxVisibleControls={headerProps.maxVisibleControls}>
                <Container grow shrink className={className}>
                  {header && <Header {...headerProps}/>}
                  <ErrorBoundary>
                    {renderContent(renderArgs)}
                  </ErrorBoundary>
                </Container>
              </TopBarControlMenu>
            );
          }}
        />
      </TopBarBackLink>
    );
  }

}

export const ListLayoutDetail = withContent(withRouter(ListLayoutDetailClass));
