import React from 'react';
import { AnamnesisForm } from 'modules/orders/components/AnamnesisForm';
import { useAnamnesisContext, useOrderRulesContext } from 'modules/orders/providers';
import messages from 'messages';
import { useIntl } from 'providers';
import { PatientDisplayName } from 'modules/orders/containers/OrderWizard/components';

const labels = messages.orders;

export const Anamnesis = () => {

  const { cancel, pending, setPending, loading } = useOrderRulesContext();
  const { anamnesisErrors } = useAnamnesisContext();

  const { translate } = useIntl();

  if (!anamnesisErrors) {
    return null;
  }

  const currentIndex = anamnesisErrors[0].index;

  const titleLabel = pending.orders.length > 1 ? labels.anamnesis.titleWithNameAndIndex : labels.anamnesis.title;

  return (
    <AnamnesisForm
      key={currentIndex}
      title={translate(titleLabel, {
        name: PatientDisplayName(pending.orders[currentIndex].patient),
        // eslint-disable-next-line id-denylist
        number: currentIndex + 1,
      })}
      loading={loading}
      order={pending.orders[currentIndex]}
      questions={anamnesisErrors.filter(e => e.index === currentIndex).map(e => e.question)}
      cancel={cancel}
      next={(anamnesis) => {
        const orders = pending.orders.map((o, idx) => ({ ...o, anamnesis: idx === currentIndex ? anamnesis : o.anamnesis }));
        setPending({ ...pending, orders, isUpsert: true });
      }}
      nextLabel={labels.anamnesis.submit}
    />
  );

};
