import React, { useMemo } from 'react';
import { ListLayoutArgs, ListLayoutSideBarSectionCallback, useGuard } from 'containers';
import { Feature, OrderListType, OrderStatus } from 'interfaces/api';
import messages from 'messages';
import { Filter, Filters } from 'components';
import { OrderStatusColor } from 'modules/orders/interfaces';
import { OrdersListContext } from 'modules/orders/index';
import { flatten, isEqual } from 'lodash';

const sampleInLabFilterLabels = messages.orders.filters.sampleInLabFilter;
const poolFilterLabels = messages.orders.filters.poolFilter;

const Component = React.memo((props: ListLayoutArgs<OrdersListContext>) => {

  const { context, bindings: { setContext } } = props;
  const guard = useGuard();

  const filters = useMemo((): Filter[] => ([
    {
      label: messages.orders.filters.status,
      filter: status => ({ status }),
      items: [
        OrderStatus.Saved,
        OrderStatus.Queued,
        OrderStatus.Executed,
        OrderStatus.Processing,
        OrderStatus.PrePrint,
        OrderStatus.Amendment,
        OrderStatus.Canceled,
        OrderStatus.Incorrect,
      ].map(s => ({
        // @ts-expect-error todo
        label: messages.orders.status[s],
        value: s,
        flag: flatten([OrderStatusColor[s]]),
      })),
    },
    {
      label: messages.general.filter.dateFrom,
      filter: dateFrom => ({ dateFrom }),
      isDate: { disableFuture: true },
    },
    {
      label: messages.general.filter.dateTo,
      filter: dateTo => ({ dateTo }),
      isDate: { disableFuture: true },
    },
    guard({ feature: Feature.SampleInLaboratory }, (): Filter => ({
      label: sampleInLabFilterLabels.label,
      filter: sampleInLab => ({ sampleInLab }),
      items: [
        { value: true, label: sampleInLabFilterLabels.only },
        { value: false, label: sampleInLabFilterLabels.not },
      ],
    })),
    guard({ feature: Feature.PoolModule }, (): Filter => ({
      label: poolFilterLabels.label,
      filter: type => ({ type }),
      items: [
        { value: OrderListType.PoolOrders, label: poolFilterLabels.only },
        { value: OrderListType.SingleOrders, label: poolFilterLabels.not },
      ],
    })),
  ]), [guard]);

  return (
    <Filters
      filters={filters}
      onFilter={value => setContext({ filters: { ...context.filters, ...value } })}
      filterValues={context.filters}
    />
  );
}, (prev, next) => isEqual(prev.context.filters, next.context.filters));

export const FiltersSection: ListLayoutSideBarSectionCallback<OrdersListContext> = ({ context }) => context.mode
  ? null
  : ({
    component: Component,
  });
