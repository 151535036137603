import React, { useState } from 'react';
import { useOrderRulesContext } from 'modules/orders/providers';
import { WorkstationSelect } from 'modules/orders/components/WorkstationSelect/WorkstationSelect';
import { Control, HelgaContainer, HelgaImageType } from 'components';
import messages from 'messages';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { useIntl } from 'providers';

export const Workstation = () => {

  const { cancel, loading, pending, setPending } = useOrderRulesContext();
  const { translate } = useIntl();

  if (!pending) {
    return null;
  }

  const [apid, setApid] = useState(pending.orders?.[0]?.apid);

  return (
    <HelgaContainer
      title={translate(messages.orders.workstations.selectLabel)}
      image={HelgaImageType.SelectAbc}
      buttons={[(
        <Control
          label={messages.general.cancel}
          onClick={cancel}
          button={{}}
        />
      ), (
        <Control
          icon={faArrowRight}
          label={messages.general.next}
          onClick={() => setPending({
            ...pending,
            isUpsert: true,
            orders: pending.orders.map(o => ({ ...o, apid, workstationChecked: true })),
          })}
          button={{
            loading,
            disabled: !apid,
            type: 'primary',
          }}
        />
      )]}
    >
      <div className={'content'}>
        <WorkstationSelect
          aid={pending.aid}
          value={apid}
          loading={loading}
          onChange={setApid}
        />
      </div>
    </HelgaContainer>
  );

};
