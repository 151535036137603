import React from 'react';
import { Control, useFileTransfer } from 'components';
import { ReportControlContext } from 'modules/reports/index';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import messages from 'messages';
import { Translate, useApi } from 'providers';
import { ReportsGetReportPdfRequest } from 'interfaces/api';

const labels = messages.reports.controls.pdf;

export const PdfControl = (label: any, pdfProps: Partial<ReportsGetReportPdfRequest>) => (props: ReportControlContext) => {

  const { reports: { getReportPdf } } = useApi();
  const { download } = useFileTransfer();

  const reports = [props.data, ...(props.data.subreports || [])];
  const bid = reports[props.context.selectedReport].bid;

  return (
    <Control
      icon={faFilePdf}
      tooltip
      label={label}
      onClick={async () => {
        await download({
          request: getReportPdf,
          data: { bid, ...pdfProps },
        });
      }}
    />
  );

};

export const Pdf = {
  Report: PdfControl(labels.export, {}),
  Original: PdfControl(labels.original, { forceOriginal: true }),
  OriginalAdditional: (forceOriginalAdditional: number, idx?: number) => PdfControl(<><Translate message={labels.originalAdditional}/> - {idx}</>, { forceOriginalAdditional }),
  OriginalExclusive: PdfControl(labels.originalExclusive, { forceOriginalExclusive: true }),
  Patient: PdfControl(labels.patient, { forcePatient: true }),
  Cumulative: PdfControl(labels.cumulative, { cumulative: true }),
  CumulativeAll: PdfControl(labels.cumulativeAll, { cumulativeAll: true }),
};
