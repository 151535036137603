import React, { useMemo } from 'react';
import { CardProps as AntCardProps, default as AntCard } from 'antd/es/card';
import './Card.less';
import { Message } from 'interfaces';
import { useIntl } from 'providers';

type OverwriteCardProps = {
  title?: Message;
  className?: string;
};

export type CardProps = Omit<AntCardProps, keyof OverwriteCardProps> & OverwriteCardProps;

export const Card: React.FC<CardProps> = (props) => {

  const { title, className, ...cardProps } = props;
  const { translate } = useIntl();

  const translatedTitle = useMemo(() => translate(title), [title]);

  return (
    <AntCard
      {...cardProps}
      className={className}
      title={translatedTitle}
    />
  );
};
