import React, { useMemo } from 'react';
import { default as AntTooltip, TooltipPlacement as AntTooltipPlacement, TooltipPropsWithTitle as AntTooltipProps } from 'antd/es/tooltip';
import './Tooltip.less';
import { Message } from 'interfaces';
import { useIntl } from 'providers';

export type TooltipPlacement = AntTooltipPlacement;

type OverwriteProps = {
  title: Message;
};

export type TooltipProps = Omit<AntTooltipProps, keyof OverwriteProps> & OverwriteProps;

export const Tooltip: React.FC<TooltipProps> = (props) => {

  const { title, ...rest } = props;

  const { translate } = useIntl();
  const translatedTitle = useMemo(() => translate(title), [title]);

  return (
    <AntTooltip
      title={translatedTitle}
      trigger={'hover'}
      {...rest}
    />
  );
};
