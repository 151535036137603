import { useCallback, useMemo } from 'react';
import { isI18nMessage, Message } from 'interfaces';
import MessageFormat from 'messageformat';
import { LanguageCode } from 'interfaces/api';

export type TranslateFunc = (message: Message, values?: Record<string, any>) => string | undefined;

export const useTranslate = (messages: Record<string, string>, locale: LanguageCode) => {

  const mf = useMemo(() => new MessageFormat(locale), [locale]);

  return useCallback<TranslateFunc>((message, values) => {

    if (!message) {
      return undefined;
    }

    if (typeof message === 'string') {
      return message;
    }

    if (isI18nMessage(message)) {

      const key = messages[message.id] || message.defaultMessage;

      if (!key) {
        return undefined;
      }

      try {
        return mf.compile(key)(values || {}).toString();
      } catch (e) {
        console.error('something is wrong with message', message);
        return key;
      }
    }

    return message as any;

  }, [messages, mf]);
};
