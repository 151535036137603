import React from 'react';
import { FormItemRenderProps } from 'components/Form/Item';
import { Feature, Gender, OrderPatientMetaProperties, OrderWizardLocalization } from 'interfaces/api';
import { Container } from 'components';
import { Guard, useGuard } from 'containers';
import { useOfficeDoctorContext } from 'modules/orders/providers';
import messages from 'messages';
import { splitEnumOptions } from 'utils/helpers';
import { getDefaultCountries, getPreferredCountries } from 'modules/orders/utils';

export const PatientDataForm: React.ComponentType<FormItemRenderProps<OrderPatientMetaProperties>> = (props) => {

  const { Input, DatePicker, Select, CountrySelect } = props;

  const { officeDoctor: { localisation } } = useOfficeDoctorContext();
  const guard = useGuard();
  const hasCountryCodeSelect = guard({ feature: Feature.CountryCodeSelect }, () => true);

  const personLabels = messages.general.person;

  return (
    <Container grid={'49%'}>

      <Input property={'title'} label={personLabels.title} maxLength={50} floating/>

      <Select
        allowClear
        floating
        label={personLabels.sex}
        property={'gender'}
        options={splitEnumOptions(Gender, messages.general.gender)}
      />

      <Input property={'firstName'} label={personLabels.firstName} maxLength={50} floating/>
      <Input property={'lastName'} label={personLabels.lastName} maxLength={50} floating/>

      {localisation !== OrderWizardLocalization.AUT && (
        <>
          <Input property={'prefix'} label={personLabels.prefix} maxLength={50} floating/>
          <Input property={'suffix'} label={personLabels.suffix} maxLength={50} floating/>
        </>
      )}

      <Input property={'insuranceNumber'} label={personLabels.insuranceNumber} maxLength={50} floating/>
      <DatePicker property={'birthday'} label={personLabels.birthday} floating withMask disableFuture/>

      {hasCountryCodeSelect
        ? (
          <CountrySelect options={getDefaultCountries()} preferred={getPreferredCountries(localisation)} property={'country'} label={personLabels.country} floating/>
        )
        : (
          <Input property={'country'} label={personLabels.country} maxLength={5} floating/>
        )}
      <Container grid={'48%'}>
        <Input property={'zipCode'} label={personLabels.zip} maxLength={10} floating/>
        <Input property={'city'} label={personLabels.city} maxLength={50} floating/>
      </Container>

      <Input property={'address'} label={personLabels.address} maxLength={100} floating/>
      <Container grid={'48%'}>
        <Input property={'houseNumber'} label={personLabels.houseNumber} maxLength={50} floating className={'oa-patient-house-number'}/>
        <Input property={'addressAdditional'} label={personLabels.addressAdditional} maxLength={100} floating/>
      </Container>

      <Guard feature={Feature.OrderWizardShowPatientContact}>
        <Input property={'phone'} label={personLabels.phone} maxLength={100} floating/>
        <Input property={'email'} label={personLabels.email} maxLength={255} floating/>
      </Guard>

    </Container>
  );

};
