import React from 'react';
import { Button, Container, Control, List, ListItem, ModalFormControl } from 'components';
import { faComment, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { ReportControlContext } from 'modules/reports/index';
import { injectApi, InjectedApiProps, Translate } from 'providers';
import { ApiRequest } from 'containers';
import './Comments.less';
import { InjectedMessagesProps, injectMessages } from 'store/components/injectMessages';
import { ReportsCreateReportCommentRequest, ReportsCreateReportCommentResponse } from 'interfaces/api';

type Props = InjectedApiProps & InjectedMessagesProps & ReportControlContext;

class CommentsClass extends React.PureComponent<Props> {

  _reload: () => void;

  render() {

    const { data: { bid, comment_count }, bindings: { updateItem }, messages } = this.props;
    const { api: { reports: { createReportComment, deleteReportComment, listReportComments } } } = this.props;

    const labels = messages.reports.controls.comments;

    return (

      <ModalFormControl
        icon={faComment}
        label={labels.label}
        badge={{ count: comment_count }}
        notifications={labels.notifications}
        keepOpen
        modal={{
          footer: null,
          scrollY: true,
        }}
        form={{
          request: value => createReportComment({ bid, data: value.data }),
          onSuccess: (value, response: ReportsCreateReportCommentResponse) => {
            updateItem(bid, { ...this.props.data, ...response });
            this._reload();
          },
          suppressControls: true,
          resetOnSuccess: true,
          initialValue: {} as ReportsCreateReportCommentRequest,
          children: ({ TextArea, submit, value }) => {

            const getControls = (listItem: ListItem) => [(
              <Control
                icon={faTrashAlt}
                key={'delete'}
                label={labels.delete.label}
                confirm={labels.delete.confirm}
                notifications={labels.delete.notifications}
                onClick={async () => {
                  await deleteReportComment({ bid, id: listItem.id as number });
                  this._reload();
                }}
              />
            )];

            const request = () => listReportComments({ bid });

            return (
              <Container>

                <Container padding>

                  <TextArea autoFocus property={'data'}/>

                  <Button type={'primary'} onClick={submit} disabled={!value.data} block className={'margin-top-1'}>
                    <Translate message={labels.form.submit}/>
                  </Button>

                </Container>

                <ApiRequest
                  staticLoader
                  bindReload={(reload) => {
                    this._reload = reload;
                  }}
                  request={request}
                  onLoaded={data => updateItem(bid, { ...this.props.data, comments: data.comments, comment_count: data.comments.length })}
                  children={({ data }) => {

                    return data.comments.length > 0
                      ? (
                        <Container>
                          <List
                            getControls={getControls}
                            items={data.comments.map((comment) => {

                              const fields = [{
                                label: comment.user_name,
                                value: this.props.formatDate(comment.created_at, { ago: true }),
                              }];

                              return {
                                fields,
                                id: comment.id,
                                title: comment.data,
                                className: 'report-comment-list-item',
                              };
                            })}
                          />
                        </Container>
                      )
                      : null;
                  }}
                />

              </Container>
            );

          },
        }}
      />
    );

  }

}

export const Comments = injectApi(injectMessages((CommentsClass)));
