import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { AutoCompleteGroups, Container, Icon, ListItem, Panel, PanelProps, Search, SearchProps, SelectedAutocomplete, SideBarIconNavItem, ToolBar } from 'components';
import cx from 'classnames';
import { Breakpoint, Message, SortOption } from 'interfaces';
import { isFunction, toggleArrayItem } from 'utils/helpers';
import { ListLayoutCustomListProps, ListLayoutList, ListLayoutListProps } from './List/ListLayoutList';
import { ListLayoutDetail, ListLayoutDetailProps } from './Detail/ListLayoutDetail';
import { AutocompleteTag, ListLayoutSideBar, ListLayoutSideBarProps } from './SideBar/SideBar';
import { faSlidersH } from '@fortawesome/pro-regular-svg-icons';
import { Toggle } from 'hooks';
import { PaginationResponse, Translate } from 'providers';
import { Footer } from 'containers/App/Footer';
import { filter, map } from 'lodash';
import './ListLayout.less';
import { PullToRefresh, PullToRefreshRefArgs } from 'components/PullToRefresh/PullToRefresh';

export interface ListLayoutContextFilters {
  query?: string;
}

export interface ListLayoutContext<Filters extends ListLayoutContextFilters = any, Mode = any> {
  sortBy?: SortOption;
  filters?: Filters;
  autocomplete?: SelectedAutocomplete;
  selected?: ListItem[];
  data?: ListItem[];
  mode?: Mode;
  aids?: number[];
}

export type ListLayoutState<Context extends ListLayoutContext> = {
  context?: Context;
  sideBarToggled?: boolean;
};

export type ListLayoutContextSetter<Context extends ListLayoutContext> =
  (context: Context, reset?: boolean, callback?: () => void) => void;

export type ListLayoutBindings<Context extends ListLayoutContext> = {
  onSearch: (value: any) => void;
  toggleSideBar: () => void;
  updateItem: ListLayoutUpdater;
  setContext: ListLayoutContextSetter<Context>;
  onSelect: (item?: ListItem, multi?: boolean) => void;
  reload: () => void;
  reloadList: () => void;
  reloadDetail: () => void;
  onAutoComplete: (autocomplete: SelectedAutocomplete) => void;
  setListView: () => void;
  setDetailView: (id: number | string) => void;
};

export type ListLayoutArgs<C extends ListLayoutContext> = {
  context?: C;
  bindings?: ListLayoutBindings<C>;
};

export type ListLayoutPanelCallback<C> = (args: ListLayoutArgs<C>) => Omit<PanelProps, 'controls'> & {
  controls: React.ComponentType<ListLayoutArgs<C>>[];
};

export type ListLayoutUpdater = (id: number | string, data?: any) => void;

export type ListLayoutHeaderFunction<Context> = (args: ListLayoutArgs<Context>) => Message;

export type ListLayoutMode<Context extends ListLayoutContext> = Omit<SideBarIconNavItem, 'active' | 'onClick'> & {
  type: Context['mode'] | undefined;
  onSelect?: (args: ListLayoutArgs<Context>) => Partial<Context>;
};

export type ListLayoutProps<Context extends ListLayoutContext, Entity, ListRequest, DetailRequest> = {

  context?: Context;

  className?: string;

  search?: Partial<SearchProps>;

  header?: Message | ListLayoutHeaderFunction<Context>;

  list: ListLayoutListProps<Context, Entity, ListRequest>;
  sideBar?: ListLayoutSideBarProps<Context>;
  detail?: ListLayoutDetailProps<Context, DetailRequest>;
  toolBar?: (args: ListLayoutArgs<Context>) => React.ReactNode;
  panel?: ListLayoutPanelCallback<Context>;

  setRef?: (args: ListLayoutArgs<Context>) => void;

  withFooter?: boolean;

} & ListLayoutState<Context>;

type Props<C, E, LR, DR> = ListLayoutProps<C, E, LR, DR> & RouteComponentProps;

class ListLayoutClass<C extends ListLayoutContext, E, LR, DR> extends React.PureComponent<Props<C, E, LR, DR>, ListLayoutState<C>> {

  // static whyDidYouRender = true;

  _bindings: ListLayoutBindings<C>;

  _pullToRefreshRef: PullToRefreshRefArgs;

  _linkTo: (selected: ListItem) => string;

  _reloadList: () => void;

  _reloadDetail: () => void;

  _updateEntity: ListLayoutUpdater;

  _updateListItem: ListLayoutUpdater;

  _lastWasSearch: boolean;

  constructor(props: Props<C, E, LR, DR>) {

    super(props);

    let filters: any = props.context?.filters || {};
    filters.query = '';
    let autocomplete = props.context?.autocomplete;

    if (props.location.state) {
      map(AutoCompleteGroups, (autocompleteFilter, group) => {

        // @ts-expect-error todo
        const item = props.location.state[group];

        if (item && !isFunction(autocompleteFilter)) {
          filters = autocompleteFilter.filter(item);
          autocomplete = { group, item, filter: filters };
        }

      });
    }

    if (autocomplete) {
      this._lastWasSearch = true;
    }

    this.state = {
      context: {
        filters,
        autocomplete,
        ...props.context,
        selected: props.list.selected || [],
        aids: [],
      },
    } as any;

    this._bindings = {
      onSearch: this.onSearch,
      toggleSideBar: this.toggleSideBar,
      updateItem: this.updateItem,
      setContext: this.setContext,
      onSelect: this.onSelect,
      reload: this.reload,
      reloadList: this.reloadList,
      reloadDetail: this.reloadDetail,
      onAutoComplete: this.onAutoComplete,
      setListView: this.setListView,
      setDetailView: this.setDetailView,
    };
    this._pullToRefreshRef = undefined;

    this._linkTo = props.detail ? (item: ListItem) => props.match.url + '/' + item.id : undefined;
  }

  toggleSideBar = () => {
    this.setState({ sideBarToggled: !this.state.sideBarToggled });
  };

  reload = () => {
    this.reloadList();
    this.reloadDetail();
  };

  reloadList = () => {
    this._reloadList && this._reloadList();
    this.setContext({ selected: [] as ListItem[], data: undefined } as C);
  };

  reloadDetail = () => {
    this._reloadDetail && this._reloadDetail();
  };

  setContext: ListLayoutContextSetter<C> = (newContext, reset, callback?: () => void) => {
    this.setState({ context: { ...(reset ? { ...this.props.context, selected: [] } : this.state.context), ...newContext } } as any, callback);
  };

  updateItem: ListLayoutUpdater = (id, data) => {
    this._updateEntity && this._updateEntity(id, data);
    this._updateListItem && this._updateListItem(id, data);
  };

  onSearch = (value: any) => {

    let filters = { ...this.state.context.filters };

    if (typeof value === 'string') {
      filters = Object.assign({}, filters, { query: value });
      this._lastWasSearch = true;
    } else {
      if (value.query) {
        filters = Object.assign({}, filters, { query: value.query });
      } else {
        filters = Object.assign({}, filters, value);
      }
    }

    this.setState({ context: { ...this.state.context, filters, autocomplete: undefined } }, () => {
      if (this.isDetailView()) {
        this.setListView();
      }
    });

  };

  onSelect = (item?: ListItem, multi?: boolean) => {

    let selected: ListItem[] = [];

    if (item) {
      selected = multi ? toggleArrayItem(this.state.context.selected, item, i => i.id) : [item];
    }

    this.setState({ context: { ...this.state.context, selected } });

    const { onSelect } = this.props.list;
    onSelect?.(item);
  };

  bindReloadList: (reload: () => void) => void = (reload) => {
    this._reloadList = reload;
  };

  bindReloadDetail: (reload: () => void) => void = (reload) => {
    this._reloadDetail = reload;
  };

  bindUpdateItem: (update: ListLayoutUpdater) => void = (updateItem) => {
    this._updateEntity = updateItem;
  };

  bindUpdateListItem: (update: ListLayoutUpdater) => void = (updateItem) => {
    this._updateListItem = updateItem;
  };

  onAutoComplete = (autocomplete: SelectedAutocomplete) => {
    this._lastWasSearch = true;
    this.setContext({ autocomplete } as C, false);
  };

  isDetailView = () => {
    const { match: { url }, location: { pathname }, detail } = this.props;
    return !!detail && pathname.length > url.length;
  };

  setListView = () => {
    this.props.history.replace(this.props.match.url);
  };

  setDetailView = (id: number | string) => {
    this.props.history.replace(this.props.match.url + '/' + id);
  };

  onListLoaded = (data: PaginationResponse<E> | E[], context?: C, listProps?: ListLayoutCustomListProps) => {

    const { list, detail } = this.props;

    if (this._lastWasSearch && context.data.length === 1 && !!detail && !list.onSelect && listProps.linkTo) {
      this.setDetailView(context.data[0].id);
    }

    this._lastWasSearch = false;
    list.onLoaded?.(data, context, listProps);
    this._pullToRefreshRef?.onRefreshDone();
  };

  render() {

    const { match: { url }, className, detail, sideBar, panel, toolBar, search, header, setRef } = this.props;
    const { context } = this.state;

    // const wasDetailView = this.props.previous?.location.pathname.length > this.props.previous?.match.url.length;
    // const getsDetailView = pathname.length > url.length;

    const containerSideBarProps = {
      toggle: [this.state.sideBarToggled, this.toggleSideBar] as Toggle,
      breakpoint: Breakpoint.DesktopDown,
    };

    const args = { context, bindings: this._bindings };

    setRef?.(args);

    const panelProps = panel ? panel(args) : undefined;

    const mergedSearchProps: SearchProps = search
      ? {
        onSearch: this.onSearch,
        onAutocomplete: this.onAutoComplete,
        ...search,
      }
      : undefined;

    const toolBarRightContent = toolBar?.(args);

    const headerMessage = isFunction(header) ? header(args) : header;

    const toolBarContent = (
      <>

        <Container className={'toolbar-left'}>

          {headerMessage && (
            <h1 onClick={sideBar ? this._bindings.toggleSideBar : undefined}>
              <Icon icon={faSlidersH} className={'sidebar-toggle is-hidden-desktop-xl-up'}/>
              <Translate message={headerMessage}/>
            </h1>
          )}

          {!sideBar && search && (
            context.autocomplete
              ? (
                <AutocompleteTag {...args}/>
              )
              : (
                <Search {...mergedSearchProps} />
              ))}

        </Container>

        {toolBarRightContent && (
          <Container horizontal className={'toolbar-right'}>
            {toolBarRightContent}
          </Container>
        )}

      </>
    );

    const handleRefresh = (): void => {
      this._bindings.reload();
    };

    return (
      <Container horizontal grow className={cx('list-layout', { 'list-layout-is-detail-view': this.isDetailView() }, className)}>

        <Container grow shrink horizontal>

          {sideBar && <ListLayoutSideBar context={context} bindings={this._bindings} search={mergedSearchProps} {...containerSideBarProps} {...sideBar} />}

          <Container className={'list-layout-content'} grow shrink withSideBar={containerSideBarProps}>

            <Container grow shrink horizontal reset>

              <Container grow className={cx('list-layout-list-container', { 'list-layout-has-detail': !!detail })}>

                <PullToRefresh onRefresh={handleRefresh} setRef={(args: PullToRefreshRefArgs) => this._pullToRefreshRef = args}>

                  <ToolBar>
                    {toolBarContent}
                  </ToolBar>

                  <ListLayoutList
                    bindReload={this.bindReloadList}
                    bindUpdateItem={this.bindUpdateListItem}
                    context={context}
                    bindings={this._bindings}
                    fixedPrimaryColumnWidth={!!detail || this.props.list.fixedPrimaryColumnWidth}
                    backUrl={detail ? url : undefined}
                    groupBy={context.sortBy ? context.sortBy.grouping : this.props.list.groupBy}
                    linkTo={this._linkTo}
                    {...this.props.list}
                    onLoaded={this.onListLoaded}
                  />

                  {panelProps && (
                    <Panel
                      {...panelProps}
                      className={'list-layout-panel'}
                      controls={filter(panelProps.controls).map((PanelControl, index) => (
                        <PanelControl key={index} {...args} />
                      ))}
                    />
                  )}

                </PullToRefresh>

              </Container>

              {detail && (
                <Container grow shrink className={'list-layout-detail-container'}>
                  <Switch>
                    <Route
                      path={url + '/:id'}
                      render={() => (
                        <ListLayoutDetail
                          {...detail as any}
                          context={context}
                          bindings={this._bindings}
                          // withDelay={!wasDetailView && getsDetailView}
                          baseUrl={detail ? url : undefined}
                          {...this._bindings}
                          bindReload={this.bindReloadDetail}
                          bindUpdateItem={this.bindUpdateItem}
                          topBarTitle={headerMessage}
                        />
                      )}
                    />
                  </Switch>
                </Container>
              )}

            </Container>

            {this.props.withFooter && <Footer/>}

          </Container>
        </Container>

      </Container>
    );

  }

}

const ListLayoutComponent = withRouter(ListLayoutClass);

export const ListLayout = <Context, Entity, ListRequest, DetailRequest>(props: ListLayoutProps<Context, Entity, ListRequest, DetailRequest>) => {
  return <ListLayoutComponent {...props} />;
};
