import React, { useMemo } from 'react';
import { useIntl } from './IntlProvider';
import { FormatPriceOptions } from './useFormatPrice';

interface ComponentProps {
  price: number | string;
  options?: FormatPriceOptions;
}

export const FormatPrice: React.FC<ComponentProps> = ({ price, options }) => {
  const { formatPrice } = useIntl();
  const formattedPrice = useMemo(() => formatPrice(price, options), [price, options]);
  return (<>{formattedPrice}</>);
};
