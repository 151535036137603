import React from 'react';
import { Control, Form, HelgaContainer, HelgaImageType } from 'components';
import messages from 'messages';
import { useMissingFields, useOrderRulesContext } from 'modules/orders/providers';
import { filter, fromPairs, get, groupBy, map } from 'lodash';
import { ApiRequest, FormLayout } from 'containers';
import {
  AccountingArea,
  Gender,
  InsuranceType,
  KbvCovidLegalBasis,
  KbvCovidLocation,
  KbvCovidLocationFacility,
  KbvCovidRvoManualOptions,
  KbvCovidTest,
  OrderRulesMandatoryField,
  WriteableOrderPropertiesSchema,
} from 'interfaces/api';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { splitEnumOptions } from 'utils/helpers';
import { useApi, useIntl } from 'providers';
import { useScheduleDatePickerAttributes } from 'modules/orders/components/ScheduleDatePicker';

export const MissingFields = () => {

  const { missingFields, updateValues } = useMissingFields();
  const { cancel, loading, pending } = useOrderRulesContext();

  const { translate } = useIntl();

  const additionalFieldsLabels = messages.orders.additionalFields;
  const personLabels = messages.general.person;

  const { orderWizardAustriaSettings: { getAustriaHvCodes, getAustriaInsuranceCategories } } = useApi();
  const scheduledAtAttributes = useScheduleDatePickerAttributes();

  if (!missingFields) {
    return null;
  }

  return (
    <Form
      suppressControls
      disabled={loading}
      initialValue={fromPairs(missingFields.map(m => [m.index + '.' + m.field]))}
      request={values => updateValues(missingFields.map((m) => {
        return ({ ...m, value: values[m.index + '.' + m.field] });
      }))}
    >
      {({ submit, Input, Select, DatePicker, value }) => (
        <HelgaContainer
          image={HelgaImageType.Report}
          title={messages.orders.missing}
          buttons={[(
            <Control
              label={messages.general.cancel}
              onClick={cancel}
              button={{}}
            />
          ), (
            <Control
              icon={faArrowRight}
              label={messages.general.next}
              onClick={submit}
              disabled={filter(value, v => v === undefined || v === null || !(value + '').length).length > 0}
              button={{
                loading,
                type: 'primary',
              }}
            />
          )]}
        >

          {map(groupBy(missingFields, 'index'), (fields, index) => (

            // eslint-disable-next-line id-denylist
            <FormLayout key={index} embedded narrowLabels label={pending.orders.length > 1 ? translate(messages.orders.orderNumber, { number: parseInt(index) + 1 }) : ''}>

              {fields.map((m) => {

                const property = m.index + '.' + m.field;
                const legalOrderBasis = parseInt(value[m.index + '.covid.legalOrderBasis']);

                switch (m.field) {

                  case OrderRulesMandatoryField.Gender:
                    return (
                      <Select
                        key={property}
                        property={property}
                        label={translate(personLabels.sex)}
                        placeholder={translate(personLabels.sex)}
                        options={splitEnumOptions(Gender, messages.general.gender)}
                      />
                    );

                  case OrderRulesMandatoryField.Birthday:
                    return (
                      <DatePicker
                        key={property}
                        property={property}
                        label={translate(personLabels.birthday)}
                        placeholder={translate(personLabels.birthday)}
                      />
                    );

                  case OrderRulesMandatoryField.ScheduleDate:
                    return (
                      <DatePicker
                        key={property}
                        property={property}
                        label={additionalFieldsLabels.scheduledDate}
                        placeholder={translate(additionalFieldsLabels.scheduledDate)}
                        {...scheduledAtAttributes}
                      />
                    );

                  case OrderRulesMandatoryField.AccountingArea:
                    return (
                      <Select
                        key={property}
                        property={property}
                        label={translate(additionalFieldsLabels.accountingArea)}
                        placeholder={translate(additionalFieldsLabels.accountingArea)}
                        options={splitEnumOptions(AccountingArea, additionalFieldsLabels.accountingAreaTypes)}
                      />
                    );

                  case OrderRulesMandatoryField.InsuranceType:
                    return (
                      <Select
                        key={property}
                        property={property}
                        label={translate(additionalFieldsLabels.patientInsuranceType)}
                        placeholder={translate(additionalFieldsLabels.patientInsuranceType)}
                        options={splitEnumOptions(InsuranceType, additionalFieldsLabels.patientInsuranceTypes)}
                      />
                    );

                  case OrderRulesMandatoryField.HvCode:
                    return (
                      <ApiRequest
                        request={() => getAustriaHvCodes()}
                        errorAsAlert
                        children={({ data }) => (
                          <Select
                            key={property}
                            property={property}
                            label={translate(additionalFieldsLabels.hvcode)}
                            placeholder={translate(additionalFieldsLabels.hvcode)}
                            options={data.filter(h => h.costUnit === pending.orders[m.index].costUnit).map(hvcode => ({
                              label: hvcode.costUnit + ' - ' + hvcode.title,
                              value: hvcode.code,
                            }))}
                          />
                        )}
                      />
                    );

                  case OrderRulesMandatoryField.VKat:
                    return (
                      <ApiRequest
                        errorAsAlert
                        request={() => getAustriaInsuranceCategories()}
                        children={({ data }) => (
                          <Select
                            key={property}
                            property={property}
                            label={translate(additionalFieldsLabels.vkat)}
                            placeholder={translate(additionalFieldsLabels.vkat)}
                            options={data.map(vcat => ({ label: vcat.title, value: vcat.code }))}
                          />
                        )}
                      />
                    );

                  case OrderRulesMandatoryField.TestNumber:
                    return (
                      <Select
                        key={property}
                        property={property}
                        label={translate(additionalFieldsLabels.patient.covid.testNumber)}
                        placeholder={translate(additionalFieldsLabels.patient.covid.testNumber)}
                        options={splitEnumOptions(KbvCovidTest, messages.general.kbvCovidTest)}
                      />
                    );

                  case OrderRulesMandatoryField.LegalOrderBasis:
                    return (
                      <Select
                        key={property}
                        property={property}
                        label={translate(additionalFieldsLabels.patient.covid.legalOrderBasis)}
                        placeholder={translate(additionalFieldsLabels.patient.covid.legalOrderBasis)}
                        options={splitEnumOptions(KbvCovidLegalBasis, messages.general.kbvCovidLegalBasis)}
                      />
                    );

                  case OrderRulesMandatoryField.LegalReason:
                    return (
                      <Select
                        key={property}
                        property={property}
                        label={translate(additionalFieldsLabels.patient.covid.legalReason)}
                        placeholder={translate(additionalFieldsLabels.patient.covid.legalReason)}
                        options={splitEnumOptions(KbvCovidRvoManualOptions, messages.general.kbvCovidRvo)}
                        disabled={legalOrderBasis !== KbvCovidLegalBasis.RvoTestV}
                      />
                    );

                  case OrderRulesMandatoryField.KvSpecial:
                    return (
                      <Input
                        key={property}
                        property={property}
                        label={messages.orders.fields[m.field]}
                        maxLength={(get(WriteableOrderPropertiesSchema, m.field) as any)?.maxLength}
                        disabled={legalOrderBasis !== KbvCovidLegalBasis.RegionalSpecialAgreement}
                      />
                    );

                  case OrderRulesMandatoryField.RiskCharacteristicsLocation:
                    return (
                      <>
                        <Select
                          key={property}
                          property={property}
                          label={translate(additionalFieldsLabels.patient.covid.riskCharacteristicsLocation)}
                          placeholder={translate(additionalFieldsLabels.patient.covid.riskCharacteristicsLocation)}
                          options={splitEnumOptions(KbvCovidLocation, messages.general.kbvCovidLocation)}
                        />
                        <Select
                          key={property}
                          property={property}
                          label={translate(additionalFieldsLabels.patient.covid.riskCharacteristicsLocationType)}
                          placeholder={translate(additionalFieldsLabels.patient.covid.riskCharacteristicsLocationType)}
                          options={splitEnumOptions(KbvCovidLocationFacility, messages.general.kbvCovidLocationType)}
                        />
                      </>
                    );

                  default:
                    return (
                      <Input
                        key={property}
                        property={property}
                        label={messages.orders.fields[m.field]}
                        maxLength={(get(WriteableOrderPropertiesSchema, m.field) as any)?.maxLength}
                      />
                    );
                }

              })}

            </FormLayout>

          ))}

        </HelgaContainer>
      )}
    </Form>
  );

};
