import React, { useEffect, useState } from 'react';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { useAsyncFn, useInterval } from 'react-use';
import { Loader, useWarningModal } from 'components';
import { useOnAwake } from 'hooks';
import { useIntl } from 'providers/IntlProvider';
import messages from 'messages';
import { useEnv } from 'store/components/injectEnv';
import * as Sentry from '@sentry/browser';

type UpdateAvailableInfo = {
  currentVersion?: string;
  availableVersion?: string;
  available: boolean;
};

export const AppUpdateProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const [updateAvailable, setUpdateAvailable] = useState<UpdateAvailableInfo>();
  const { translate } = useIntl();
  const { isNative, isDevelopment } = useEnv();

  const [updateInfo, getUpdateInfo] = useAsyncFn(async () => {
    if (isNative && !updateAvailable && !isDevelopment) {
      try {
        const { currentVersion, availableVersion, updateAvailability } = await AppUpdate.getAppUpdateInfo({ country: 'DE' });
        if (updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
          setUpdateAvailable({ currentVersion, availableVersion, available: true });
        } else {
          setUpdateAvailable({ available: false });
        }
      } catch (e) {
        Sentry.captureException(e);
        setUpdateAvailable({ available: false });
      }
    }
  }, [updateAvailable]);

  useEffect(() => {
    getUpdateInfo().catch(console.error);
  }, []);

  useInterval(() => getUpdateInfo(), 6e3);
  useOnAwake(() => getUpdateInfo(), 2e3);

  const labels = messages.general.updateAvailableModal;
  const warningModal = useWarningModal();

  useEffect(() => {
    if (updateAvailable && updateAvailable.available) {
      warningModal({
        title: translate(labels.title),
        content: translate(labels.content, updateAvailable),
        okText: translate(labels.updateNow),
        onOk: (close) => {
          AppUpdate.openAppStore({ country: 'AT' }).catch(console.error);
          return false;
        },
        closable: false,
      });
    }
  }, [updateAvailable]);

  if (updateInfo.loading && updateAvailable === undefined) {
    return <Loader/>;
  }

  return <>{children}</>;

};
