import React from 'react';
import { OrderFormPrintMode, UserType } from 'interfaces/api';
import { Control } from 'components';
import { useGuard } from 'containers';
import { useEnv } from 'store/components/injectEnv';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import messages from 'messages';
import { useApi } from 'providers';
import { OrderControlContext } from 'modules/orders/index';
import { ChooseWorkstationControl } from 'modules/orders/components/ChooseWorkstationControl';

const labels = messages.orders.controls.printOrderForm;

export const PrintOrderForm = (props: OrderControlContext) => {

  const { print: { printOrderForm } } = useApi();
  const { data: { id, orders, doctor: { aid, socketPrint } }, context: { selectedOrder } } = props;
  const orderId = (orders[selectedOrder || 0])?.id || id;
  const apid = (orders[selectedOrder || 0])?.apid;
  const guard = useGuard();
  const { BACKEND_URL } = useEnv();

  const isDoctor = guard({ permitted: UserType.ARZ }, () => true);

  return isDoctor && socketPrint
    ? (
      <ChooseWorkstationControl
        icon={faPrint}
        label={labels.label}
        notifications={labels.notifications}
        aid={aid}
        defaultSelected={apid}
        onDone={async (apid) => {
          await printOrderForm({ id: orderId, mode: OrderFormPrintMode.PrintToDb, workstation: apid });
        }}
      />
    )
    : (
      <Control
        icon={faPrint}
        tooltip
        label={labels.label}
        onClick={e => window.open(BACKEND_URL + `/api/orders/${orderId}/print/orderform?mode=sendtobrowser`, '_blank')}
      />
    );
};
