import React from 'react';
import { FormItemRenderProps } from 'components/Form/Item';
import { AccountingArea, ExceptionalIndication, InsuranceType, OrderReason, WriteableOrderProperties } from 'interfaces/api';
import { Container } from 'components';
import messages from 'messages';
import { splitEnumOptions } from 'utils/helpers';
import styles from 'modules/orders/containers/OrderWizard/components/PatientForm/styles.module.less';
import { useOfficeDoctorContext } from 'modules/orders/providers';

export const PatientInsuranceForm: React.ComponentType<FormItemRenderProps<WriteableOrderProperties>> = (props) => {

  const { Property, Switch, disabled } = props;

  const { kisDisabled } = useOfficeDoctorContext();

  const labels = messages.orders.additionalFields;

  return (
    <Container grid={'100%'}>
      <Container grid={'49%'}>

        <Property property={'patient'} disabled={kisDisabled || disabled}>
          {({ Input, DatePicker, Select }) => (
            <>
              <DatePicker property={'insuranceStart'} label={labels.insuranceStart} floating withMask disableFuture/>
              <DatePicker property={'insuranceEnd'} label={labels.insuranceEnd} floating withMask/>
              <Select
                floating
                label={labels.accountingArea}
                property={'accountingArea'}
                options={splitEnumOptions(AccountingArea, labels.accountingAreaTypes)}
              />
              <Select
                floating
                label={labels.exceptionalIndication}
                property={'exceptionalIndication'}
                options={splitEnumOptions(ExceptionalIndication, labels.exceptionalIndicationTypes)}
              />
              <Select
                floating
                label={labels.patientInsuranceType}
                property={'insuranceType'}
                options={splitEnumOptions(InsuranceType, labels.patientInsuranceTypes)}
              />
              <Input property={'sktIdentifier'} label={labels.sktIdentifier} maxLength={50} floating/>
              <Input property={'wopIdentifier'} label={labels.wopIdentifier} maxLength={50} floating/>
              <Input property={'costUnitIdentification'} label={labels.costUnitIdentification} maxLength={25} floating/>
            </>
          )}
        </Property>

      </Container>

      <Container grid={'100%'}>
        <Property property={'patient'}>
          {({ Radio }) => (
            <Radio
              property={'orderReason'}
              values={splitEnumOptions(OrderReason, labels.orderReasons)}
              className={styles.radio}
              optionType="button"
              buttonStyle="solid"
            />
          )}
        </Property>
      </Container>

      <Container grid={'49%'}>
        <Switch property={'accident'} label={labels.accident}/>
        <Switch property={'controlCheckupKnownInfection'} label={labels.controlCheckupKnownInfection}/>
        <Switch property={'dz116b'} label={labels._par116b_sgb_v}/>
        <Switch property={'dz16b'} label={labels._par16_3a_sbg_v}/>
      </Container>
    </Container>
  );

};
