import { useCallback, useMemo } from 'react';
import { OrderRuleActionType, OrderRulesMandatoryField, WriteableOrderProperties } from 'interfaces/api';
import { useOrderRulesContext } from './OrderRulesProvider';
import { useOrdersContext } from 'modules/orders/providers/OrdersProvider';
import { difference, get, sum } from 'lodash';
import { isEmptyValue, splitEnumOptions } from 'utils/helpers';
import { useOfficeDoctorContext } from 'modules/orders/providers/OfficeDoctorProvider';

export const additionalFieldsMandatoryFields = [
  OrderRulesMandatoryField.PatientHeight,
  OrderRulesMandatoryField.PatientWeight,
  OrderRulesMandatoryField.PregnancyWeek,
  OrderRulesMandatoryField.SamplePeriod,
  OrderRulesMandatoryField.SampleAmount,
  OrderRulesMandatoryField.InsuranceName,
  OrderRulesMandatoryField.ScheduleDate,
  OrderRulesMandatoryField.CostUnit,
  OrderRulesMandatoryField.HvCode,
  OrderRulesMandatoryField.VKat,
];

export const standaloneMandatoryFields = [
  OrderRulesMandatoryField.Tnr,
  OrderRulesMandatoryField.Diagnosis,
];

export const allMandatoryFields: OrderRulesMandatoryField[] = difference(
  splitEnumOptions(OrderRulesMandatoryField).map(f => f.value),
  standaloneMandatoryFields,
);

const patientMandatoryFields: OrderRulesMandatoryField[] = difference(
  allMandatoryFields,
  additionalFieldsMandatoryFields,
);

export const useMandatoryFields = () => {

  const { getActionsByType } = useOrderRulesContext();

  const { currentOrder, orders } = useOrdersContext();

  const { officeDoctor: { localisation } } = useOfficeDoctorContext();

  // all mandatory fields from result
  const mandatoryFields: OrderRulesMandatoryField[] = useMemo(
    () => getActionsByType(OrderRuleActionType.MandatoryField)?.map(f => f.field) || [],
    [getActionsByType, currentOrder?.costUnit, localisation],
  );

  const getMandatoryFieldsForOrder = useCallback((order: WriteableOrderProperties, checkFields = allMandatoryFields) => {
    return checkFields.filter(field => mandatoryFields.includes(field) && isEmptyValue(get(order, field)));
  }, [mandatoryFields]);

  const getMandatoryPatientFieldsForOrder = useCallback(
    (order: WriteableOrderProperties) => getMandatoryFieldsForOrder(order, patientMandatoryFields),
    [getMandatoryFieldsForOrder],
  );

  const showAdditionalInfoWarningBadge = useMemo(
    () => currentOrder && !!getMandatoryFieldsForOrder(currentOrder, additionalFieldsMandatoryFields).length,
    [getMandatoryFieldsForOrder, currentOrder],
  );

  const showPatientWarningBadge = useMemo(
    () => currentOrder
      ? !!getMandatoryPatientFieldsForOrder(currentOrder).length
      : !!sum(orders.map(order => getMandatoryFieldsForOrder(order).length)),
    [currentOrder, orders, getMandatoryPatientFieldsForOrder, getMandatoryFieldsForOrder],
  );

  const showPatientWarningBadgeForPoolOrder = useCallback(
    (order: WriteableOrderProperties) => getMandatoryFieldsForOrder(order).length,
    [getMandatoryFieldsForOrder],
  );

  return {
    mandatoryFields,
    showPatientWarningBadge,
    showPatientWarningBadgeForPoolOrder,
    showAdditionalInfoWarningBadge,
    getMandatoryPatientFieldsForOrder,
  };

};
