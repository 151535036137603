import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { ButtonGroup, Control } from 'components';
import React, { useMemo } from 'react';
import messages from 'messages';
import { useOrdersContext } from 'modules/orders/providers';
import { useContextFunctions, useSaveOrExecuteOrders } from 'modules/orders/containers/OrderWizard/providers';
import { Feature, OrderStatus } from 'interfaces/api';
import { filter, reverse } from 'lodash';
import { SampleInLaboratoryIcon } from 'modules/orders/utils';
import { useGuard } from 'containers';

const labels = messages.orders.controls.execute;

export const ExecuteOrderControl = () => {

  const guard = useGuard();

  const { globalSettings, orders } = useOrdersContext();
  const saveOrExecute = useSaveOrExecuteOrders();

  const { wizardSettings } = useContextFunctions();

  const ordersPreferScheduleDateMode = wizardSettings?.preferences?.ordersPreferScheduleDateMode;
  const ordersShowPrePrint = wizardSettings?.preferences?.ordersShowPrePrint;
  const ordersPrePrintIsC2A = wizardSettings?.preferences?.ordersPrePrintIsC2A;
  const ordersSampleInLaboratoryButtonFirst = wizardSettings?.preferences?.ordersSampleInLaboratoryButtonFirst;

  const preferPrePrint = useMemo(
    () => ![OrderStatus.Queued].includes(orders[0].status) && ordersShowPrePrint && ordersPrePrintIsC2A,
    [orders, ordersShowPrePrint, ordersPrePrintIsC2A],
  );

  const buttonType = useMemo(
    () => !preferPrePrint && (!ordersPreferScheduleDateMode || globalSettings.scheduled_at) ? 'primary' : 'default',
    [preferPrePrint, ordersPreferScheduleDateMode, globalSettings.scheduled_at],
  );

  const buttons = filter([(
    <Control
      key={'execute'}
      icon={faPaperPlane}
      label={labels.label}
      onClick={() => saveOrExecute({ execute: true })}
      notifications={{
        success: labels.notifications.success,
        error: false,
      }}
      button={{
        type: buttonType,
      }}
    />
  ), guard({ feature: Feature.SampleInLaboratory }, () => (
    <Control
      key={'sample-in-lab'}
      icon={SampleInLaboratoryIcon}
      label={labels.labelInLab}
      onClick={async () => {
        await (saveOrExecute({ execute: true, sample_in_lab: true }));
      }}
      notifications={{
        success: labels.notifications.success,
        error: false,
      }}
      button={{
        type: buttonType,
      }}
    />
  ))]);

  return (
    <ButtonGroup>
      {ordersSampleInLaboratoryButtonFirst ? reverse(buttons) : buttons}
    </ButtonGroup>
  );

};
