import { useCallback } from 'react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { LanguageCode } from 'interfaces/api';

type FormatDateFunc = (date: Date | dayjs.Dayjs | string | number, options?: FormatDateOptions) => string | undefined;

export type TranslateAgeInYears = (values: { YEARS: number }) => string | undefined;
export type TranslateAgeInMonths = (values: { MONTHS: number }) => string | undefined;

export interface FormatDateOptions {
  ago?: boolean;
  age?: boolean;
  showInvalid?: boolean;
  dateOnly?: boolean;
  withSeconds?: boolean;
  withMilliSeconds?: boolean;
}

export const useFormatDate = (translateAgeInYears: TranslateAgeInYears, translateAgeInMonths: TranslateAgeInMonths, locale: LanguageCode) => {

  return useCallback<FormatDateFunc>((date, options = {}) => {

    if (isEmpty(date)) {
      return undefined;
    }

    let m = dayjs(date);

    if (!m.isValid()) {
      m = dayjs(date, 'YYYYMMDDHHmmss');

      if (!m.isValid()) {
        m = dayjs(date, 'YYYYMMDDHHmm');

        if (!m.isValid()) {
          m = dayjs(date, 'YYYYMMDD');

          if (!m.isValid()) {
            return options.showInvalid ? (date ? date + '' : undefined) : undefined;
          }
        }
      }
    }

    if (options.age) {
      const YEARS = dayjs().diff(m, 'years');
      if (YEARS > 0) {
        return translateAgeInYears({ YEARS });
      } else {
        return translateAgeInMonths({ MONTHS: dayjs().diff(m, 'months') });
      }
    }

    if (options.ago) {
      return m.fromNow().toString();
    }

    return m.format('L' + (!options.dateOnly ? ` - LT${options.withSeconds ? 'S' : ''}${options.withMilliSeconds ? '.SSS' : ''}` : ''));

  }, [translateAgeInYears, translateAgeInMonths, locale]);
};
