import constate from 'constate';
import { Platform } from 'interfaces/api';

type EnvContextInitialValue = {
  platform: Platform;
};

const envContext = (initialValue: EnvContextInitialValue) => {

  const { platform } = initialValue;
  const isWeb = platform === Platform.WEB;
  const isNative = platform !== Platform.WEB;
  const isDevelopment = import.meta.env.DEV;
  const isProductive = import.meta.env.PROD;

  return {
    platform,
    isWeb,
    isNative,
    isDevelopment,
    isProductive,
  };

};

const [EnvProvider, useEnv] = constate(envContext);

export {
  EnvProvider,
  useEnv,
};
