import React, { useMemo } from 'react';
import { AlertProps as AntdAlertProps, default as AntdAlert } from 'antd/es/alert';
import './Alert.less';
import { Message } from 'interfaces';
import { Icon, IconProps } from 'components/Icon';
import { useIntl } from 'providers';
import messages from 'messages';
import { isString, startCase } from 'lodash';
import { faCheckCircle, faExclamationCircle, faInfoCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

type OverwriteProps = {
  message: Message | React.ReactNode;
  description?: Message | React.ReactNode;
  icon?: IconProps;
  noMaxWidth?: boolean;
};

export type AlertErrorProps = {
  error: Error;
  hideDescription?: boolean;
  noMaxWidth?: boolean;
};

export type AlertProps = Omit<AntdAlertProps, keyof OverwriteProps> & (OverwriteProps | AlertErrorProps);

export const isAlertErrorProps = (obj: AlertProps): obj is AlertErrorProps => {
  return obj !== undefined && (obj as AlertErrorProps).error !== undefined;
};

const alertIcons: Record<AlertProps['type'], IconProps> = {
  success: faCheckCircle,
  info: faInfoCircle,
  warning: faExclamationCircle,
  error: faTimesCircle,
};

export const Alert: React.FC<AlertProps> = (props) => {

  const { translate } = useIntl();

  const isError = isAlertErrorProps(props);

  const propsIcon = (props as OverwriteProps).icon;
  const propsMessage = (props as OverwriteProps).message;
  const propsDescription = (props as OverwriteProps).description;
  const propsError = (props as AlertErrorProps).error;

  const type: AlertProps['type'] = useMemo(
    () => props.type || (isError ? 'error' : 'warning'),
    [props.type, isError],
  );

  const icon = useMemo(
    () => props.showIcon
      ? <Icon {...(propsIcon || alertIcons[type])}/>
      : undefined,
    [type, propsIcon, props.showIcon],
  );

  const message = useMemo(() => {
    if (isError) {
      // @ts-expect-error todo
      const errorMessage = messages.errors[propsError.name];
      return translate(errorMessage?.title || errorMessage || startCase(propsError.name));
    }
    return translate(propsMessage);
  }, [propsMessage, propsError]);

  const description = useMemo(() => {

    if (isError) {
      if (props.hideDescription) {
        return undefined;
      }

      // @ts-expect-error todo
      const errorDescription = messages.errors[propsError.name]?.description;

      if (errorDescription) {
        return translate(errorDescription);
      }

      if (isString(props.error.message)) {
        return props.error.message;
      }

      return JSON.stringify(props.error.message);
    }

    return translate(propsDescription);

  }, [propsDescription, propsError]);

  return (
    <AntdAlert
      {...props}
      type={type}
      icon={icon}
      message={message}
      description={translate(description)}
      style={{ maxWidth: props.noMaxWidth ? 'none' : undefined }}
    />
  );
};
