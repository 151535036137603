import { Messages } from 'interfaces';

import errors from './errors';
import features from './features';
import general from './general';
import products from './products';
import userlabels from './userlabels';

import admin from 'modules/admin/messages';
import auth from 'modules/auth/messages';
import exchange from 'modules/exchange/messages';
import dashboard from 'modules/dashboard/messages';
import messages from 'modules/messages/messages';
import notifications from 'modules/notifications/messages';
import orders from 'modules/orders/messages';
import reports from 'modules/reports/messages';
import lexika from 'modules/lexika/messages';
import cases from 'modules/cases/messages';

export default Messages({
  errors,
  features,
  general,
  products,
  userlabels,
  admin,
  auth,
  exchange,
  dashboard,
  messages,
  notifications,
  orders,
  reports,
  lexika,
  cases,
});
