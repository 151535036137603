import React, { useMemo } from 'react';
import { useIntl } from './IntlProvider';
import { FormatDateOptions } from './useFormatDate';
import dayjs from 'dayjs';

interface ComponentProps {
  date: Date | dayjs.Dayjs | string | number;
  options?: FormatDateOptions;
}

export const FormatDate: React.FC<ComponentProps> = ({ date, options }) => {
  const { formatDate } = useIntl();
  const formattedDate = useMemo(() => formatDate(date, options), [date, options]);
  return (<>{formattedDate}</>);
};
