import React from 'react';
import { FormItemRenderProps } from 'components/Form/Item';
import { Feature, OrderPatientMetaProperties, OrderWizardLocalization } from 'interfaces/api';
import { Container } from 'components';
import messages from 'messages';
import { useGuard } from 'containers';
import { getDefaultCountries, getPreferredCountries } from 'modules/orders/utils';

export const InsuranceDataForm: React.ComponentType<FormItemRenderProps<OrderPatientMetaProperties>> = (props) => {

  const { Input, DatePicker, CountrySelect } = props;
  const guard = useGuard();
  const hasCountryCodeSelect = guard({ feature: Feature.CountryCodeSelect }, () => true);

  const labels = messages.orders.additionalFields;
  const personLabels = messages.general.person;

  return (
    <Container grid={'49%'}>
      <Input property={'autInsuranceTitle'} label={personLabels.title} maxLength={50} floating/>
      <Input property={'autInsuranceFirstName'} label={personLabels.firstName} maxLength={50} floating/>
      <Input property={'autInsuranceLastName'} label={personLabels.lastName} maxLength={50} floating/>
      <Input property={'autInsuranceInsuranceNumber'} label={personLabels.insuranceNumber} maxLength={50} floating/>
      <DatePicker property={'autInsuranceBirthday'} label={personLabels.birthday} floating withMask disableFuture/>
      <Input property={'autInsuranceAddress'} label={personLabels.address} maxLength={150} floating/>
      <Input property={'autInsuranceHouseNumber'} label={personLabels.houseNumber} maxLength={50} floating/>
      {hasCountryCodeSelect
        ? (
          <CountrySelect
            options={getDefaultCountries()}
            property="autInsuranceCountry"
            preferred={getPreferredCountries(OrderWizardLocalization.AUT)}
            label={personLabels.country}
            floating
          />
        )
        : <Input property={'autInsuranceCountry'} label={personLabels.country} maxLength={5} floating/>
      }
      <Input property={'autInsuranceZip'} label={personLabels.zip} maxLength={10} floating/>
      <Input property={'autInsuranceCity'} label={personLabels.city} maxLength={50} floating/>
      <Input property={'autInsuranceEmployer'} label={labels.patient.austria.employer} maxLength={50} floating/>
      <Input property={'autInsuranceEmployerCity'} label={labels.patient.austria.employerPlace} maxLength={50} floating/>
    </Container>
  );

};
