import React from 'react';
import { Store } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { createSetEnvAction, EnvState } from 'store/ducks/env';

type EnvStateProps = {
  env: EnvState;
};

type EnvDispatchProps = {
  setEnv: typeof createSetEnvAction;
};

export type InjectedEnvProps = EnvStateProps & EnvDispatchProps;

type OwnProps<P extends InjectedEnvProps> = Omit<P, keyof InjectedEnvProps>;

export function injectEnv<P extends InjectedEnvProps>(ComposedComponent: React.ComponentType<P>): React.ComponentType<OwnProps<P>> {
  return (props: P) => {
    const env = useEnv();
    return <ComposedComponent {...props} env={env}/>;
  };
}

export const useEnv = () => {
  const dispatch = useDispatch();
  const env = useSelector<Store, EnvState>(state => ({ ...state.env }));
  const setEnv = (env: EnvState) => dispatch(createSetEnvAction(env));

  return {
    ...env,
    setEnv,
  };
};
