import React, { useEffect, useState } from 'react';
import { App, ConfigProvider } from 'antd';
import { useIntl } from './IntlProvider';
import { getAntTheme } from 'utils/helpers';
import { LanguageCode } from 'interfaces/api';

const antdLocaleFiles = {
  [LanguageCode.DE]: () => import('antd/lib/locale/de_DE'),
  [LanguageCode.EN]: () => import('antd/lib/locale/en_GB'),
  [LanguageCode.IT]: () => import('antd/lib/locale/it_IT'),
  [LanguageCode.FR]: () => import('antd/lib/locale/fr_FR'),
  [LanguageCode.HU]: () => import('antd/lib/locale/hu_HU'),
  [LanguageCode.NL]: () => import('antd/lib/locale/nl_NL'),
};

const messageConfig = {
  top: 58,
  duration: 3,
  maxCount: 3,
};

export const LibraryConfig: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { locale } = useIntl();

  const [antdLocale, setAntdLocale] = useState();

  useEffect(() => {
    if (locale) {
      antdLocaleFiles[locale]().then((res) => {
        setAntdLocale(res.default as any);
      });
    }
  }, [locale]);

  if (!antdLocale) {
    return null;
  }

  return (
    <ConfigProvider locale={antdLocale} key={locale} theme={{ token: getAntTheme() }}>
      <App message={messageConfig}>
        {children}
      </App>
    </ConfigProvider>
  );

};
