import React from 'react';
import { Translate } from 'providers';
import './IconNav.less';
import cx from 'classnames';
import { Icon, IconProps } from 'components';
import { Color, Message } from 'interfaces';
import { Guard, GuardConfig } from 'containers';
import { getCssColor } from 'utils/dom';
import { map } from 'lodash';

export type SideBarIconNavItem = {
  onClick?: () => void;
  color: Color;
  icon: IconProps;
  title: Message;
  active?: boolean;
  disabled?: boolean;
  guard?: GuardConfig;
  margin?: number;
  className?: any;
};

export type SideBarIconNavProps = {
  items: SideBarIconNavItem[];
  className?: any;
};

export const SideBarIconNav = (props: SideBarIconNavProps) => {

  return (
    <ul className={cx('icon-nav', props.className)}>

      {map(props.items, (item, idx) => {

        const { active, disabled, onClick, color, icon, title, guard, margin } = item;

        return (
          <Guard {...guard} key={idx}>
            <li className={cx({ 'is-active': active, 'is-disabled': disabled }, item.className)} onClick={onClick}>
              <span style={{ background: getCssColor(color) }}>
                <Icon {...icon} style={margin ? { marginLeft: `${margin}px` } : undefined}/>
              </span>
              <span><Translate message={title}/></span>
            </li>
          </Guard>
        );
      })}
    </ul>
  );
};
