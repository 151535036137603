import * as env from './ducks/env';

export const Reducers = {
  env: env.reducer,
};

export interface Store {
  env: env.EnvState;
}

export * from './middleware/index';
