import React from 'react';
import { FormItemRenderProps } from 'components/Form/Item';
import { OrderAnimalProperties } from 'interfaces/api';
import { Container } from 'components';
import messages from 'messages';

export const AnimalDataForm: React.ComponentType<FormItemRenderProps<OrderAnimalProperties>> = (props) => {

  const { Input, DatePicker } = props;

  const animalLabels = messages.general.animal;

  return (
    <Container grid={'49%'}>
      <Input property={'name'} label={animalLabels.name} maxLength={50} floating/>
      <DatePicker property={'birthday'} label={animalLabels.birthday} floating withMask disableFuture/>
      <Input property={'sex'} label={animalLabels.sex} maxLength={10} floating/>
      <Input property={'species'} label={animalLabels.species} maxLength={2} floating/>
      <Input property={'chipId'} label={animalLabels.chipId} maxLength={50} floating/>
      <Input property={'breed'} label={animalLabels.breed} maxLength={50} floating/>
      <Input property={'height'} label={animalLabels.size} maxLength={3} floating/>
      <Input property={'weight'} label={animalLabels.weight} maxLength={3} floating/>
    </Container>
  );

};
