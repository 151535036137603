import React, { useEffect } from 'react';
import constate from 'constate';
import { useApi, useApiCall, useSocketConnection } from 'providers';
import { Notification } from 'interfaces/api';
import { App } from 'antd';
import { Icon } from 'components';
import { getIcon, notificationLocation } from 'modules/notifications/utils';
import { useAuthUser } from 'modules/auth/providers';

const notificationCountContext = () => {

  const user = useAuthUser();
  const { notifications: { countNotifications, readNotification } } = useApi();
  const count = useApiCall(user ? countNotifications : async () => undefined);

  const { connection } = useSocketConnection();
  const { notification: antdNotification } = App.useApp();

  useEffect(() => {

    connection?.on('notification', (notification: Notification) => {

      const key = notification.id.toString() || 'key';

      count.reload();

      antdNotification.open({
        key: notification.id.toString(),
        message: notification.title,
        description: notification.body,
        placement: 'topRight',
        icon: <Icon icon={getIcon(notification)}/>,
        className: 'notification-popup',
        onClick: async () => {
          antdNotification.destroy(key);
          notificationLocation(notification);
          await readNotification({ id: notification.id });
          await count.reload();
        },
      });
    });
  }, [connection]);

  return {
    notificationCount: count.data?.count || 0,
    reloadNotificationCount: count.reload,
    loadingNotificationCount: count.loading,
  };
};

const [NotificationCountProvider, useNotificationCount] = constate(notificationCountContext);

export { NotificationCountProvider, useNotificationCount };
