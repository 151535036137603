import { AlertModal } from 'components';
import React from 'react';
import { MaintenanceError, useMaintenanceProvider } from './MaintenanceProvider';

export const Maintenance: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { isMaintenance } = useMaintenanceProvider();

  return (
    <>
      {children}
      <AlertModal
        closable={false}
        open={isMaintenance}
        showIcon
        type={'warning'}
        error={new MaintenanceError()}
      />
    </>
  );
};
