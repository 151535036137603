import React, { useCallback, useState } from 'react';
import { Container, Filters, Header, List, ModalControl, PopOvers } from 'components';
import { PaginationResponse, useApi, useIntl } from 'providers';
import { getReportValuesListHeader } from 'modules/reports/utils';
import * as ReportFilters from 'modules/reports/filters';
import { ApiRequest, InfiniteScroll } from 'containers';
import { useGraph } from 'modules/reports/components/Graph/Graph';
import { Patient, Report } from 'interfaces/api';
import '../../Detail/Detail.less';
import './Endless.less';
import { faArrowFromTop, faStethoscope, faVial } from '@fortawesome/pro-light-svg-icons';
import { Item } from 'components/List/Item/Item';
import { flatten, groupBy, map } from 'lodash';
import messages from 'messages';
import { useConvertReportValuesToListItems, useGetListItemFlag } from 'modules/reports/providers/ReportListLayoutProvider';

type EndlessReportControlProps = {
  patient: Patient;
};

export const EndlessReportControl = ({ patient }: EndlessReportControlProps) => {

  const { pid, displayName } = patient;

  const { reports: { listReports, readReport } } = useApi();

  const [filters, setFilters] = useState({});

  const Graph = useGraph({ disableAnimations: true });

  const { translate, formatDate } = useIntl();

  const convertReportValuesToListItems = useConvertReportValuesToListItems();
  const getReportListItemFlag = useGetListItemFlag();

  const requestList = useCallback((offset?: number) => listReports({ pid, offset, ...filters, limit: 100, sort: '-imported_at' }), [pid, listReports, filters]);

  return (
    <ModalControl
      label={messages.reports.endless.button}
      icon={faArrowFromTop}
      modal={{
        fullHeight: true,
        fullScreen: true,
        title: translate(messages.reports.endless.title, { NAME: displayName }),
        footer: (
          <Container horizontal>
            <Filters
              isHorizontal
              filters={[
                ReportFilters.ReportTypeFilter,
                ReportFilters.ReportDateFromFilter,
                ReportFilters.ReportDateToFilter,
              ]}
              onFilter={setFilters}
              filterValues={filters}
            />
          </Container>
        ),
        children: () => {

          return (
            <ApiRequest
              key={JSON.stringify(filters)}
              request={requestList}
              children={({ data: listedReports }) => {

                const requestReport = async (offset: number = 0) => {

                  const report = await readReport({ bid: listedReports.results[offset || 0]?.bid });

                  report.values = report.values.map(v => ({ ...v, group: v.group + ' - ' + report.tnr }));

                  return {
                    indexStart: offset,
                    indexEnd: offset,
                    results: [report],
                    hasMore: offset < listedReports.results.length - 1,
                  } as PaginationResponse<Report>;
                };

                return (

                  <Container grow shrink scrollY style={{ marginBottom: '-1px' }}>

                    <ApiRequest
                      request={requestReport}
                      children={({ data: reports, next }) => {

                        const elements = flatten(map(reports.results, (report) => {

                          const { tnr, doctor, status } = report;

                          const groupedValues = groupBy(convertReportValuesToListItems(report.values, report, Graph), item => item.groupByValue);

                          return [
                            (
                              <Header
                                key={tnr}
                                flag={getReportListItemFlag(report)}
                                title={formatDate(report.created_at)}
                                subtitle={doctor?.displayName}
                                className={'endless-header'}
                                popovers={[
                                  {
                                    label: status + ' ' + tnr,
                                    content: <PopOvers.Report report={report}/>,
                                  },
                                  {
                                    label: report.doctor?.displayName,
                                    icon: faStethoscope,
                                    content: <PopOvers.Doctor doctor={report.doctor}/>,
                                  },
                                  {
                                    label: report.laboratory?.displayName,
                                    icon: faVial,
                                    content: <PopOvers.Labor labor={report.laboratory}/>,
                                  },
                                ]}
                              />
                            ),
                            ...flatten(map(groupedValues, (values, title) => ([
                              <h3 key={title + tnr} className={'list-header'}>{translate(getReportValuesListHeader(report.values)(title, values).title)}</h3>,
                              ...map(values, value => <Item {...value} className={value.className}/>),
                            ]))),
                          ];

                        }));

                        return (
                          <div className={'report-detail-list'}>
                            <InfiniteScroll onNext={next}>
                              <List
                                children={elements}
                              />
                            </InfiniteScroll>
                          </div>
                        );
                      }}
                    />

                  </Container>
                );

              }}
            />
          );
        },
      }}
    />
  );

};
