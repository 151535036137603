import React from 'react';
import { default as AntInput, InputProps as AntInputProps } from 'antd/es/input';
import './Input.less';

type OverwriteProps = object;

export type InputProps = Omit<AntInputProps, keyof OverwriteProps> & OverwriteProps;

export const Input: React.FC<InputProps> = (props) => {

  return (
    <AntInput
      {...props}
    />
  );
};
