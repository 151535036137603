import { useEffect } from 'react';
import { AnyFunction } from 'utils/helpers';

export const usePauseResume = (pauseCallback: AnyFunction, resumeCallback: AnyFunction) => {

  useEffect(() => {

    document.addEventListener('pause', pauseCallback, false);
    document.addEventListener('resume', resumeCallback, false);

    window.addEventListener('blur', pauseCallback, false);
    window.addEventListener('focus', resumeCallback, false);

    return () => {
      document.removeEventListener('pause', pauseCallback, false);
      document.removeEventListener('resume', resumeCallback, false);

      window.removeEventListener('blur', pauseCallback, false);
      window.removeEventListener('focus', resumeCallback, false);
    };
  }, [pauseCallback, resumeCallback]);
};
