import { useCallback, useEffect, useMemo, useState } from 'react';
import { Doctor, Feature, OrderRequirementsFilter, OrderWizardGetSettingsResponse, OrderWizardLocalization, UserType } from 'interfaces/api';
import constate from 'constate';
import { useAuth, useAuthUser } from 'modules/auth/providers';
import { MetaLogMessage, useApi, useIntl, useLogger } from 'providers';
import { find } from 'lodash';
import { useGuard } from 'containers';
import { App } from 'antd';
import messages from 'messages';

type InitialOfficeDoctor = { officeDoctor: Doctor };
const useOfficeDoctor = (initialValue: InitialOfficeDoctor) => {

  const guard = useGuard();
  const { resolveLegacy, legacy } = useAuth();
  const { type, entityId } = useAuthUser();
  const { translate } = useIntl();
  const logger = useLogger('OfficeDoctorProvider');

  const { message } = App.useApp();

  const { doctorGroupOffice: { readDoctorGroupOfficeAssignments }, orderWizard: { getSettings } } = useApi();

  const [loading, setLoading] = useState(false);
  const [officeDoctor, internalSetOfficeDoctor] = useState<Doctor>();
  const [officeDoctors, setOfficeDoctors] = useState<Doctor[]>();
  const [ambiguousAid, setAmbiguousAid] = useState<number | true>();

  const [wizardSettings, setWizardSettings] = useState<OrderWizardGetSettingsResponse>();
  const [availableFilters, setAvailableFilters] = useState<OrderRequirementsFilter[]>([]);

  const reloadWizardSettings = useCallback(async (aid: number) => {
    const settings = await getSettings({ aid });
    setWizardSettings(settings);
  }, [getSettings, setWizardSettings]);

  useEffect(() => {
    if (officeDoctor?.aid) {
      reloadWizardSettings(officeDoctor?.aid);
    }
  }, [officeDoctor?.aid]);

  /**
   * reset office doctor
   */
  const setOfficeDoctor = useCallback(async (value?: Doctor) => {

    setLoading(true);

    if (!value) {
      internalSetOfficeDoctor(undefined);
    }

    const aid = value?.aid;

    setAmbiguousAid(aid || true);
    setOfficeDoctors(undefined);

    try {

      if (aid || type === UserType.ARZ) {

        const assignments = await readDoctorGroupOfficeAssignments({ aid });
        setOfficeDoctors(assignments);

        if (assignments.length === 1) {
          logger.debug('single assignment found', new MetaLogMessage('assignments', assignments[0]));
          internalSetOfficeDoctor(assignments[0]);
          setAmbiguousAid(undefined);
        } else if (value?.lanr) {
          const doctor = find(assignments, { lanr: value.lanr });

          if (doctor) {
            logger.debug('lanr assignment found', new MetaLogMessage('doctor', doctor));
            internalSetOfficeDoctor(doctor);
            setAmbiguousAid(undefined);
          }
        }

      }

    } catch (e) {
      logger.error(e);
      message.error(translate(messages.errors.occurred));
    }

    if (legacy) {
      resolveLegacy();
    }

    setLoading(false);
  }, [resolveLegacy, type, entityId]);

  // initial check
  useEffect(() => {
    setOfficeDoctor(initialValue.officeDoctor);
  }, []);

  const kisDisabled = useMemo(
    () => guard(
      { feature: Feature.KisModusEdit },
      () => officeDoctor?.localisation === OrderWizardLocalization.KIS && !officeDoctor?.editKisMode),
    [officeDoctor],
  );

  return {
    loading,
    officeDoctor,
    officeDoctors,
    setOfficeDoctor,
    availableFilters,
    setAvailableFilters,
    wizardSettings,
    reloadWizardSettings,
    kisDisabled,
    ambiguousAid,
  };

};

const [OfficeDoctorProvider, useOfficeDoctorContext] = constate(useOfficeDoctor);

export {
  OfficeDoctorProvider,
  useOfficeDoctorContext,
};
