import { DatePickerProps as AntDatePickerProps } from 'antd/es/date-picker';
import './DatePicker.less';
import { DatePicker as AntDatePicker } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import imask from 'imask';
import { IMaskInput } from 'react-imask';
import { getLongDateFormat } from 'utils/helpers';

export type DatePickerProps = AntDatePickerProps & {
  disablePast?: boolean;
  disableFuture?: boolean;
  format?: string;
  withMask?: boolean;
};

export const DatePicker: React.FC<DatePickerProps> = (props) => {

  const { disablePast, disableFuture, withMask, ...rest } = props;

  const [value, setValue] = useState(props.value ? dayjs(props.value) : undefined);
  const [isFocused, setIsFocused] = useState(props.autoFocus);

  useEffect(() => setValue(props.value ? dayjs(props.value) : undefined), [props.value]);

  const disabledDateFn = useCallback((current: dayjs.Dayjs) => {
    if (disableFuture && current && current > dayjs().endOf('day')) {
      return true;
    } else if (disablePast && current && current < dayjs().startOf('day')) {
      return true;
    }
    return false;
  }, [disableFuture, disablePast]);

  const format = useMemo(() => props.format || 'L', [props.format]);

  const dateFormat = getLongDateFormat();
  const maskPattern = useMemo(() => format.replace('LT', 'HH:mm').replace('L', dateFormat), [format, dateFormat]);

  const onChange = useCallback((newValue: dayjs.Dayjs, newValueString: string) => {
    props.onChange(newValue, newValueString);
    setValue(newValue);
  }, [props.onChange, setValue]);

  const inputRef = useRef<any>();

  const inputRender = useMemo(() => {

    if (!withMask) {
      return undefined;
    }

    return (inputProps: React.InputHTMLAttributes<HTMLInputElement>) => {
      return (
        <IMaskInput
          mask={Date}
          ref={inputRef}
          pattern={maskPattern}
          format={(date: any) => dayjs(date).format(maskPattern)}
          parse={str => dayjs(str, maskPattern) as any}
          value={value ? value.format(format) : ''}
          unmask={false}
          lazy={!isFocused}
          onComplete={(value: string) => {
            onChange(dayjs(value, format), value);
            inputRef.current?.element?.blur?.();
          }}
          onMouseDown={inputProps.onMouseDown}
          onFocus={(event) => {
            setIsFocused(true);
            inputProps.onFocus?.(event as any);
            props.onFocus?.(event as any);
          }}
          onBlur={(event) => {
            setIsFocused(false);
            inputProps.onBlur?.(event as any);
            props.onBlur?.(event as any);
          }}
          blocks={{
            DD: { mask: imask.MaskedRange, placeholderChar: 'd', from: 1, to: 31, maxLength: 2 },
            MM: { mask: imask.MaskedRange, placeholderChar: 'm', from: 1, to: 12, maxLength: 2 },
            YYYY: { mask: imask.MaskedRange, placeholderChar: 'y', from: 1900, to: 2999, maxLength: 4 },
          } as any}
        />
      );
    };
  }, [withMask, maskPattern, isFocused, value]);

  return (
    <AntDatePicker
      {...rest}
      format={format}
      disabledDate={props.disabledDate || disabledDateFn}
      inputRender={inputRender}
      value={value}
      onChange={onChange}
    />
  );
};
