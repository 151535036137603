import React from 'react';
import { AggregatedProfileProperties, OrderWizardRequirement } from 'interfaces/api';
import { faShoppingCart, faTrash } from '@fortawesome/pro-light-svg-icons';
import { Control } from 'components/Control';
import messages from 'messages';
import { useBasketContext, useToggleBasket } from 'modules/orders/containers/OrderWizard/providers';
import { isProfile } from 'modules/orders/containers/OrderWizard/utils';

export const AddBasket = (props: { requirement: OrderWizardRequirement | AggregatedProfileProperties; profileId?: number }) => {

  const { requirement, profileId } = props;

  const { profileRequirementsAllInBasket, getBasketCount } = useBasketContext();
  const toggleBasket = useToggleBasket();
  const showRemove = isProfile(requirement) ? profileRequirementsAllInBasket(requirement) : getBasketCount(requirement);

  return (
    <Control
      icon={showRemove ? faTrash : faShoppingCart}
      label={showRemove ? messages.orders.removeFromBasket : messages.orders.addToBasket}
      tooltip={{ placement: 'top' }}
      onClick={async (e) => {
        e.stopPropagation();
        if (isProfile(requirement)) {
          toggleBasket({ ...requirement, formId: undefined });
        } else {
          toggleBasket({ ...requirement, profileId, formId: undefined });
        }
      }}
    />
  );

};
