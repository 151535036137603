import React from 'react';
import { ListLayoutArgs, ListLayoutSideBarHiddenSectionCallback } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import { Container, Filter, Filters } from 'components';
import { RealReRequestMode, OrderListMode } from 'interfaces/api';
import messages from 'messages';

const labels = messages.orders.filters;

export const realReRequestFilters: Filter[] = [{
  label: labels.realReRequestModes.label,
  filter: value => ({ realReRequestMode: value }),
  items: [
    RealReRequestMode.Applied,
    RealReRequestMode.History,
  ].map(m => ({
    label: labels.realReRequestModes[m],
    value: m,
  })),
}];

const Component = React.memo((props: ListLayoutArgs<OrdersListContext>) => {

  const { context, bindings: { setContext } } = props;

  return (
    <Container className={'margin-top-1'}>
      <Filters
        filters={realReRequestFilters}
        onFilter={value => setContext({ filters: { ...context.filters, ...value } })}
        filterValues={context.filters}
      />
    </Container>
  );
});

export const RealReRequestSection: ListLayoutSideBarHiddenSectionCallback<OrdersListContext> = args => ({
  active: args.context.mode === OrderListMode.RealReRequest,
  component: Component,
});
