import React, { useMemo } from 'react';
import { useIntl } from './IntlProvider';
import { Message } from 'interfaces';

interface ComponentProps {
  message: Message;
  values?: Record<string, any>;
}

export const Translate: React.FC<ComponentProps> = ({ message, values }) => {
  const { translate } = useIntl();
  const translated = useMemo(() => translate(message, values), [message, values]);
  return (<>{translated}</>);
};
