import { useCallback, useMemo } from 'react';
import { flattenDeep, get, set } from 'lodash';
import { useOrderRulesContext } from './OrderRulesProvider';

export type PendingOrderRuleInvalidField = {
  field: string;
  index: number;
  value?: any;
  message?: string;
};

export const useInvalidFields = () => {

  const { getErrorsByName, pending, setPending, errors } = useOrderRulesContext();

  // get missing fields from errors
  const invalidFieldErrors = useMemo(() => getErrorsByName('InputError'), [getErrorsByName]);

  // create missing fields array
  const invalidFields: PendingOrderRuleInvalidField[] = useMemo(() => {
    const errors = flattenDeep(invalidFieldErrors?.map(
      ({ errors, order, idx }) => errors.map(inputErrors => inputErrors.message.map(
        ({ instancePath, message }: any) => ({
          message,
          index: idx,
          field: instancePath?.substring(1).split('/').join('.'),
          value: get(order, instancePath?.substring(1).split('/').join('.')),
        }),
      )),
    ));

    return errors.length > 0 ? errors : undefined;

  }, [invalidFieldErrors]);

  // update values functionality
  const updateValues = useCallback((values: PendingOrderRuleInvalidField[]) => {

    const orders = [...errors.map(e => e.order)];

    values.forEach((v) => {
      set(orders, v.index + '.' + v.field, v.value);
    });

    setPending({ ...pending, orders, isUpsert: true });

  }, [pending, setPending, errors]);

  return { invalidFields, updateValues };

};
