import { isWeb } from 'utils/device';
import constate from 'constate';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

const storageContext = () => {

  const setter = async (key: string, value: string, secureOnly?: boolean) => {

    if (await isWeb() && secureOnly) {
      return undefined;
    }

    try {
      return SecureStoragePlugin.set({ key, value });
    } catch (e) {
      await SecureStoragePlugin.remove({ key });
      return SecureStoragePlugin.set({ key, value });
    }
  };

  const getter = async (key: string) => {
    try {
      return (await SecureStoragePlugin.get({ key })).value;
    } catch (e) {
      return undefined;
    }
  };

  const remover = async (key: string): Promise<void> => {
    try {
      await SecureStoragePlugin.remove({ key });
    } catch (e) { /* empty */ }
  };

  const setIdentifier = (identifier: string) => setter('lab7-identifier', identifier, true);
  const getIdentifier = () => getter('lab7-identifier');

  const setToken = (token: string) => setter('lab7-token', token, true);
  const getToken = () => getter('lab7-token');
  const removeToken = () => remover('lab7-token');

  const setFaceIdLoginRequested = () => setter('lab7-face-id-requested', 'true');
  const getFaceIdLoginRequested = () => getter('lab7-face-id-requested');
  const removeFaceIdLoginRequested = () => remover('lab7-face-id-requested');

  return {
    setIdentifier,
    getIdentifier,
    setToken,
    getToken,
    removeToken,
    setFaceIdLoginRequested,
    getFaceIdLoginRequested,
    removeFaceIdLoginRequested,
  };

};

const [StorageProvider, useStorage] = constate(storageContext);

export {
  StorageProvider,
  useStorage,
};
