import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'providers/IntlProvider';
import { getClient } from './client';
import { useEnv } from 'store/components/injectEnv';
import { getStore } from 'store';
import { createLogoutAction } from 'modules/auth/store/ducks/auth';
import { ApiDefinition } from 'interfaces/api';
import { useLogger } from 'providers/LoggerProvider';
import { useWarningModal } from 'components';
import messages from 'messages';

export const useApiClient = (lid?: number): ApiDefinition => {

  const { locale, translate } = useIntl();
  const { dispatch, getState } = getStore();
  const { BACKEND_URL: endpoint, TARGET: target, BUILD_NUMBER: buildNumber, isNative } = useEnv();

  const logger = useLogger('api');
  const warningModal = useWarningModal();

  const onUnauthorizedError = useCallback(() => {
    if (getState().auth.user) {
      dispatch(createLogoutAction());
    }
  }, [dispatch]);

  const [updateAvailable, setUpdateAvailable] = useState(false);

  const checkResponseHeaders = useCallback((headers: any) => {
    if (headers['x-build'] !== buildNumber && !isNative) {
      setUpdateAvailable(true);
    }
  }, [dispatch, endpoint]);

  useEffect(() => {
    if (updateAvailable) {
      warningModal({
        title: translate(messages.general.newVersionAvailableModal.title),
        content: translate(messages.general.newVersionAvailableModal.content),
        okText: translate(messages.general.newVersionAvailableModal.reloadNow),
        onOk: () => window.location.reload(),
      });
    }
  }, [updateAvailable]);

  return useMemo(() => getClient({
    lid,
    locale,
    logger,
    endpoint,
    target,
    checkResponseHeaders,
    onUnauthorizedError,
    version: APP_VERSION,
  }), [lid, locale, logger, endpoint]);
};
