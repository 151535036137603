import React, { useEffect, useMemo, useState } from 'react';
import { Message } from 'interfaces';
import constate from 'constate';
import { LibraryConfig } from './LibraryConfig';
import { useTranslate } from './useTranslate';
import { TranslateAgeInMonths, TranslateAgeInYears, useFormatDate } from './useFormatDate';
import { filter, first } from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/fr';
import 'dayjs/locale/hu';
import 'dayjs/locale/nl';
import { useFormatPrice } from './useFormatPrice';
import { LanguageCode } from 'interfaces/api';
import { useEnv } from 'store/components/injectEnv';

type IntlContextInitialValue = {
  locale?: LanguageCode;
  ageInYearsMessage: Message;
  ageInMonthsMessage: Message;
};

const useIntlContext = (initialValue: IntlContextInitialValue) => {

  const { locales } = useEnv();

  // get initial locale
  const defaultLocale = useMemo(() => first(filter([
    localStorage.getItem('locale'),
    initialValue.locale,
    (navigator.languages || [navigator.language]).filter(l => locales.indexOf(l.split('-')[0] as LanguageCode) > -1)[0],
    LanguageCode.DE,
  ])) as LanguageCode, []);

  const [locale, setLocale] = useState<LanguageCode>(defaultLocale);
  const [translations, setTranslations] = useState<Record<string, string>>({});

  useEffect(() => {
    // save locale to local storage
    if (locale !== defaultLocale) {
      localStorage.setItem('locale', locale);
    }

    // fetch messages
    axios.get(`/i18n/${locale}.json`, { responseType: 'json' }).then(({ data }) => setTranslations(data));

    // set dayjs locale
    dayjs.locale(locale);

  }, [locale]);

  const translate = useTranslate(translations, locale);

  const translateAgeInYears: TranslateAgeInYears = useMemo(() => values => translate(initialValue.ageInYearsMessage, values), [translate]);
  const translateAgeInMonths: TranslateAgeInMonths = useMemo(() => values => translate(initialValue.ageInMonthsMessage, values), [translate]);

  const formatDate = useFormatDate(translateAgeInYears, translateAgeInMonths, locale);
  const formatPrice = useFormatPrice(locale);

  return {
    locale,
    setLocale,
    translate,
    formatDate,
    formatPrice,
  };

};

const [IntlInternalProvider, useIntl] = constate(useIntlContext);
export { useIntl };

export const IntlProvider: React.FC<IntlContextInitialValue & { children?: React.ReactNode }> = ({ children, ...providerConfig }) => (
  <IntlInternalProvider {...providerConfig}>
    <LibraryConfig>
      {children}
    </LibraryConfig>
  </IntlInternalProvider>
);
