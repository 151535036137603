import React from 'react';
import './ColorPicker.less';
import { default as AntColorPicker, ColorPickerProps as AntColorPickerProps } from 'antd/es/color-picker';

type OverwriteProps = {
  onChange?: (color: string) => void;
};

export type ColorPickerProps = Omit<AntColorPickerProps, keyof OverwriteProps> & OverwriteProps;

export const ColorPicker: React.FC<ColorPickerProps> = (props) => {

  return (
    <AntColorPicker
      showText
      disabledAlpha
      {...props}
      onChange={(color, hex) => props.onChange?.(hex.replace('#', ''))}
    />
  );
};
