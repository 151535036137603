import React, { useMemo } from 'react';
import { OrderWizardRequirement } from 'interfaces/api';
import { FormItem, Select } from 'components';
import { useUpsertBasket } from 'modules/orders/containers/OrderWizard/providers';
import messages from 'messages';
import { useIntl } from 'providers';
import { useOrdersContext } from 'modules/orders/providers';
import { FormLayout } from 'containers';
import { useInvoiceTo } from 'modules/orders/containers/OrderWizard/utils';

const labels = messages.orders.additionalFields;

type Props = {
  requirement: OrderWizardRequirement;
};

export const InvoiceTo: React.FC<Props> = ({ requirement }) => {

  const { translate } = useIntl();
  const upsertBasket = useUpsertBasket();
  const { currentOrder } = useOrdersContext();

  const { options, getSelectedLabel } = useInvoiceTo();

  const globalInvoiceTo = currentOrder?.patient?.invoiceTo;

  const placeholder = useMemo(() => {
    if (globalInvoiceTo) {
      return translate(labels.invoiceToFromGlobal, { invoiceTo: getSelectedLabel(globalInvoiceTo) });
    }
    return translate(labels.invoiceToFromGlobalNone);
  }, [globalInvoiceTo]);

  return (
    <FormLayout embedded className={'border-bottom'}>
      <FormItem label={labels.invoiceTo}>
        <Select
          allowClear
          placeholder={placeholder}
          value={requirement.invoiceTo}
          onChange={invoiceTo => upsertBasket({ ...requirement, invoiceTo })}
          onClear={() => upsertBasket({ ...requirement, invoiceTo: null })}
          options={options}
        />
      </FormItem>
    </FormLayout>
  );

};
