import React, { useCallback, useMemo, useState } from 'react';
import { DefaultOptionType, default as AntSelect, SelectProps as AntSelectProps } from 'antd/es/select';
import './CountrySelect.less';
import cx from 'classnames';

type OverwriteProps = {
  preferred?: (string | number)[];
};

export type CountrySelectProps = Omit<AntSelectProps, keyof OverwriteProps> & OverwriteProps;

export const CountrySelect: React.FC<CountrySelectProps> = (props) => {

  const [searchValue, setSearchValue] = useState<string>('');

  const { value, options, onSelect, preferred = ['DE', 'AT', 'CH'], ...rest } = props;

  const selectValue = useMemo(() => {
    if (Array.isArray(value)) {
      return value;
    }
    return value ? [value] : [];
  }, [value]);

  const handleSelect = useCallback((value: string, option: DefaultOptionType) => {
    let selectedValue = value;
    if (value.length > 5) {
      selectedValue = value.substring(0, 5);
      option.label = selectedValue;
      option.value = selectedValue;
    }
    onSelect(selectedValue, option);
    setSearchValue('');
  }, []);

  const filterOption = useCallback((inputValue: string, option: DefaultOptionType) => {
    return option.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 || option.label.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  }, []);

  const handleSearch = useCallback((searchValue: string) => {
    if (value) {
      onSelect('', null);
    }
    setSearchValue(searchValue);
  }, [value, onSelect]);

  const reorderedCountries = useMemo((): DefaultOptionType[] => {

    const reorderedOptions = [...options.sort((a, b) => (a.label.toString()).toLowerCase().localeCompare((b.label.toString()).toLowerCase()))];

    const checkValue = (i: string) => {
      const index = reorderedOptions.findIndex((option: DefaultOptionType) => option.value.toString().toLowerCase().indexOf(i.toLowerCase()) > -1);
      if (index > -1) {
        const currentItem = reorderedOptions[index];
        reorderedOptions.splice(index, 1);
        reorderedOptions.unshift(currentItem);
      }
    };

    if (preferred?.length > 0) {
      preferred.reverse().forEach(checkValue);
    }

    if (searchValue) {
      checkValue(searchValue);
    }

    return reorderedOptions;

  }, [preferred, options, searchValue]);

  return (
    <AntSelect
      mode="tags"
      allowClear
      className={cx('country-select', props.className)}
      options={reorderedCountries}
      onSelect={handleSelect}
      onSearch={handleSearch}
      value={selectValue}
      filterOption={filterOption}
      searchValue={searchValue.substring(0, 5)}
      {...rest}
    />
  );
};
