import React, { RefObject } from 'react';
import { Color, Message, SortGrouping } from 'interfaces';
import { faBan } from '@fortawesome/pro-solid-svg-icons';
import './List.less';
import { RouteComponentProps, withRouter } from 'react-router';
import cx from 'classnames';
import { Item } from './Item/Item';
import { InjectedMessagesProps, injectMessages } from 'store/components/injectMessages';
import { Collapse, ControlGroupProps, ControlProps, Icon, IconProps, ImageProps, PopoverProps } from 'components';
import { ListHeader, ListHeaderProps } from 'components/List/Header/Header';
import { filter, flatten, groupBy, map } from 'lodash';
import Masonry from 'react-masonry-css';
import { useSize } from 'react-use';
import { getCssVariableValue } from 'utils/dom';
import { CollapsibleType } from 'antd/es/collapse/CollapsePanel';

export type ListField = {
  label?: Message;
  value: Message;
  flipped?: boolean;
  className?: string;
  narrow?: boolean;
  wide?: boolean;
};

export const enum BadgeType {
  Inline,
  ImageOverlay,
  IconOverlay,
}

export interface ListItem<MetaInfo = any> {
  id: string | number;
  key?: string | number;
  title?: Message | React.ReactNode;
  subtitle?: Message | React.ReactNode;
  fields?: ListField[];
  body?: string | React.ReactNode;
  className?: string;
  flag?: Color | Color[];
  icon?: IconProps;
  iconColor?: Color;
  icons?: IconProps[];
  images?: ImageProps[];
  hoverIcon?: IconProps;
  highlighted?: boolean;
  immutable?: boolean;
  faded?: boolean;
  disabled?: boolean;
  highlightedDot?: boolean | Color;
  groupByValue?: string;
  backgroundColor?: string;
  badge?: string | string[] | number;
  badgeIcon?: BadgeIconProps;
  badgeType?: BadgeType;
  meta?: MetaInfo;
  controls?: React.ReactElement<ControlProps | ControlGroupProps>[];
}

export type BadgeIconProps = {
  icon: IconProps;
  backgroundColor?: Color;
};

export const enum ListColumnType {
  Horizontal,
  Vertical,
  Masonry,
  HorizontalBroader,
  VerticalBroader,
  MasonryBroader,
}

export type ListGroupHeader = (group: string, items: ListItem[]) => ListHeaderProps;
export type ListGroupFooter = (group: string, items: ListItem[]) => React.ReactNode;

export interface ListProps {
  items?: ListItem[];
  children?: React.ReactElement[];
  selected?: ListItem[];
  onSelect?: (selected: ListItem) => void;
  checkboxes?: boolean;
  disabled?: boolean;
  linkTo?: (selected: ListItem) => string;
  onItemClick?: (listItem: ListItem) => void;
  hideCaretOnSelect?: boolean;
  backUrl?: string;
  linkProps?: (item: ListItem) => React.AnchorHTMLAttributes<HTMLAnchorElement>;
  itemIsControl?: (item: ListItem) => React.ReactElement<ControlProps>;
  itemIsCollapse?: (item: ListItem) => React.ReactElement;
  suppressCollapseAccordion?: boolean;
  expandAll?: boolean;
  className?: any;
  condensed?: boolean;
  fixedPrimaryColumnWidth?: boolean;
  getControls?: (listItem: ListItem) => React.ReactElement<ControlProps>[];
  getPopover?: (item: ListItem) => Omit<PopoverProps, 'children'>;
  groupBy?: SortGrouping;
  groupHeader?: ListGroupHeader;
  groupFooter?: ListGroupFooter;
  columnType?: ListColumnType;
  columns?: number;
  suppressNoResults?: boolean;
  groupClassName?: (items: ListItem[]) => any;
}

type Props = ListProps & RouteComponentProps & InjectedMessagesProps;

type ListWrapColumnObserverProps = {
  children: React.ReactNode;
  columnType: ListColumnType;
  columns?: number;
};

const ListWrapColumnObserver: React.FC<ListWrapColumnObserverProps> = ({ columnType, columns, children }) => {

  const [sized] = useSize(
    ({ width }) => {

      const columnWidth = parseInt(getCssVariableValue('--list-column-grid-width').replace('px', ''));
      const columnBroaderWidth = parseInt(getCssVariableValue('--list-column-grid-broader-width').replace('px', ''));

      const calculateColumns = (broader?: boolean) => columns || Math.floor((width - 12) / ((broader ? columnBroaderWidth : columnWidth) + 18));

      if ([ListColumnType.Masonry, ListColumnType.MasonryBroader].includes(columnType)) {
        return (
          <div>
            <Masonry
              breakpointCols={calculateColumns(columnType === ListColumnType.MasonryBroader)}
              className={'list-masonry-wrapper'}
              columnClassName={'list-masonry-column'}
            >
              {children}
            </Masonry>
          </div>
        );
      }

      if ([ListColumnType.Vertical, ListColumnType.VerticalBroader].includes(columnType)) {
        return (
          <div
            className={'list-columns-vertical-wrapper'}
            style={{ columnCount: calculateColumns(columnType === ListColumnType.VerticalBroader) }}
          >
            {children}
          </div>
        );
      }

      return (
        <>{children}</>
      );

    },
  );

  return sized;

};

class ListComponent extends React.PureComponent<Props> {

  listRef: RefObject<any>;

  constructor(props: Props) {
    super(props);

    this.listRef = React.createRef();
  }

  onClick = (item: ListItem, event: any) => {

    const { onSelect, linkTo, backUrl, onItemClick, disabled } = this.props;

    if (disabled || item.disabled) {
      return;
    }

    if (linkTo || onSelect || onItemClick) {
      event.preventDefault();
    }

    if (onItemClick) {
      onItemClick(item);
    }

    if (linkTo) {
      this.props.history.push({ pathname: this.isSelected(item) && backUrl ? backUrl : linkTo(item) });
    } else if (onSelect) {
      onSelect(item);
    }
  };

  isSelected = (item: ListItem) => {
    const { selected, linkTo, location: { pathname } } = this.props;
    return (selected && [].concat(selected).map(s => s.id).includes(item.id)) || (linkTo && ((pathname + '/').indexOf(linkTo(item) + '/') > -1));
  };

  groupItems = () => groupBy<ListItem>(this.props.items, (item) => {

    const value = item.groupByValue;

    switch (this.props.groupBy) {

      case SortGrouping.DATE:
        return this.props.formatDate(value, { ago: true, showInvalid: true });

      case SortGrouping.LETTER:
        return value ? value[0].toUpperCase() : '';

      case SortGrouping.STRING:
        return value ? value + '' : '';

      default:
        return '';

    }
  });

  renderItem = (item: ListItem, measure?: () => void) => {

    const { linkProps, checkboxes, getControls, getPopover, backUrl, itemIsControl, disabled } = this.props;

    const isSelected = !this.props.hideCaretOnSelect && this.isSelected(item);

    const itemProps = { disabled, ...item, measure, getControls, getPopover, linkProps, checkboxes, isSelected, itemIsControl, isCloseable: !!backUrl };

    return (
      <Item key={item.id} onClick={this.onClick} {...itemProps} />
    );

  };

  wrapCollapse = (items: ListItem[], header?: string) => {
    const { itemIsCollapse, suppressCollapseAccordion, expandAll } = this.props;
    if (itemIsCollapse) {
      const collapseItems = items.map((item) => {

        const panelContent = itemIsCollapse(item);

        return {
          key: item.id,
          label: this.renderItem(item),
          collapsible: panelContent ? 'header' : 'disabled' as CollapsibleType,
          showArrow: !!panelContent,
          children: panelContent,
        };
      });

      return (
        <Collapse
          accordion={!suppressCollapseAccordion}
          destroyInactivePanel
          expandIconPosition={'end'}
          key={'collapse-' + header}
          defaultActiveKey={expandAll ? collapseItems.map(i => i.key) : undefined}
          items={collapseItems}
        />
      );
    } else {
      return <div key={'group-' + header}>{items.map(i => this.renderItem(i))}</div>;
    }
  };

  renderGroupHeader = (header: string, items: ListItem[]) => {
    const group = this.props.groupHeader?.(header, items) || (header ? { title: header, styles: undefined } : undefined);
    return group ? (<ListHeader key={header} {...group}/>) : null;
  };

  renderGroup = (groupItems: ListItem[], header: string) => {
    const groupContent = filter([
      this.renderGroupHeader(header, groupItems),
      this.wrapCollapse(groupItems, header),
      this.props.groupFooter?.(header, groupItems),
    ]);

    return this.props.columnType === undefined
      ? <React.Fragment key={header}>{groupContent}</React.Fragment>
      : <div key={header} className={cx('list-group', this.props.groupClassName?.(groupItems))}>{groupContent}</div>;
  };

  render() {

    const { items, children, groupBy, selected, onSelect, linkTo, onItemClick, itemIsControl } = this.props;

    const { translate, messages } = this.props;

    const { columnType, columns, condensed, fixedPrimaryColumnWidth } = this.props;

    const selectable = onSelect || linkTo || itemIsControl || onItemClick;
    const hasSelected = filter([].concat(selected)).length > 0;

    const showNoResult = !children && (items || []).length === 0 && !this.props.suppressNoResults;

    const containerStyles = {
      'list-selectable': selectable,
      'list-has-selected': hasSelected,
      'list-condensed': condensed,
      'list-fixed-primary-column-width': fixedPrimaryColumnWidth,
      'list-columns-horizontal': columnType === ListColumnType.Horizontal,
      'list-columns-horizontal-broader': columnType === ListColumnType.HorizontalBroader,
      'list-columns-vertical': columnType === ListColumnType.Vertical && !showNoResult,
      'list-columns-vertical-broader': columnType === ListColumnType.VerticalBroader && !showNoResult,
      'list-no-grouping': groupBy === undefined,
      'list-masonry': [ListColumnType.Masonry, ListColumnType.MasonryBroader].includes(columnType),
    };

    let content: React.ReactNode;

    if (groupBy !== undefined) {
      content = (
        <ListWrapColumnObserver columnType={columnType} columns={columns} key={columnType}>
          {flatten(map(this.groupItems(), (groupItems, header) => ([this.renderGroup(groupItems, header)])))}
        </ListWrapColumnObserver>
      );
    } else {
      content = this.wrapCollapse(items);
    }

    if (showNoResult) {
      content = (
        <div className={'list-no-results'}>
          <Icon icon={faBan}/> {translate(messages.general.list.noResults)}
        </div>
      );
    }

    const styles: React.CSSProperties = {};

    if ([ListColumnType.Horizontal, ListColumnType.HorizontalBroader].includes(columnType) && columns) {
      styles.gridTemplateColumns = 'repeat(6, minmax(0, 1fr))';
    }

    return (
      <div className={cx('list-container', containerStyles, this.props.className)} ref={this.listRef} style={styles}>
        {content}
      </div>
    );

  }

}

export const List = withRouter(injectMessages(ListComponent));
