export default {
  login: {
    welcome: 'Welcome back',
    subheader: '',
    description: 'Login with username',
    descriptionWithQuickAccess: 'Or login with username',
    bannerText: 'Save the way to your laboratory.<br/>Find your results online',
    identifier: 'Identifier',
    password: 'Password',
    submit: 'Login',
    biometrics: 'Use biometrics',
    loginWithCredentials: 'Login with credentials',
    forgot_password: 'Forgot password?',
    backToLogin: 'Back to login',
    request_account: 'Request account',
    expired: 'You have been logged out automatically due to security reasons.',
    info: {
      reset_password: 'You can now login with your newly set password.',
      sent_reset_email: 'Your password has been reset. You will receive an e-mail with further instructions.',
    },
    infoModal: {
      title: 'How can I login here?',
      // eslint-disable-next-line max-len
      content: 'After successfully registering with your doctor or laboratory, you will receive your online-results access data. You can use this to log into your account and view your laboratory results. If you have received a QR code, you can alternatively view your results via the quick results access. If you have any questions, please contact our hotline.',
      backToLogin: 'Back to login',
    },
    quickScan: {
      content: '<h3>Covid-19</h3>',
      description: 'Press the barcode button to find your test results.',
    },
    additionalContent: '',
    autoLoginError: {
      title: 'Biometric login not possible.',
      content: 'Please login using your credentials.',
    },
    toc: {
      title: 'Terms and conditions',
      accept: 'Accept',
    },
    twoFactor: {
      title: 'Two factor authentication',
      header: 'Enter the authenticator password',
      description: 'If you need any assistance, please contact your laboratory',
      submit: 'Submit password',
    },
    faceIdRequest: {
      description: 'Would you like to use biometric authentication in addition?',
      notice: 'If you want to activate the biometric authentication later, use the "Profile & Settings" menu.',
      useBiometrics: 'Use biometrics',
      postponeUseBiometrics: 'No, activate later',
    },
  },
  forgot: {
    title: 'Forgot Password',
    submit: 'Send E-mail',
    description: 'Please enter your identification. If you don\'t remember it, please contact your laboratory.',
  },
  reset: {
    title: 'Reset Password',
    submit: 'Change Password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    description: 'Please enter your new password.',
    passwordStrength: 'Password strength',
  },
  verifySms: {
    title: 'Sms Verification',
    submit: 'Submit code',
    code: 'Code',
    description: 'An sms with a verification code has been sent to your stored phone number',
    resend: 'Resend code',
    resent: 'Sms has been resent',
  },
  verifyBirthday: {
    title: 'Birthday Verification',
    description: 'Please enter your birthday to verify your identity',
    submit: 'Submit',
  },
  notPermittedModal: {
    title: 'Not permitted',
    content: 'You don´t have sufficient permissions for this action.',
  },
  enterPassword: {
    title: 'Please enter your password',
    description: 'For security reasons please enter your password to use this functionality.',
    wrongPassword: {
      title: 'Wrong password',
      content: 'The password you have entered is not correct!',
    },
  },
};
