import React from 'react';
import { ListLayoutArgs, ListLayoutSideBarHiddenSectionCallback } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import { Container, Filter, Filters } from 'components';
import { OrderDigitalSignature, OrderListMode } from 'interfaces/api';
import messages from 'messages';
import { OrderDigitalSignatureColor } from 'modules/orders/interfaces';

export const digitalSignatureFilters: Filter[] = [{
  label: messages.orders.filters.digitalSignature,
  filter: value => ({ digitalSignature: parseInt(value) }),
  disallowClear: true,
  items: [
    OrderDigitalSignature.Signed,
    OrderDigitalSignature.Unsigned,
    OrderDigitalSignature.None,
  ].map(d => ({
    label: messages.orders.digitalSignature[d],
    value: d,
    flag: [OrderDigitalSignatureColor[d]],
  })),
}];

const Component = React.memo((props: ListLayoutArgs<OrdersListContext>) => {

  const { context, bindings: { setContext } } = props;

  return (
    <Container className={'margin-top-1'}>
      <Filters
        filters={digitalSignatureFilters}
        onFilter={value => setContext({ filters: { ...context.filters, ...value } })}
        filterValues={context.filters}
      />
    </Container>
  );
});

export const DigitalSignatureSection: ListLayoutSideBarHiddenSectionCallback<OrdersListContext> = args => ({
  active: args.context.mode === OrderListMode.DigitalSignature,
  component: Component,
});
