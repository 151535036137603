import { useEffect, useState } from 'react';
import { useAuthUser } from 'modules/auth/providers';
import { ActionPerformed, PushNotifications as CorePushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import constate from 'constate';
import { useApi } from 'providers';
import { useEnv } from 'store/components/injectEnv';
import { Platform } from 'interfaces/api';
import { usePrevious } from 'react-use';
import { App } from '@capacitor/app';

const usePushNotificationContext = () => {

  const user = useAuthUser();
  const { PLATFORM } = useEnv();
  const { devices: { setPushToken } } = useApi();

  const [token, setToken] = useState<string>();
  const previousToken = usePrevious(token);

  useEffect(() => {
    if (token !== previousToken) {
      setPushToken({ pushToken: token });
    }
  }, [token, previousToken, setPushToken]);

  useEffect(() => {

    if (PLATFORM !== Platform.WEB) {

      CorePushNotifications.addListener('registration', ({ value }: Token) => {
        setToken(value);
        console.log('My token: ' + JSON.stringify(token));
      });

      CorePushNotifications.addListener('registrationError', (error: any) => {
        console.log(error);
      });

      CorePushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
      });

      CorePushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        console.log('Action performed: ' + JSON.stringify(notification.notification));
        if (data.detailsId) {
          // this.router.navigateByUrl(`/home/${data.detailsId}`);
        }
      });

      return () => {
        CorePushNotifications.removeAllListeners();
      };

    }

    return undefined;

  }, [setToken]);

  useEffect(() => {
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive && PLATFORM !== Platform.WEB) {
        CorePushNotifications.removeAllDeliveredNotifications();
      }
    });

    return () => {
      App.removeAllListeners();
    };

  });

  useEffect(() => {
    if (user && PLATFORM !== Platform.WEB) {
      CorePushNotifications.requestPermissions().then((permission) => {
        if (permission.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          CorePushNotifications.register().then(console.log).catch(console.error);
        } else {
          // No permission for push granted
        }
      }).catch(console.error);
    }

  }, [user]);

  return {
    enabled: !!token,
  };

};

const [PushNotificationProvider, usePushNotifications] = constate(usePushNotificationContext);

export {
  PushNotificationProvider,
  usePushNotifications,
};
