import React, { useEffect, useState } from 'react';
import { Container, Control, FormItem, HelgaContainer, HelgaImageType, Modal, Select, Switch } from 'components';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useIntl } from 'providers';
import { Message } from 'interfaces';
import messages from 'messages';
import { useOfficeDoctorContext, useOrdersConfig, useOrdersContext, useWizardToggleContext } from 'modules/orders/providers';
import { OrderWizardLocalization } from 'interfaces/api';
import { HvCodeSelect } from 'modules/orders/containers/OrderWizard/components/AdditionalFields/HvCodeSelect';
import { VkatSelect } from 'modules/orders/containers/OrderWizard/components/AdditionalFields/VkatSelect';

const labels = messages.orders.requirementOptions.costUnit;

export type CostUnitSelectProps = {
  visible?: boolean;
  onCancel?: () => void;
  onNext?: (value: string, allSelected?: boolean) => void;
  filterOptions?: (values: string[]) => string[];
  selectAll?: boolean;
  selectAllLabel?: Message;
  commonMode?: boolean;
};

export const CostUnitSelect: React.FC<CostUnitSelectProps> = (props) => {

  const { showCostUnitSelect, setShowCostUnitSelect, setOrderProperties, setOrder } = useOrdersContext();
  const { wizardSettings, officeDoctor } = useOfficeDoctorContext();
  const { costUnits: ordersConfigCostUnits } = useOrdersConfig();
  const { visible, onCancel, onNext, filterOptions, selectAll, selectAllLabel, commonMode } = props;

  const selectableCostUnits = wizardSettings?.costUnits || ordersConfigCostUnits;

  const { translate } = useIntl();

  const [costUnit, setCostUnit] = useState<string>();
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const [hvCode, setHvCode] = useState<string>();
  const [vkat, setVkat] = useState<string>();

  // reset on wizard open
  const toggle = useWizardToggleContext();
  useEffect(() => {
    if (toggle?.[0]) {
      setCostUnit(undefined);
      setHvCode(undefined);
      setVkat(undefined);
    }
  }, [toggle?.[0]]);

  const defaultCancel = () => setShowCostUnitSelect(undefined);

  const isAutMode = officeDoctor?.localisation === OrderWizardLocalization.AUT;

  const defaultNext = () => {
    if (isAutMode) {
      setOrder((order) => {
        return { ...order, patient: { ...(order.patient || {}), vkat, hvcode: hvCode } };
      });
    } else {
      setOrderProperties({ costUnit });
    }
    setShowCostUnitSelect(undefined);
  };

  return (
    <Modal
      open={showCostUnitSelect || visible}
      // zIndex={1310}
      footer={null}
      children={() => (
        <HelgaContainer
          title={labels.title}
          text={labels.text}
          image={HelgaImageType.Question}
          buttons={[(
            <Control
              label={messages.general.cancel}
              onClick={() => onCancel ? onCancel() : defaultCancel()}
              button={{}}
            />
          ), (
            <Control
              icon={faArrowRight}
              label={messages.general.next}
              onClick={() => onNext ? onNext(costUnit, allSelected) : defaultNext()}
              button={{
                disabled: ((isAutMode && !commonMode) ? !hvCode || (costUnit !== 'Privat' && !vkat) : !costUnit && !allSelected),
                type: 'primary',
              }}
            />
          )]}
        >
          <div className={'content form-container'}>
            {isAutMode && !selectAll
              ? (
                <Container>
                  <HvCodeSelect
                    selectRenderer={args => (
                      <Select
                        {...args}
                        popupClassName={'cost-unit-select'}
                        popupMatchSelectWidth={false}
                        onChange={value => setHvCode(value)}
                        value={hvCode}
                        placeholder={translate(messages.orders.additionalFields.hvcode)}
                      />
                    )}
                  />
                  <VkatSelect
                    selectRenderer={args => (
                      <Select
                        {...args}
                        popupClassName={'cost-unit-select'}
                        popupMatchSelectWidth={false}
                        className={'margin-top-1'}
                        onChange={value => setVkat(value)}
                        value={vkat}
                        placeholder={translate(messages.orders.additionalFields.vkat)}
                      />
                    )}
                  />
                </Container>
              )
              : (
                <Container>
                  <Select
                    popupClassName={'cost-unit-select'}
                    popupMatchSelectWidth={false}
                    options={(filterOptions ? filterOptions(selectableCostUnits) : selectableCostUnits)?.map(c => ({ value: c, label: c }))}
                    onChange={setCostUnit}
                    disabled={allSelected}
                  />
                  {!!selectAll && (
                    <FormItem label={selectAllLabel} className={'form-switch-container'} style={{ marginTop: 16 }}>
                      <Switch
                        checked={allSelected}
                        onChange={v => setAllSelected(v)}
                      />
                    </FormItem>
                  )}
                </Container>
              )}
          </div>
        </HelgaContainer>
      )}
    />
  );

};
