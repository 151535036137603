export default {

  occurred: 'An error occurred: {name}',
  canceled: 'Canceled operation',
  tryAgainLater: 'Please reload the page or try again later',

  InputError: 'Wrong input',
  InvalidCredentialsError: 'Identifier or password is wrong',
  NotAuthenticatedError: 'You are not authenticated',
  TokenBlacklistedError: 'Your session has expired',
  TokenExpiredError: 'Your session has expired',
  TokenInvalidError: 'Your session is invalid',
  ForbiddenError: 'This action is not allowed',
  UnexpectedUserStatusError: 'The user has the wrong status for this action',
  UserEmailMissingError: 'Missing E-Mail for this user',
  UserPhoneMissingError: 'Missing phone number for this user',
  UserBirthdayMissingError: 'Missing birthday for this user',
  TenantError: 'Wrong laboratory selected',
  TooManyRequestsError: 'Too many requests',
  MissingAggregatedRequirement: {
    title: 'Missing Aggregated Requirement',
    description: 'Basic data has changed, it is not possible to view or edit this order anymore.',
  },
  FreeBarcodeSplitError: 'No order split allowed',
  InitialSmsVerificationWrongCodeError: 'Provided code is not valid',
  InitialBirthdayVerificationWrongCodeError: 'Provided birthday is not valid',
  ApiRequestTimeoutError: 'Request timeout',

  TypeError: {
    title: 'Something went wrong',
    description: 'We will look into that.',
  },

  AxiosError: {
    title: 'Connection Error',
    description: 'Something is wrong with the connection',
  },

  NoNetworkError: {
    title: 'No Internet Connection',
    description: 'You are currently not connected to the internet.',
  },

  NoCameraError: 'No camera found.',
  PasswordPolicyError: 'The password does not meet the policy requirements',

  MaintenanceError: {
    title: 'Server Maintenance',
    // eslint-disable-next-line max-len
    description: 'The server is currently getting an update or is restarted. The page will automatically reload as soon as the server is back up again. Please have a little patience.',
  },

  InternalServerError: {
    title: 'Something went wrong',
    description: 'We will look into that.',
  },

  ReportInProcessError: {
    title: 'Report in process',
    description: 'The requested report is still being processed. Please try again later.',
  },

  ReportGuidWrongKeyError: 'The qr code seems to be broken. Please contact your laboratory.',
  ReportGuidTooManyGuidsRegistered: 'This qr code has already been registered too many times.',
  ReportGuidTooManyDevicesRegistered: 'This device has already registered too many qr codes.',

  NoNewCentralBarcodeLeft: {
    title: 'No barcodes left',
    description: 'There are no barcodes left to be used for a new order. Please contact your laboratory for more information.',
  },

};
