import { DispatchMethod, Store } from 'store';
import { UserDetailProperties } from 'interfaces/api';
import { findIndex } from 'lodash';
import { getClient } from 'providers/ApiProvider/client';

export const AUTH_LS_LID_KEY = 'auth_lid';

export type AuthState = Readonly<{
  user?: UserDetailProperties;
  lid?: number;
  legacy?: boolean;
  avatar?: File;
  wasAutoLoggedOut?: boolean;
  legacyRejected?: Error;
}>;

// actions

export enum ActionKeys {
  SET_USER = 'auth/SET_USER',
  SET_AVATAR = 'auth/SET_AVATAR',
  LEGACY_LOGIN = 'auth/LEGACY_LOGIN',
  LOGOUT = 'auth/LOGOUT',
  AUTO_LOGOUT = 'auth/AUTO_LOGOUT',
  SET_LID = 'auth/SET_LID',
  START_LEGACY_LOGIN = 'auth/START_LEGACY_LOGIN',
  RESOLVE_LEGACY = 'auth/RESOLVE_LEGACY',
  REJECT_LEGACY = 'auth/REJECT_LEGACY',
}

export type ActionTypes =
  | SetUserAction
  | SetAvatarAction
  | LegacyLoginAction
  | LogoutAction
  | AutoLogoutAction
  | SetLidAction
  | StartLegacyLoginAction
  | ResolveLegacyAction
  | RejectLegacyAction
  ;

export interface SetUserAction {
  type: ActionKeys.SET_USER;
  user: UserDetailProperties;
}

export interface SetAvatarAction {
  type: ActionKeys.SET_AVATAR;
  avatar: File;
}

export interface LegacyLoginAction {
  type: ActionKeys.LEGACY_LOGIN;
  user: UserDetailProperties;
}

export interface LogoutAction {
  type: ActionKeys.LOGOUT;
}

export interface AutoLogoutAction {
  type: ActionKeys.AUTO_LOGOUT;
}

export interface SetLidAction {
  type: ActionKeys.SET_LID;
  lid: number;
}

export interface StartLegacyLoginAction {
  type: ActionKeys.START_LEGACY_LOGIN;
}

export interface ResolveLegacyAction {
  type: ActionKeys.RESOLVE_LEGACY;
}

export interface RejectLegacyAction {
  type: ActionKeys.REJECT_LEGACY;
  error: Error;
}

const getStoredLid = () => {
  const storedLid = localStorage.getItem(AUTH_LS_LID_KEY);
  return storedLid ? parseInt(storedLid) : undefined;
};

// reducers

export const reducer = (state: AuthState = { lid: getStoredLid() }, action: ActionTypes): AuthState => {

  const resetActions: AuthState = {
    legacy: undefined,
    legacyRejected: undefined,
    wasAutoLoggedOut: false,
  };

  switch (action.type) {

    case ActionKeys.SET_USER:
    case ActionKeys.LEGACY_LOGIN:

      const user = action.user;

      let lid = getStoredLid() || state.lid;

      if (user && findIndex(user.labors, { lid }) === -1) {
        lid = user.labors[0].lid;
      }

      return { ...state, user, lid, ...resetActions, legacy: action.type === ActionKeys.LEGACY_LOGIN };

    case ActionKeys.LOGOUT:
      return { lid: undefined, user: undefined, ...resetActions };

    case ActionKeys.SET_AVATAR:
      return { ...state, avatar: action.avatar };

    case ActionKeys.AUTO_LOGOUT:
      return { lid: undefined, user: undefined, ...resetActions, wasAutoLoggedOut: true };

    case ActionKeys.SET_LID:
      return { ...state, lid: action.lid };

    case ActionKeys.START_LEGACY_LOGIN:
      return { ...state, legacy: true };

    case ActionKeys.RESOLVE_LEGACY:
      return { ...state, ...resetActions };

    case ActionKeys.REJECT_LEGACY:
      return { ...state, ...resetActions, legacyRejected: action.error };

    default:
      return state;
  }
};

// action creators

export const createSetUserAction = (user: UserDetailProperties): SetUserAction => {
  return { user, type: ActionKeys.SET_USER };
};

export const createSetAvatarAction = (avatar: File): SetAvatarAction => {
  return { avatar, type: ActionKeys.SET_AVATAR };
};

export const createLegacyLoginAction = (user: UserDetailProperties): LegacyLoginAction => {
  return { user, type: ActionKeys.LEGACY_LOGIN };
};

export const createLogoutAction = (): LogoutAction => {
  return { type: ActionKeys.LOGOUT };
};

export const createStartLegacyLoginAction = (): StartLegacyLoginAction => {
  return { type: ActionKeys.START_LEGACY_LOGIN };
};

export const createResolveLegacyAction = (): ResolveLegacyAction => {
  return { type: ActionKeys.RESOLVE_LEGACY };
};

export const createRejectLegacyAction = (error: Error): RejectLegacyAction => {
  return { error, type: ActionKeys.REJECT_LEGACY };
};

export const createSetLidAction = (lid: number): SetLidAction => {
  if (lid) {
    localStorage.setItem(AUTH_LS_LID_KEY, lid + '');
  }
  return { lid, type: ActionKeys.SET_LID };
};

type LegacyLoginAid = { aid: string; key: string };
type LegacyLoginToken = { token: string };

const isLegacyLoginToken = (obj: LegacyLoginToken | any): obj is LegacyLoginToken => {
  return obj !== undefined && (obj as LegacyLoginToken).token !== undefined;
};

export const legacyLogin: DispatchMethod = (data: LegacyLoginAid | LegacyLoginToken) => async (dispatch, getState) => {

  const state: Store = getState();
  const { BACKEND_URL: endpoint, TARGET: target } = state.env;

  const api = getClient({ endpoint, target, version: APP_VERSION }).authentication;

  dispatch(createStartLegacyLoginAction());

  const response = await (() => {
    if (isLegacyLoginToken(data)) {
      return api.exchangeToken(data);
    } else {
      return api.createLegacyToken(data);
    }
  })();

  dispatch(createLegacyLoginAction(response));
};
