import React, { ComponentType, FC } from 'react';
import { filter } from 'lodash';

type ComposeComponent<T> = ComponentType<T> | [ComponentType<T>, T];

interface Props {
  children?: React.ReactNode;
  components: ComposeComponent<any>[];
}

export const Compose: FC<Props> = ({ components, children }) => (
  <>
    {filter(components).reduceRight((acc, curr) => {
      const [Provider, props] = Array.isArray(curr) ? [curr[0], curr[1]] : [curr, {}];
      return <Provider {...props}>{acc}</Provider>;
    }, children)}
  </>
);
