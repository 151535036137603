import { LanguageCode, Platform } from 'interfaces/api';

export const ENV_REDIRECTED_URL_KEY = 'env_redirected_url';

type EnvFlags = {
  isWeb?: boolean;
  isNative?: boolean;
  isDevelopment?: boolean;
  isProductive?: boolean;
  locales?: LanguageCode[];
};

export type EnvState = EnvFlags & {
  NODE_ENV?: string;
  BACKEND_URL?: string;
  TARGET?: string;
  TITLE?: string;
  TITLE_SEPARATOR?: string;
  PRIVACY_URL?: string;
  BUILD_NUMBER?: string;
  BUILD_DATE?: string;
  PLATFORM?: Platform;
  APP_NAME?: string;
  CHECK_WEBSOCKET_MAINTENANCE?: boolean;
  REPORTS_GRAPH_NO_ORANGE?: boolean;
  HERO_PAGE_FULL_SIZE_COVER?: boolean;
  STATUS_BAR_DARK_MODE?: boolean;
  HIDE_PROFILE_LABORATORY?: boolean;
  COUNTRIES?: Map<string, string>;
  I18N_LOCALES_SHORT?: string;
  DISABLE_SENTRY?: string;
  CH_BAGS?: Map<string, string>;
  CH_GLNS?: Map<string, string>;
};

// export const env = process.env;

const initialState: EnvState = {
  NODE_ENV: import.meta.env.MODE || 'production',
  TARGET: 'local',
  COUNTRIES: new Map(),
  // ...env,
  // REPORTS_GRAPH_NO_ORANGE: env.REPORTS_GRAPH_NO_ORANGE === '1',
  // CHECK_WEBSOCKET_MAINTENANCE: env.CHECK_WEBSOCKET_MAINTENANCE === '1',
  // HERO_PAGE_FULL_SIZE_COVER: env.HERO_PAGE_FULL_SIZE_COVER === '1',
};

// actions

export enum ActionKeys {
  SET_ENV = 'env/SET_ENV',
  SET_PLATFORM = 'env/SET_PLATFORM',
  SET_COUNTRIES = 'env/SET_COUNTRIES',
  SET_CH_BAGS = 'env/SET_CH_BAGS',
  SET_CH_GLNS = 'env/SET_CH_GLNS',
}

export type ActionTypes =
  | SetEnvAction
  | SetPlatformAction
  | SetCountriesAction
  | SetChBagsAction
  | SetChGlnsAction
  ;

export interface SetEnvAction {
  type: ActionKeys.SET_ENV;
  env: EnvState;
}

export interface SetPlatformAction {
  type: ActionKeys.SET_PLATFORM;
  platform: Platform;
}

export interface SetCountriesAction {
  type: ActionKeys.SET_COUNTRIES;
  countries: Map<string, string>;
}

export interface SetChBagsAction {
  type: ActionKeys.SET_CH_BAGS;
  chBags: Map<string, string>;
}

export interface SetChGlnsAction {
  type: ActionKeys.SET_CH_GLNS;
  chGlns: Map<string, string>;
}

// reducers

const appendEnvFlags = (env: EnvState) => ({
  ...env,
  isWeb: env.PLATFORM === Platform.WEB,
  isNative: env.PLATFORM !== Platform.WEB,
  isDevelopment: env.NODE_ENV === 'development',
  isProductive: env.NODE_ENV === 'production',
  locales: (env.I18N_LOCALES_SHORT || 'en,de').split(',') as LanguageCode[],
});

export const reducer = (state: EnvState = initialState, action: ActionTypes): EnvState => {

  const newState = (() => {
    switch (action.type) {

      case ActionKeys.SET_ENV:
        return { ...state, ...action.env };

      case ActionKeys.SET_PLATFORM:
        return { ...state, PLATFORM: action.platform };

      case ActionKeys.SET_COUNTRIES:
        return { ...state, COUNTRIES: action.countries };

      case ActionKeys.SET_CH_BAGS:
        return { ...state, CH_BAGS: action.chBags };

      case ActionKeys.SET_CH_GLNS:
        return { ...state, CH_GLNS: action.chGlns };

      default:
        return state;
    }
  })();

  return appendEnvFlags(newState);

};

// action creators

export const createSetEnvAction = (env: EnvState): SetEnvAction => {
  return { env, type: ActionKeys.SET_ENV };
};

export const createSetPlatformAction = (platform: Platform): SetPlatformAction => {
  return { platform, type: ActionKeys.SET_PLATFORM };
};

export const createSetCountriesAction = (countries: Map<string, string>): SetCountriesAction => {
  return { countries, type: ActionKeys.SET_COUNTRIES };
};

export const createSetChBagsAction = (chBags: Map<string, string>): SetChBagsAction => {
  return { chBags, type: ActionKeys.SET_CH_BAGS };
};

export const createSetChGlnsAction = (chGlns: Map<string, string>): SetChGlnsAction => {
  return { chGlns, type: ActionKeys.SET_CH_GLNS };
};
