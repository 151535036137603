import React from 'react';
import { faPrint } from '@fortawesome/pro-regular-svg-icons';
import { OrderControlContext } from 'modules/orders/index';
import { ChooseOrdersControl } from 'modules/orders/components/ChooseOrdersControl';
import { useOrderRulesContext } from 'modules/orders/providers';
import messages from 'messages';
import { useConfig } from 'providers';
import { OrderStatus } from 'interfaces/api';
import { isSingleOrderView } from 'modules/orders/utils';

const labels = messages.orders.controls.prePrint;

export const PrePrint = (props: OrderControlContext) => {

  const { data: { orders, doctor: { aid } }, context, bindings: { reload, setDetailView } } = props;
  const { setPending } = useOrderRulesContext();

  const { preferences: { ordersPrePrintIsC2A } } = useConfig();

  return (
    <ChooseOrdersControl
      orders={orders}
      icon={faPrint}
      disabledCheck={order => order.status !== OrderStatus.Saved}
      button={{
        block: true,
        type: ordersPrePrintIsC2A ? 'primary' : 'default',
      }}
      label={labels.label}
      notifications={{
        success: labels.notifications.success,
        error: false,
      }}
      onDone={orders => new Promise((resolve, reject) => {
        setPending({
          orders, aid, reject, prePrint: true, ignorePool: true, resolve: (resp: any) => {
            reload();
            setDetailView(isSingleOrderView(context) ? resp.order.id : resp.order.orders[0].id);
            resolve(undefined);
          },
        });
      })}
    />
  );
};
