import React, { useCallback, useEffect, useState } from 'react';
import constate from 'constate';
import { GlobalConfigProperties, PreferencesProperties } from 'interfaces/api';
import { useAuthLid, useAuthUser } from 'modules/auth/providers';
import { useApi } from 'providers';
import { useAsync } from 'react-use';
import { useFormatPriceDecimalTypeValue } from 'providers/IntlProvider/useFormatPrice';

type ConfigContextInitialValue = {
  config: GlobalConfigProperties;
};

const configContext = (initialValue: ConfigContextInitialValue) => {

  const [config, setInternalConfig] = useState<GlobalConfigProperties>(initialValue.config);
  const setConfig = useCallback((newConfig: Partial<GlobalConfigProperties>) => setInternalConfig(prev => ({ ...prev, ...newConfig })), []);

  const setDecimalType = useFormatPriceDecimalTypeValue()[1];
  useEffect(() => {
    if (config?.system?.formatPriceDecimalType) {
      setDecimalType(config.system.formatPriceDecimalType);
    }
  }, [config?.system?.formatPriceDecimalType]);

  const getPreference = useCallback((key: keyof PreferencesProperties) => config.preferences[key], [config]);
  const setPreference = useCallback(<K extends keyof PreferencesProperties>(key: K, value: PreferencesProperties[K]) => {
    setConfig({ preferences: config.preferences, [key]: value });
  }, [config, setConfig]);

  return { config, setConfig, getPreference, setPreference };

};

const [ConfigInternalProvider, useConfig, useSetConfig] = constate(
  configContext,
  values => values.config,
  values => values.setConfig,
);

export {
  useConfig,
  useSetConfig,
};

export const ConfigProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const lid = useAuthLid();
  const user = useAuthUser();

  const { globals: { getUserConfig } } = useApi();

  const config = useAsync(getUserConfig, [lid, user?.id]);

  if (config.loading) {
    return null;
  }

  if (config.error) {
    throw config.error;
  }

  return (
    <ConfigInternalProvider config={config.value}>
      {children}
    </ConfigInternalProvider>
  );
};
