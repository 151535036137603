import React, { useState } from 'react';
import { Container, ControlProps, Icon, List, ListItem, Loader, Modal, ModalFormControl, Tabs } from 'components';
import { CostUnitSelect } from 'modules/orders/containers/OrderWizard/components/CostUnitSelect';
import { ConfirmCopyModal } from 'modules/orders/containers/OrderWizard/components/CopyProfilesControl/ConfirmCopyModal';
import { App } from 'antd';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import messages from 'messages';
import { useApi, useIntl, useLogger } from 'providers';
import { useGuard } from 'containers';
import {
  AggregatedProfileProperties,
  AggregatedProfileType,
  Feature,
  FormNotFoundProperties,
  OrderWizardLocalization,
  ProfileNameCollisionProperties,
  ProfileNameConfirmProperties,
} from 'interfaces/api';
import { AnyFunction, groupProfilesByCostUnit } from 'utils/helpers';
import * as _ from 'lodash';

type Props = ControlProps & {
  aid: number;
  profiles: AggregatedProfileProperties[];
  onSuccess?: AnyFunction;
  localisation: OrderWizardLocalization;
};

const labels = messages.orders.wizard.profiles;

export const CopyProfilesControl = (props: Props) => {

  const { message } = App.useApp();

  const { aid, profiles: allProfiles, localisation, onSuccess, ...controlProps } = props;

  const profiles = _.filter(allProfiles, p => p.entityType === AggregatedProfileType.Personal);

  const { translate } = useIntl();

  const logger = useLogger();

  const { profiles: { batchUpdateProfiles, copyProfiles } } = useApi();

  const guard = useGuard();

  const hasRequirementDiagnosesFeature = guard({ feature: Feature.RequirementDiagnoses }, () => true);

  const profileGroups = groupProfilesByCostUnit(profiles, hasRequirementDiagnosesFeature);

  const [selectedProfiles, setSelectedProfiles] = useState<ListItem[]>([]);
  const [openCostunitSelect, setOpenCostunitSelect] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [prevTargets, setPrevTargets] = useState<string[]>([]);
  const [prevAllSelected, setPrevAllSelected] = useState<boolean>(false);
  const [formNotFound, setFormNotFound] = useState<FormNotFoundProperties[]>([]);
  const [nameCollision, setNameCollision] = useState<ProfileNameCollisionProperties[]>([]);

  const performCopy = async (
    aid: number,
    profileIds: number[],
    targetCostUnits: string[],
    hide: () => void,
    toAllOthers?: boolean,
    confirmed?: boolean,
    confirmedNames?: ProfileNameConfirmProperties[],
    onProfilesChanged?: (profiles: AggregatedProfileProperties[]) => void,
  ) => {
    try {
      const res = await copyProfiles({ aid, profileIds, targetCostUnits, toAllOthers, confirmed, confirm: { confirmedNames } });

      setFormNotFound(res.confirm?.formNotFound);
      setNameCollision(res.confirm?.nameCollision);

      if (res.done) {
        message.success(translate(messages.orders.wizard.profiles.controls.copy.notifications.success));
        onProfilesChanged?.(res.profiles);
        onSuccess?.(res.profiles);
        setSelectedProfiles([]);
        hide();
      } else {
        if (!confirmed && res.confirm) {
          setOpenConfirm(true);
        } else {
          // unknown problem
          logger.error(res);
          message.error(translate(messages.orders.wizard.profiles.controls.copy.notifications.error));
        }
      }
    } catch (e) {
      logger.error(e);
      message.error(translate(messages.orders.wizard.profiles.controls.copy.notifications.error));
    } finally {
      setOpenCostunitSelect(false);
      setLoading(false);
    }
  };

  return (
    <ModalFormControl
      label={labels.copy}
      icon={faCopy}
      {...controlProps}
      modal={{
        okButtonProps: {
          disabled: !selectedProfiles?.length,
          icon: <Icon icon={faArrowRight}/>,
          onClick: () => setOpenCostunitSelect(true),
        },
        narrow: true,
        okText: messages.general.next,
        title: labels.copy,
      }}
      form={{
        onSuccess,
        initialValue: {
          aid,
          profileGroups,
        },
        request: (values) => {
          const profiles = _.flatMap(_.values(values.profileGroups), g => _.map(g.profiles, p => _.omit(p, 'type')));
          return batchUpdateProfiles({ aid, profiles });
        },
        children: ({ value, onChange, hide }) => (
          <>
            <Tabs
              tabs={_.map(_.values(profileGroups), g => ({
                title: g.name,
                children: (
                  <Container scrollY>
                    <List
                      checkboxes
                      selected={selectedProfiles}
                      items={_.map(g.profiles, (p => ({
                        id: p.id,
                        title: p.name,
                      })))}
                      onSelect={(item) => {
                        const selected = selectedProfiles.slice();
                        const selectedIndex = _.findIndex(selected, s => s.id === item.id);

                        if (selectedIndex > -1) {
                          selected.splice(selectedIndex, 1);
                        } else {
                          selected.push(item);
                        }

                        setSelectedProfiles(selected);
                      }}
                    />
                  </Container>
                ),
              }))}
            />
            <ConfirmCopyModal
              open={openConfirm}
              formNotFound={formNotFound}
              nameCollision={nameCollision}
              next={async (confirmedNames) => {
                setOpenConfirm(false);
                await performCopy(aid, _.map(selectedProfiles, p => +p.id), prevTargets, hide, prevAllSelected, true, confirmedNames);
              }}
              cancel={() => setOpenConfirm(false)}
            />
            <Modal
              open={openCostunitSelect}
              // zIndex={1080}
              footer={null}
              children={(
                <Container grow shrink padding>
                  <CostUnitSelect
                    visible={!loading}
                    commonMode={true}
                    filterOptions={options => options?.filter(o => o !== value.costUnit)}
                    onCancel={() => {
                      setOpenCostunitSelect(false);
                    }}
                    onNext={async (costUnit, allSelected) => {
                      setPrevTargets([costUnit]);
                      setPrevAllSelected(allSelected);

                      setLoading(true);

                      await performCopy(
                        aid,
                        _.map(selectedProfiles, p => +p.id),
                        [costUnit],
                        hide,
                        allSelected, false, undefined,
                        profiles => onChange({ profileGroups: groupProfilesByCostUnit(profiles, hasRequirementDiagnosesFeature) }),
                      );
                    }}
                    selectAll={true}
                    selectAllLabel={messages.orders.wizard.profiles.controls.copy.toAllOthers.title}
                  />
                  {loading && <Loader/>}
                </Container>
              )}
            />
          </>
        ),
      }}
    />
  );
};
