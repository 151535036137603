import React from 'react';
import { useApiClient } from './useApiClient';
import { useAuthLid } from 'modules/auth/providers';
import constate from 'constate';
import { ApiDefinition } from 'interfaces/api';

const apiContext = (): ApiDefinition => {
  const lid = useAuthLid();
  return useApiClient(lid);
};

const [ApiProvider, getApiClient] = constate(apiContext);

const useApi: () => ApiDefinition = getApiClient;

export { ApiProvider, useApi };

export type InjectedApiProps = {
  api: ApiDefinition;
};

type OwnProps<P extends InjectedApiProps> = Omit<P, keyof InjectedApiProps>;

export function injectApi<P extends InjectedApiProps>(ComposedComponent: React.ComponentType<P>): React.ComponentType<OwnProps<P>> {
  return (props: P & InjectedApiProps) => {
    const api = useApi();
    return <ComposedComponent {...props} api={api}/>;
  };
}
