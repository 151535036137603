import { useCallback, useMemo } from 'react';
import { FormatPriceDecimalType, LanguageCode } from 'interfaces/api';
import { createGlobalState } from 'react-use';

type FormatPriceFunc = (price: number | string, options?: FormatPriceOptions) => string | undefined;

export interface FormatPriceOptions {
  fractionDigits?: number;
}

export const useFormatPriceDecimalTypeValue = createGlobalState<FormatPriceDecimalType>(FormatPriceDecimalType.LocalString);

export const useFormatPrice = (locale: LanguageCode) => {

  const [decimalType] = useFormatPriceDecimalTypeValue();

  const localeString = useMemo(() => ({
    [FormatPriceDecimalType.LocalString]: locale,
    [FormatPriceDecimalType.Comma]: LanguageCode.DE,
    [FormatPriceDecimalType.Dot]: LanguageCode.EN,
  }[decimalType]), [locale, decimalType]);

  return useCallback<FormatPriceFunc>((price: number | string, options = { fractionDigits: 2 }) => {

    const parsedPrice = (price === typeof Number ? price : parseFloat(price as string)) as number;

    const { fractionDigits } = options;
    return parseFloat(parsedPrice.toFixed(fractionDigits)).toLocaleString(localeString, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });

  }, [locale, decimalType]);
};
