import React from 'react';
import { DoctorBarcodeMode, Feature, OrdersPrintSettings } from 'interfaces/api';
import messages from 'messages';
import { FormLayout, useGuard } from 'containers';
import { faCircleCheck, faCircleExclamation, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { splitEnumOptions } from 'utils/helpers';
import { FormItem, FormItemRenderProps, Icon } from 'components';
import { Color } from 'interfaces';

const labels = messages.admin.order.printSettings;

export const PrintSettingsForm: React.ComponentType<FormItemRenderProps<OrdersPrintSettings>> = (props) => {

  const { Input, Select, Switch, Plaintext, value } = props;
  const guard = useGuard();

  const barcodeModes = splitEnumOptions(DoctorBarcodeMode, labels.barcodeMode.selectLabels)
    .filter(o => o.value !== DoctorBarcodeMode.CentralBarcodes || guard({ feature: Feature.CentralBarcodes }, () => true));

  const centralStatusIcon = value.centralBarcodesAvailableCount < (value.centralBarcodesThreshold / 2)
    ? <Icon icon={faCircleXmark} tooltip={labels.statusHints.error} color={Color.Red} style={{ fontSize: 24 }}/>
    : value.centralBarcodesAvailableCount < value.centralBarcodesThreshold
      ? <Icon icon={faCircleExclamation} tooltip={labels.statusHints.warning} color={Color.Yellow} style={{ fontSize: 24 }}/>
      : <Icon icon={faCircleCheck} tooltip={labels.statusHints.ok} color={Color.Green} style={{ fontSize: 24 }}/>;

  return (
    <>
      <FormLayout label={labels.groupLabel.barcode} embedded>
        <Select
          property={'barcodeMode'}
          label={labels.barcodeMode.label}
          options={barcodeModes}
        />

        {value.barcodeMode === DoctorBarcodeMode.CentralBarcodes && (
          <>
            <FormItem label={labels.availableCount} className={'form-plain-text-wide'}>
              <span>{value.centralBarcodesAvailableCount}</span>
              <span>{centralStatusIcon}</span>
            </FormItem>
            <Plaintext property={'centralBarcodesNextBarcode'} label={labels.nextBarcode}/>
          </>
        )}

        {value.barcodeMode !== DoctorBarcodeMode.CentralBarcodes && (
          <>
            <Input type={'number'} property={'printRangeMin'} label={labels.printRangeMin}/>
            <Input type={'number'} property={'printRangeMax'} label={labels.printRangeMax}/>

            <Input property={'printPrefix'} label={labels.printPrefix}/>
            <Input type={'number'} property={'printPosition'} label={labels.printPosition}/>

            <Switch property={'overwrite'} label={labels.overwrite}/>
            <Switch property={'useOwnNummernkreis'} label={labels.useOwnRange}/>
            <Switch property={'useLgNummernkreis'} label={labels.useLaboratoryGroupRange}/>
          </>
        )}
      </FormLayout>

      <FormLayout label={labels.groupLabel.form} embedded>
        <Switch property={'socketPrint'} label={labels.socketPrint}/>
      </FormLayout>
    </>
  );
};
