import { Feature, FeatureSetting } from 'interfaces/api';

export default {

  settings: {
    doctors: {
      [FeatureSetting.None]: 'NO doctor',
      [FeatureSetting.All]: 'ALL doctors',
      [FeatureSetting.ExplicitLock]: 'ALL doctors except explicitly locked',
      [FeatureSetting.ExplicitUnlock]: 'NO doctor except explicitly unlocked',
    },
    patients: {
      [FeatureSetting.None]: 'NO patient',
      [FeatureSetting.All]: 'ALL patients',
      [FeatureSetting.ExplicitLock]: 'ALL patients except explicitly locked',
      [FeatureSetting.ExplicitUnlock]: 'NO patient except explicitly unlocked',
    },
  },
  feature: {
    [Feature.CorrectReport]: {
      title: 'Correction Report',
      description: '',
    },
    [Feature.OriginalReport]: {
      title: 'Original Report',
      description: '',
    },
    [Feature.OriginalReportExclusive]: {
      title: 'Original Report Exclusive',
      description: 'You can use this feature to specify that doctors or patients only receive original reports',
    },
    [Feature.AutoLockReport]: {
      title: 'Auto Lock Report',
      description: '',
    },
    [Feature.LanrSearch]: {
      title: 'LANR Search',
      description: 'Allows you to filter it after LANR',
    },
    [Feature.ExportMEFormat]: {
      title: 'Export ME Format',
      description:
      // eslint-disable-next-line max-len
        'Export ME format, your customers can determine for himself whether they want to receive exports in ME format. Doctors can also determine which state they want to receive (partial report, endreport, both)',
    },
    [Feature.ReportAccessLog]: {
      title: 'Report Access Log',
      description:
        'With this feature, you can obtain additional information about Reports accessed.',
    },
    [Feature.ReportChangeLog]: {
      title: 'Report Change Log',
      description:
        'With this feature, you can obtain additional information about the Reports. For example: the time of writing of the Reports or the Reports updates.',
    },
    [Feature.CumulativeReport]: {
      title: 'Cumulative Report',
      description:
        'By enabling this feature, the creation and display of cumulative reports is possible.',
    },
    [Feature.GraphicalBDW]: {
      title: 'Graphical BDW',
      description:
      // eslint-disable-next-line max-len
        'Graphical stage of the values ​​means the graphical representation of the values ​​you can view the result of the course you selected examinations of a patient graphically and compare with other studies of the same patient.',
    },
    [Feature.ObBakModule]: {
      title: 'Bak module',
      description: '',
    },
    [Feature.Elpho]: {
      title: 'Electrophoresis',
      description: '',
    },
    [Feature.ReportComments]: {
      title: 'Report Comments',
      description:
        'By enabling this feature, add comments to reports is possible.',
    },
    [Feature.IpRestriction]: {
      title: 'IP Restriction',
      description:
        'Using this function, you can set that they can only log in via an IP address.',
    },
    [Feature.DeviceIdAuth]: {
      title: 'Device ID Authentication',
      description: '',
    },
    [Feature.PdfPassword]: {
      title: 'PDF Report Password',
      description: '',
    },
    [Feature.ShowAccountingType]: {
      title: 'Show Accounting Type',
      description: '',
    },
    [Feature.ReportValuesCustomSort]: {
      title: 'Report Values Custom Sort',
      description: '',
    },
    [Feature.EndlessScrollReports]: {
      title: 'Endless Report Scrolling',
      description: '',
    },
    [Feature.MailTemplateGroups]: {
      title: 'Mail Template Groups',
      description: '',
    },
    [Feature.AutoActivatePatients]: {
      title: 'Auto Activate Patients',
      description: '',
    },
    [Feature.PatientsSmsVerification]: {
      title: 'Patients Sms Verification',
      description: 'Patients need to verify authentication with sms',
    },
    [Feature.PatientsBirthdayVerification]: {
      title: 'Patients Birthday Verification',
      description: 'Patients need to verify authentication with birthday',
    },
    [Feature.SetDownloadPdfAnalyses]: {
      title: 'Set Download Pdf Analyses',
      description: 'Enable or disable certain analyses for PDF download',
    },
    [Feature.ManualReadReports]: {
      title: 'Manual Read Reports',
      description: 'The user needs to manually set the report to read',
    },
    [Feature.GroupMultipleReports]: {
      title: 'Group Multiple Reports',
      description: 'Reports that have a parent_bid are grouped into a single report view',
    },
    [Feature.LaboratoryGroupReportFilter]: {
      title: 'Laboratory Group Report Filter',
      description: 'Using this function, you can set and use filters by laboratory groups',
    },
    [Feature.UnrealRerequest]: {
      title: 'Unreal Rerequest',
      description: '',
    },
    [Feature.Blacklist]: {
      title: 'Blacklist',
      description: '',
    },
    [Feature.CustomPush]: {
      title: 'Custom Push',
      description: '',
    },
    [Feature.Poct]: {
      title: 'POCT',
      description: '',
    },
    [Feature.PoolModule]: {
      title: 'Pool Module',
      description: '',
    },
    [Feature.BakModule]: {
      title: 'Bak Module',
      description: '',
    },
    [Feature.DigitalSignatureModule]: {
      title: 'Digital Signature Module',
      description: '',
    },
    [Feature.Journal]: {
      title: 'Journal',
      description: '',
    },
    [Feature.SuperRequirement]: {
      title: 'Super Requirement',
      description: 'You can use this feature to automatically select dependent requirements via a super requirement',
    },
    [Feature.TransportList]: {
      title: 'Transport List',
      description: '',
    },
    [Feature.RealRerequest]: {
      title: 'Real Rerequest',
      description: '',
    },
    [Feature.ClosureList]: {
      title: 'Final List',
      description: 'With this feature, you can use the final list',
    },
    [Feature.GroupForms]: {
      title: 'Group Forms',
      description: '',
    },
    [Feature.PriceManagement]: {
      title: 'Price Management',
      description: '',
    },
    [Feature.IndicationSearch]: {
      title: 'Indication Search',
      description: '',
    },
    [Feature.DynamicProbeType]: {
      title: 'Dynamic Probe Type',
      description: '',
    },
    [Feature.FormSplit]: {
      title: 'Form Splitting',
      description: 'With this feature you can adjust form splitting',
    },
    [Feature.RequestGroups]: {
      title: 'Request groups',
      description: '',
    },
    [Feature.EditRequirements]: {
      title: 'Edit Requests',
      description: 'Within this Feature to can edit and modifiy some of the Order entry Master Data',
    },
    [Feature.ExportNoteToFile]: {
      title: 'Export Note To File',
      description: '',
    },
    [Feature.MaterialZone]: {
      title: 'Material Zone',
      description: '',
    },
    [Feature.SpecialBardcodes]: {
      title: 'Special Bardcodes',
      description: 'Creation of individual order barcodes. Using this feature, individual labels can be defined for each laboratory group.',
    },
    [Feature.AnamnesticInfo]: {
      title: 'Anamnestic information',
      description: 'With this feature you can create anamnestic information.',
    },
    [Feature.LaboratoryGroupBarcodes]: {
      title: 'Laboratory groups barcode print range',
      description: 'With this feature you can define your own barcode number ranges for laboratory groups',
    },
    [Feature.OrderOvertaker]: {
      title: 'Order Overtaker',
      description: '',
    },
    [Feature.MultipleWorkplaces]: {
      title: 'Multiple Workspaces',
      description: '',
    },
    [Feature.ClientCertificates]: {
      title: 'Client Certificates',
      description: '',
    },
    [Feature.ExchangeViewFile]: {
      title: 'Preview/Download files',
      description: 'Enables previewing and downloading files',
    },
    [Feature.OrderCourier]: {
      title: 'Courier',
      description: 'Enables requesting courier service',
    },
    [Feature.AdvancedAlphanumericBarcodes]: {
      title: 'Advanced Alphanumeric Barcodes',
      description: 'Enables Advanced Alphanumeric Barcodes',
    },
    [Feature.KBVMuster10C]: {
      title: 'KBV Muster 10C form type',
      description: '',
    },
    [Feature.KBVMusterOegd]: {
      title: 'KBV Muster OEGD form type',
      description: '',
    },
    [Feature.MassOrders]: {
      title: 'Mass Orders',
      description: '',
    },
    [Feature.RequirementDiagnoses]: {
      title: 'Requirement Diagnoses',
      description: 'Set predefined diagnoses for requirements',
    },
    [Feature.Anamnesis]: {
      title: 'Anamnesis',
      description: 'Enable anamnesis questionnaire.',
    },
    [Feature.FormLevelPrices]: {
      title: 'Form Level Prices',
      description: 'This feature allows you to set requirement prices at the form level.',
    },
    [Feature.LaboratoryImportES]: {
      title: 'Laboratory Import Elastic Search Requirements',
      description: 'Enable laboratory to start import elastic search requirements job.',
    },
    [Feature.DoctorHierarchy]: {
      title: 'Doctor Hierarchy',
      description: 'Enable doctor hierarchy.',
    },
    [Feature.CentralBarcodes]: {
      title: 'Central Barcodes',
      description: 'Doctors with this feature and manual barcode enabled will get the next barcode available from a_central_barcode',
    },
    [Feature.GdtImport]: {
      title: 'GDT Import',
      description: 'This feature enables gdt import from AIS',
    },
    [Feature.KisModusEdit]: {
      title: 'Edit in KIS Mode',
      description: 'This feature enables editing patient information in KIS mode',
    },
    [Feature.OrderWizardShowPatientContact]: {
      title: 'Show Patient Contact in Order Wizard',
      description: 'Enables editing patients email and phone in order wizard',
    },
    [Feature.RequirementFreeText]: {
      title: 'Requirement Free Text',
      description: 'This feature enables to write a free text for each requirement in the basket',
    },
    [Feature.WizardScanPatient]: {
      title: 'Scan Patient in Wizard',
      description: '',
    },
    [Feature.GynFields]: {
      title: 'GYN Fields',
      description: 'Shows additional gyn fields',
    },
    [Feature.HzvField]: {
      title: 'HZV Field',
      description: '',
    },
    [Feature.BdwNewOrder]: {
      title: 'New order control in BDW',
      description: '',
    },
    [Feature.Mails]: {
      title: 'E-Mail Notifications',
      description: '',
    },
    [Feature.CumulativeExportAll]: {
      title: 'Export all cumulative pdf values',
      description: '',
    },
    [Feature.CountryCodeSelect]: {
      title: 'Country code via select field',
      description: 'Enables the country selection via select with autocomplete possibility',
    },
    [Feature.DuplicateReports]: {
      title: 'Duplicate reports',
      description: '',
    },
    [Feature.PriceGroupManagement]: {
      title: 'Price group management',
      description: '',
    },
    [Feature.MaximumPriceManagement]: {
      title: 'Maximum price management',
      description: '',
    },
    [Feature.MvzVoucherApiGeneration]: {
      title: 'MvzVoucherApiGeneration',
      description: '',
    },
    [Feature.CancelRequirement]: {
      title: 'Cancel order requirement',
      description: 'Enables single requirement cancellation',
    },
    [Feature.SampleInLaboratory]: {
      title: 'Sample In Laboratory',
      description: '',
    },
    [Feature.PatientPdf]: {
      title: 'Patient PDF',
      description: '',
    },
    [Feature.RequirementsAdditionalText]: {
      title: 'Requirements Additional Text',
      description: '',
    },
    [Feature.ReportCommentRtf]: {
      title: 'RTF conversion',
      description: '',
    },
    [Feature.Airport]: {
      title: 'Airport Mode',
      description: '',
    },
    [Feature.Vetorders]: {
      title: 'Veterinary Mode',
      description: '',
    },
    [Feature.ExternalOrderInfo]: {
      title: 'External Order Info',
      description: '',
    },
    [Feature.ReportNotificationFilters]: {
      title: 'Report Notification Filters',
      description: 'Customize notifications for new reports',
    },
    [Feature.SwitzerlandInsuranceMapping]: {
      title: 'Switzerland insurance mapping',
      description: '',
    },
    [Feature.OrderChangeLog]: {
      title: 'Order change log',
      description: '',
    },
    [Feature.BarcodeFactor]: {
      title: 'Barcode factor',
      description: '',
    },
    [Feature.PrinterMapping]: {
      title: 'Printer Mapping',
      description: 'Check get params for PrinterAliasName and get override printer names from mapping table',
    },
  },
};
