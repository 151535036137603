import React from 'react';
import { useIntl } from 'providers';
import messages from 'messages';
import { App } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';

export type InjectedMessagesProps = ReturnType<typeof useIntl> & {
  message: MessageInstance;
  messages: typeof messages;
};

type OwnProps<P extends InjectedMessagesProps> = Omit<P, keyof InjectedMessagesProps>;

export function injectMessages<P extends InjectedMessagesProps>(ComposedComponent: React.ComponentType<P>): React.ComponentType<OwnProps<P>> {
  return (props: P & InjectedMessagesProps) => {
    const messagesProps = useMessages();
    const { message } = App.useApp();
    return <ComposedComponent {...props} {...messagesProps} message={message}/>;
  };
}

export const useMessages = (): InjectedMessagesProps => {
  const intl = useIntl();
  const { message } = App.useApp();
  return { messages, ...intl, message };
};
