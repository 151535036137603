export const enum ReportValueStatus {
  Ok = 0,
  Pathological = 1,
  Default = 2,
}

export type OrderFormPdfProps = {
  id: number;
  mode?: string;
  autoPrint?: boolean;
};
