import React from 'react';
import { ApiRequest, ListLayoutArgs, ListLayoutSideBarHiddenSectionCallback } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import { Checkbox, Container } from 'components';
import { FormatDate, Translate, useApi, useIntl } from 'providers';
import { DatePicker } from 'antd';
import { getOrderTransportationSummary } from 'modules/orders/utils';
import { OrderListMode } from 'interfaces/api';
import { map } from 'lodash';
import messages from 'messages';
import './Transportation.less';

const MonthPicker = DatePicker.MonthPicker;

const labels = messages.orders.transportation;

const Component = (props: ListLayoutArgs<OrdersListContext>) => {

  const { context, bindings: { setContext } } = props;
  const { translate } = useIntl();
  const { transportationList: { listTransportationHistory } } = useApi();
  const { transportationHistoryId, transportationHistoryKey, transportationHistoryDate } = context;

  const onSetTransportationHistory = (id: number) => {
    setContext({ transportationHistoryId: id === transportationHistoryId ? undefined : id, selected: [] });
  };

  const orders = transportationHistoryId ? context.data : context.selected;

  const { samples, total } = getOrderTransportationSummary(orders);

  return (
    <Container className={'margin-top-1'}>

      <h3><Translate message={labels.history}/></h3>

      <MonthPicker
        allowClear={false}
        value={transportationHistoryDate}
        placeholder={translate(messages.general.filter.date)}
        onChange={transportationHistoryDate => setContext({ transportationHistoryDate, transportationHistoryKey: transportationHistoryKey + 1 })}
      />

      <Container className={'margin-top-1'}>
        <ApiRequest
          errorAsAlert
          request={() => listTransportationHistory({ year: transportationHistoryDate.year(), month: transportationHistoryDate.month() })}
          key={context.transportationHistoryKey}
          children={({ data, next }) => {
            if (data.length === 0) {
              return (
                <Container>
                  <Translate message={messages.general.list.noResults}/>
                </Container>
              );
            }
            return (
              <ul>
                {data.map(transportation => (
                  <li key={transportation.tid}>
                    <Checkbox onChange={() => onSetTransportationHistory(transportation.tid)} checked={transportation.tid === transportationHistoryId}>
                      <FormatDate date={transportation.created_at}/>
                    </Checkbox>
                  </li>
                ))}
              </ul>
            );
          }}
        />
      </Container>

      {orders.length > 0 && (
        <>
          <h3><Translate message={labels.summary}/></h3>
          <ul className={'transportation-samples'}>
            {map(samples, (count, name) => (
              <li key={name}>
                <span className={'transportation-label'}>{name}</span>
                <span className={'transportation-value'}>{count}</span>
              </li>
            ))}
            <li className={'transportation-total'}>
              <span className={'transportation-label'}><Translate message={labels.total}/></span>
              <span className={'transportation-value'}>{total}</span>
            </li>
          </ul>
        </>
      )}

    </Container>
  );
};

export const TransportationSection: ListLayoutSideBarHiddenSectionCallback<OrdersListContext> = args => ({
  active: args.context.mode === OrderListMode.Transport,
  component: Component,
});
