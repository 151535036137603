import React from 'react';
import { Control, ControlProps } from 'components/Control';
import { Input } from 'antd';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useOrdersContext } from 'modules/orders/providers';
import { useConfirmModal } from 'components';
import { useIntl } from 'providers';

type EditBarcodeControlProps = Omit<ControlProps, 'onClick'> & {
  tnr: string;
  onDone?: (tnr: string) => void;
};

const labels = messages.orders.wizard.barcodes;

export const EditBarcodeControl = (props: EditBarcodeControlProps) => {

  const { tnr, onDone } = props;

  const { setOrderProperties } = useOrdersContext();

  const { translate } = useIntl();
  const confirmModal = useConfirmModal();

  const performEdit = () => {
    let editedTnr = tnr;

    return new Promise<string>((resolve) => {
      const perform = () => confirmModal({
        title: translate(labels.edit.title),
        content: (
          <div>
            <Input
              minLength={100}
              autoFocus
              defaultValue={tnr}
              onChange={(e) => {
                editedTnr = e.target.value;
              }}
              placeholder={translate(labels.edit.placeholder)}
            />
          </div>
        ),
        onOk: async () => {
          setOrderProperties({ tnr: editedTnr });
          onDone?.(editedTnr);
          resolve(editedTnr);
        },
        onCancel: () => {
          resolve(tnr);
        },
      });

      perform();

    });
  };

  return (
    <Control
      icon={faPencil}
      onClick={performEdit}
    />
  );
};
