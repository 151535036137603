import React, { useRef } from 'react';
import { useApi } from 'providers';
import { Notification, NotificationStatus } from 'interfaces/api';
import { Container, Control, Drawer, Header, List, ListItem } from 'components';
import './styles.less';
import { ApiRequest } from 'containers';
import { Toggle } from 'hooks';
import { convertToListItem, notificationLocation } from 'modules/notifications/utils';
import { Dismiss } from 'modules/notifications/components/Controls/Dismiss';
import { useNotificationCount } from 'modules/notifications/providers/Notifications';
import messages from 'messages';
import { faCheckDouble, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Read } from 'modules/notifications/components/Controls/Read';
import NotificationSettings from 'modules/notifications/components/Settings/NotificationSettings';

type Props = {
  toggle: Toggle;
};

export default (props: Props) => {

  const { notifications: { listNotifications, dismissNotification, readAllNotifications, readNotification } } = useApi();

  const [toggled, toggle] = props.toggle;
  const { reloadNotificationCount, notificationCount } = useNotificationCount();

  const ref = useRef(null);

  const onItemClick = async (item: ListItem<Notification>, reload: any) => {
    if (item.meta.status === NotificationStatus.Unread) {
      await readNotification({ id: item.meta.id });
      await reloadNotificationCount();
    }
    reload();
    notificationLocation(item.meta);
    toggle(false);
  };

  return (
    <Drawer
      placement={'right'}
      closable={false}
      onClose={() => toggle(false)}
      open={toggled}
      getContainer={false}
      className={'notification-drawer'}
      width={427}
      rootStyle={{ position: 'absolute' }}
    >
      <ApiRequest
        key={notificationCount}
        request={() => listNotifications()}
        children={({ data, load }) => (
          <Container grow ref={ref}>
            <Header
              title={messages.general.topBar.notifications}
              maxVisibleControls={3}
              controls={[
                (
                  <NotificationSettings/>
                ),
                (
                  <Control
                    key={'dismiss'}
                    onClick={() => {
                      readAllNotifications()
                        .then(() => {
                          load();
                          reloadNotificationCount();
                        });
                    }}
                    icon={faCheckDouble}
                    label={messages.notifications.dismissAll}
                  />
                ),
                (
                  <Control
                    key={'close'}
                    onClick={() => toggle()}
                    icon={faTimes}
                    label={messages.general.close}
                  />
                ),
              ]}
            />
            <Container scrollY grow shrink className={'notification-list-wrapper'}>
              <List
                items={data.results.map(v => convertToListItem(v))}
                hideCaretOnSelect={true}
                className={'notification-list-container'}
                onItemClick={item => onItemClick(item, load)}
                getControls={(item) => {
                  return [item.meta.status === NotificationStatus.Unread
                    ? (
                      <Read
                        key={item.id}
                        readNotification={() => {
                          readNotification({ id: item.id as number })
                            .then(() => {
                              reloadNotificationCount();
                              load();
                            });
                        }}
                      />
                    )
                    : undefined, (
                    <Dismiss
                      key={item.id}
                      dismissNotification={() => {
                        dismissNotification({ id: item.id as number })
                          .then(() => {
                            reloadNotificationCount();
                            load();
                          });
                      }}
                    />
                  )];
                }}
              />
            </Container>
          </Container>
        )
        }
      />
    </Drawer>
  );
};
