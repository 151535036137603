import { Store as ReduxStore } from 'redux';
import { getStore, history, Store } from 'store';
import { LOCATION_CHANGE, RouterAction } from 'connected-react-router';
import queryString from 'query-string';
import { createRejectLegacyAction, createResolveLegacyAction, legacyLogin } from 'modules/auth/store/ducks/auth';
import { ExchangeLocationState } from 'modules/exchange';
import { ExchangeFileType, OrderChangeAction } from 'interfaces/api';
import { ReportLocationState } from 'modules/reports';
import { getWizardStateFromGetParams } from 'utils/helpers';
import { omit } from 'lodash';
import dayjs from 'dayjs';

export const legacyRoutesMiddleware = (() => (store: ReduxStore<Store>) => (next: any) => async (action: RouterAction) => {

  const { dispatch, getState } = getStore();

  if (action.type === LOCATION_CHANGE && !getState().auth.legacy) {

    const { pathname, search } = action.payload.location;

    const params = queryString.parse(search, { arrayFormat: 'bracket' });

    if (params.token) {
      // exchange token
      const state: any = {};
      try {
        await legacyLogin({ token: params.token })(dispatch, getState);
        dispatch(createResolveLegacyAction());
        history.replace({ pathname, state, search: queryString.stringify(omit(params, 'token')) });
      } catch (e) {
        dispatch(createRejectLegacyAction(e));
      }

    } else if (pathname.startsWith('/index')) {

      if (params.ID && params.key && params.url) {

        try {
          await legacyLogin({ aid: params.ID, key: params.key })(dispatch, getState);
        } catch (e) {
          dispatch(createRejectLegacyAction(e));
        }

        try {

          const el = document.createElement('a');
          el.href = 'http://localhost/' + decodeURIComponent((params.url as string).replace(/\+/g, ' '));

          const { func, cache, ...legacyParams } = queryString.parse(el.search);

          if (el.pathname === '/onlineauftrag/index.php') {

            if (func === 'neuerpoolauftrag' || func === 'neuerauftrag') {
              const params = getWizardStateFromGetParams(legacyParams);
              params.showWizard.logs.push({ action: OrderChangeAction.Gdt2OA, created_at: dayjs().toISOString() });
              history.replace('/oa', params);
            }

          } else if (el.pathname === '/labexchange/index.php') {

            const locationState: ExchangeLocationState = {};

            if (func === 'listFiles') {
              locationState.fileType = ExchangeFileType.LDT;
            } else if (func === 'listPDFFiles') {
              locationState.fileType = ExchangeFileType.PDF;
            } else if (func === 'sonstigefiles') {
              locationState.fileType = ExchangeFileType.OTHER;
            }

            history.replace('/le', locationState);

          }
        } catch (e) {
          dispatch(createResolveLegacyAction());
        }

      }

    } else if (pathname.startsWith('/onlinebefunde/launcher.php')) {

      if (params.loadaisimport && params.id && params.key) {
        const { key, id } = params;
        const loadAisImport = params.loadaisimport as string;
        const loadAisFirstname = params.aisfirstname as string;
        const loadAisLastname = params.aislastname as string;
        const loadAisBirthdate = params.aisbirthdate as string;
        const loadAisShowReport = (params.handle === 'befund');

        await legacyLogin({ key, aid: id })(dispatch, getState);

        try {
          if (loadAisImport === '1') {
            const locationState: ReportLocationState = {
              loadAisImport: { loadAisFirstname, loadAisLastname, loadAisBirthdate, loadAisShowReport },
            };

            history.replace('/ob', locationState);
          }
        } catch (e) {
          dispatch(createResolveLegacyAction());
        }
      }

    }
  }

  return next(action);

})();
