import { useEffect, useState } from 'react';
import { Keyboard } from '@capacitor/keyboard';
import { KeyboardInfo } from '@capacitor/keyboard/dist/esm/definitions';
import { useEnv } from 'providers/EnvProvider';

export const useKeyboard = () => {

  const [info, setInfo] = useState<KeyboardInfo>();
  const { isNative } = useEnv();

  useEffect(() => {

    if (isNative) {

      Keyboard.addListener('keyboardWillShow', (info) => {
        setInfo(info);
        document.body.classList.add('keyboard-will-show');
      });

      Keyboard.addListener('keyboardDidShow', (info) => {
        setInfo(info);
        document.body.classList.add('keyboard-open');
        document.body.classList.remove('keyboard-will-show');
      });

      Keyboard.addListener('keyboardWillHide', () => {
        document.body.classList.add('keyboard-will-hide');
        document.body.classList.remove('keyboard-open');
      });

      Keyboard.addListener('keyboardDidHide', () => {
        document.body.classList.remove('keyboard-will-hide');
      });

    }

    return () => {
      if (isNative) {
        Keyboard.removeAllListeners();
      }
    };

  });

  return info;

};
