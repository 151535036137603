import {
  AdvancedAlphanumericBarcodeUseState,
  DeviceStatus,
  DoctorBarcodeMode,
  ExchangeFileUpdateModes, FormatPriceDecimalType,
  LanguageCode,
  LDT2ModeLaboratoryGroupIdentificationTypes,
  LDT3ModeLaboratoryGroupIdentificationTypes,
  LinkPlatform,
  MailTemplateType,
  OrderExportTrigger,
  OrderRuleActionType,
  OrderRuleConditionType,
  OriginalReportDisplayValue,
  ReportAccessFields,
  ReportsQuickAccessConfigOrderNumberType,
  ShortCodeMode,
  UserLogAction,
  UserStatus,
  UserType,
} from 'interfaces/api';

export default {
  pages: {
    users: 'User Management',
    productFeatures: 'Products & Features',
    groups: 'Groups',
    laboratoryGroups: 'Laboratory Groups',
    doctorGroups: 'Doctor Groups',
    designer: 'Report Designer',
    labconnect: 'Labconnect',
    print: 'Report Print',
    settings: 'Settings',
    server: 'Server',
  },
  generalSettings: 'General Settings',
  doctorSettings: 'Doctor Settings',
  patientSettings: 'Patient Settings',
  preferences: 'Preferences',
  laboratoryGroupBarcodes: 'Laboratory group barcodes',
  advancedAlphanumericBarcodes: 'Advanced alphanumeric barcodes',
  realReRequest: 'Real re-request',
  userPreferences: {
    reset: {
      label: 'Reset Defaults',
      confirm: {
        title: 'Attention!',
        content: 'Do you really want to reset the user preferences?',
      },
    },
  },
  users: {
    total: 'Total',
    enabled2fa: '2FA enabled',
    apiDocs: 'API Docs enabled',
    status: {
      title: 'User Status',
      type: {
        [UserStatus.Ok]: 'Ok',
        [UserStatus.Initial]: 'Initial',
        [UserStatus.Inactive]: 'Inactive',
        [UserStatus.AutoLocked]: 'Auto locked',
        [UserStatus.ManLocked]: 'Manually locked',
        [UserStatus.Queued]: 'Queued',
      },
    },
    sections: {
      general: 'General',
      meExport: 'Export ME Format',
    },
    tabs: {
      detail: 'Base Data',
      reportDetails: 'Report Details',
      products: 'Products & Features',
      overtaker: 'Overtake Settings',
      permissions: 'Permissions',
      additionalSettings: 'Additional Settings',
      doctorGroups: 'Doctor Groups',
      devices: 'Devices',
      lanr: 'Lanr',
      misc: 'Miscellaneous',
      logs: 'Logs',
    },
    setReportsRead: {
      label: 'Set reports read',
      endDate: 'End date',
      setDate: 'Set date',

    },
    devices: {
      notifications: {
        success: 'Saved settings successfully',
        error: 'An error occurred',
      },
      form: {
        label: 'Device ID',
        columns: {
          note: 'Note',
          active: 'Active',
          date: 'Date',
        },
        controls: {
          delete: {
            label: 'Delete device',
            notifications: {
              confirm: 'Confirm delete',
              success: 'Deleted device successfully',
            },
          },
        },
      },
    },
    patient: {
      insuranceNumberShort: 'Ins. Nr.',
      pident: 'Pident',
      insuranceNumber: 'Insurance Number',
      birthDate: 'Birth date',
    },
    doctors: {
      reportLanguage: {
        [LanguageCode.DE]: 'German',
        [LanguageCode.EN]: 'English',
        [LanguageCode.FR]: 'French',
        [LanguageCode.HU]: 'Hungarian',
        [LanguageCode.NL]: 'Durch',
        [LanguageCode.IT]: 'Italian',
      },
    },
    form: {
      labels: {
        username: 'Username',
        email: 'E-Mail',
        password: 'Password',
        displayName: 'Display Name',
        hl7Name: 'HL7-Name',
      },
    },
    logActions: {
      [UserLogAction.Login]: 'Logged in',
      [UserLogAction.Logout]: 'Logged out',
      [UserLogAction.WrongPassword]: 'Wrong Password',
      [UserLogAction.LegacyLogin]: 'Legacy Login',
      [UserLogAction.LockedAccount]: 'Locked Account',
      [UserLogAction.PasswordReset]: 'Password Reset',
      [UserLogAction.Activated]: 'Activated',
      [UserLogAction.Reactivated]: 'Reactivated',
      [UserLogAction.ResentWelcomeMail]: 'Resent Welcome Mail',
      [UserLogAction.Deactivated]: 'Deactivated',
      [UserLogAction.EmailChanged]: 'Changed E-Mail',
      [UserLogAction.PhoneChanged]: 'Changed phone number',
      [UserLogAction.UserNameChanged]: 'Changed Username',
      [UserLogAction.PasswordChanged]: 'Changed Password',
      [UserLogAction.CanViewDiagnosis]: 'Can view diagnosis',
      [UserLogAction.CannotViewDiagnosis]: 'Cannot view diagnosis',
      [UserLogAction.DeviceRegistered]: 'Device registered',
      [UserLogAction.DeviceApproved]: 'Device approved',
      [UserLogAction.GeneratedDeviceToken]: 'Generated Device Token',
      [UserLogAction.Initialized2fa]: 'Initialized 2FA',
      [UserLogAction.Enabled2fa]: 'Enabled 2FA',
      [UserLogAction.Disabled2fa]: 'Disabled 2FA',
      [UserLogAction.Rejected2fa]: 'Rejected 2FA',
    },
    controls: {
      add: {
        label: {
          [UserType.LAB]: 'Add Laboratory',
          [UserType.LAU]: 'Add Laboratory User',
          [UserType.ARZ]: 'Add Doctor',
          [UserType.ADM]: 'Add Administrator',
          [UserType.SAD]: 'Add Administrator',
          [UserType.PAT]: 'Add Administrator',
        },
        notifications: {
          success: 'Added user successfully',
        },
      },
      edit: {
        label: 'Edit User',
        notifications: {
          success: 'Edited user successfully',
        },
      },
      activateUser: {
        label: 'Activate User',
        notifications: {
          success: 'Activated user successfully',
        },
        confirm: {
          title: 'Do you really want to activate this user?',
          content: 'The user will get an email',
        },
      },
      enableDebug: {
        label: 'Enable Debug',
        notifications: {
          success: 'Activated user debug',
        },
      },
      apiDocs: {
        enable: 'Enable Api Docs',
        disable: 'Disable Api Docs',
      },
      disableTwoFactor: {
        label: 'Disable 2FA',
        confirm: {
          title: 'Do you really want to disable 2FA for this user?',
        },
        notifications: {
          success: '2FA deactivated',
        },
      },
      reactivateUser: {
        label: 'Reactivate User',
        notifications: {
          success: 'Reactivated user successfully',
        },
        confirm: {
          title: 'Do you really want to reactivate this user?',
          content: 'The user will get an email',
        },
      },
      deactivateUser: {
        label: 'Deactivate User',
        notifications: {
          success: 'Deactivated user successfully',
        },
        confirm: {
          title: 'Do you really want to deactivate this user?',
        },
      },
      resendWelcomeMail: {
        label: 'Resend Welcome E-Mail',
        notifications: {
          success: 'Sent welcome e-mail successfully',
        },
        confirm: {
          title: 'Do you really want to resend the welcome mail to this user?',
        },
      },
      changeUsername: {
        label: 'Change Username',
        notifications: {
          success: 'Changed username successfully',
        },
        form: {
          labels: {
            username: 'Username',
          },
        },
      },
      changePassword: {
        label: 'Change Password',
        notifications: {
          success: 'Changed password successfully',
        },
        form: {
          labels: {
            password: 'New Password',
            confirm: 'Confirm Password',
          },
          validation: {
            passwords: 'The two passwords are inconsistent',
          },
        },
      },
      exchangeExportSettings: {
        label: 'Exchange Export Settings',
        addDoctorBtn: 'Add doctor',
        tabs: {
          own: 'Own settings',
          notOwn: 'Other settings',
        },
        form: {
          filenameLa: 'Filename (LA)',
          filenameLg: 'Filename (LG)',
          labTargetDir: 'Lab target directory',
          labGrpTargetDir: 'Lab group target directory',
          otherTargetDir: 'Other target directory',
          pdfTargetDir: 'PDF target directory',
          targetEncoding: 'File encoding',
        },
      },
      deleteDoctor: {
        label: 'Delete User',
        notifications: {
          success: ' User successfully deleted',
          error: 'Something went wrong',
        },
        confirm: {
          title: 'Do you really want to delete this user?',
          content: 'The user will no longer be able to login.',
        },
        form: {
          labels: {
            delete: 'Delete',
          },
        },
      },
      deleteReports: {
        label: 'Delete Reports',
        notifications: {
          success: ' All reports successfully deleted',
          error: 'Something went wrong',
        },
        confirm: {
          title: 'Delete reports?',
          content: 'reports will be deleted',
        },
      },
      group: {
        title: 'Manage Groups',
        label: {
          add: 'Add to Group',
          remove: 'Remove from Group',
        },
        notifications: {
          success: 'Successfully updated',
          error: 'Something went wrong',
        },
      },
    },
  },
  reports: {
    nav: {
      blacklist: 'Blacklist',
      custompush: 'Custompush',
      details: 'Details',
      origialreport: 'Original Report',
      customsort: 'Custom Sort',
      quickScan: 'Quick Scan',
      quickAccess: 'Quick Access',
      analyses: 'Analyses',
      laboratoryGroupFilter: 'Laboratory Group Report Filter',
    },
    reportDisplay: 'Report Display',
    cumulativeDisplay: 'Cumulative Display',
    blacklist: {
      header: 'Blacklist Settings',
      title: 'Blacklist',
      // eslint-disable-next-line max-len
      description: 'You see in this collection all for the patient basically locked investigations. You can unlock the Reports individually for each patient. You can add and delete text.',
      add: 'Add test',
      test: 'Test',
      autolock: {
        title: 'Automatic Lock',
        switch: 'Lock reports automatically.',
      },
    },
    custompush: {
      header: 'Custompush Settings',
      title: 'Custompush',
      description: 'Here you can define shortcuts investigation, which were utilized for the Custom Push function.',
      add: 'Add test',
      test: 'Test',
    },
    analyses: {
      header: 'Analyses settings',
      title: 'Analyses',
      description: 'Here you can define analyses, which can enable or disable PDF download.',
      enabledMode: 'Enabled Mode',
      add: 'Add analysis',
      analysis: 'Analysis',
      pathological: 'Pathological',
      enable: 'Enable/disable download',
    },
    laboratoryGroupFilters: {
      header: 'Laboratory Group Filter Settings',
      title: 'Laboratory Group Filter',
      description: 'Here you can define laboratory group filters, which you can use in report list.',
      add: 'Add laboratory group filter',
      group: 'Laboratory Group',
      name: 'Name',
      color: 'Color',
    },
    accessStats: {
      title: 'Statistics',
      total: 'Total',
      today: 'Today',
      last7: 'Last 7 days',
      last30: 'Last 30 days',
    },
    quickScan: {
      header: 'Report Quick Scan',
      settingsTitle: 'Settings',
      enable: 'Enable',
      manualCode: 'Enable manual code entry',
      multiTargetsEnabled: 'Multiple targets',
      partialReportsEnabled: 'Enable partial reports',
      path: 'Path',
      target: 'Target',
      addTarget: 'Add Target',
      highlightPathological: 'Highlight header for pathological reports',
      analyses: {
        label: 'Analyses',
        analysis: 'Analysis',
        value: 'Value',
        highlight: 'Highlight',
        positive: 'Positive (red)',
        negative: 'Negative (green)',
        // eslint-disable-next-line max-len
        description: 'The value of first found analysis will be shown in the quick scan header. If a value is provided and matches the on in the report, the highlight color will be used.',
      },
    },
    quickAccess: {
      header: 'Report Quick Access',
      disabled: 'Report Quick Access is disabled. Enable it via .env file.',
      description: 'Here you can define the fields that are asked when a guest wants to quick access a report',
      add: 'Add Field',
      addPath: 'Add Path',
      field: 'Field',
      fieldsTitle: 'Mandatory fields',
      settingsTitle: 'Settings',
      enable: 'Enable',
      multiPathsEnabled: 'Multiple target paths',
      enableMailNotification: 'Enable E-Mail Notifications',
      partialReportsEnabled: 'Enable partial reports',
      path: 'Path',
      target: 'Target',
      reportMaxAge: 'Report Max Age',
      privacyUrl: 'Privacy Url',
      privacyUrlHint: 'This will overwrite the target ENV var REACT_APP_PRIVACY_URL. If none of both is set, no privacy link will be provided.',
      orderNumberType: {
        label: 'Type',
        options: {
          [ReportsQuickAccessConfigOrderNumberType.OrderNumber]: 'Order Number',
          [ReportsQuickAccessConfigOrderNumberType.ExternalNumber]: 'External Order Number',
          [ReportsQuickAccessConfigOrderNumberType.Both]: 'Order Or External Number',
        },
      },
      orderNumber: 'Order Number',
      orderNumberLength: 'Length',
      orderNumberNumericOnly: 'Numeric values only',
      orderNumberPrefix: 'Prefix',
      orderNumberSuffix: 'Suffix',
      useOrderNumberPrefix: 'Use Prefix',
      useOrderNumberSuffix: 'Use Suffix',
      levenshtein: {
        header: 'Levenshtein Configuration',
        notAvailable: 'Levenshtein is not available right now. Please ask your admin to install postgresql-contrib and enable the fuzzystrmatch extension',
        enable: 'Enable Levenshtein Comparison',
        distance: 'Maximum Distance',
        insertCost: 'Insert Cost',
        deleteCost: 'Delete Cost',
        substituteCost: 'Substitute Cost',
      },
      pathHint: 'e.g. "befundabfrage" will enable http://localhost/befundabfrage. to enable quick access on root url for a certain domain, enter e.g. http://127.0.0.1:8081',
      fields: {
        [ReportAccessFields.Firstname]: 'First name',
        [ReportAccessFields.Lastname]: 'Last name',
        [ReportAccessFields.Birthday]: 'Birthday',
        [ReportAccessFields.OrderNumber]: 'Order Number',
        [ReportAccessFields.Zip]: 'Zip',
      },
    },
    customsort: {
      title: 'Custom Sort',
      entries: 'Entries',
      description: 'Here you can customize the sort of the tests on the reports.',
      columns: {
        methode: 'Methode',
        description: 'Description',
        group: 'Group',
      },
      controls: {
        search: 'Search',
        pagination: {
          loadNext: 'Load next',
          loadPrevious: 'Load previous',
        },
        upload: {
          title: 'Upload CSV File',
          submit: 'Upload now',
          withHeader: 'First line is header',
        },
        download: {
          title: 'Download CSV File',
        },
      },
      bdwEnabled: 'Enable in BDW',
      cumulativeEnabled: 'Enabled in Cumulative View',
      cumulativeGrouped: 'Groups enabled in cumulative view',
    },
    details: {
      header: 'Details Settings',
      title: 'Details',
      inputLabels: {
        title: 'Title',
        firstName: 'First Name',
        name: 'Name',
        suffix: 'Suffix',
        telefon: 'Phone',
        fax: 'Fax',
        notificationEmail: 'Notification Email',
        orderEmail: 'Order Email',
        webSite: 'Web Site',
        loginSite: 'Login Site',
        address: 'Address',
        city: 'City',
        postcode: 'Postcode',
        contactInfo: 'Contact Info (OA)',
        additional1: 'Additional 1',
        additional2: 'Additional 2',
        additional3: 'Additional 3',
      },
    },
    origialreport: {
      header: 'Original Report Settings',
      title: 'Original Report',
      description: 'Here you can control the priotity of the report display.',
      pdfExportSection: {
        title: 'PDF Settings',
        standard: {
          label: 'PDF Export Standard (HL7)',
          values: {
            [OriginalReportDisplayValue.ShowIfAvailable]: 'Show (if available)',
            [OriginalReportDisplayValue.NeverShow]: 'Never Show',
            [OriginalReportDisplayValue.ShowIfNothingElse]: 'Show (if nothing else)',
          },
        },
        original: {
          label: 'PDF Export Original Report (Base64)',
          values: {
            [OriginalReportDisplayValue.ShowIfAvailable]: 'Show (if available)',
            [OriginalReportDisplayValue.NeverShow]: 'Never Show',
            [OriginalReportDisplayValue.ShowIfNothingElse]: 'Show (if nothing else)',
          },
        },
      },
      bdwSection: {
        title: 'BDW Settings',
        standard: {
          label: 'BDW Standard (HL7)',
          values: {
            [OriginalReportDisplayValue.ShowIfAvailable]: 'Show (if available)',
            [OriginalReportDisplayValue.NeverShow]: 'Never Show',
            [OriginalReportDisplayValue.ShowIfNothingElse]: 'Show (if nothing else)',
          },
        },
        original: {
          label: 'BDW Original Report (Base64)',
          values: {
            [OriginalReportDisplayValue.ShowIfAvailable]: 'Show (if available)',
            [OriginalReportDisplayValue.NeverShow]: 'Never Show',
            [OriginalReportDisplayValue.ShowIfNothingElse]: 'Show (if nothing else)',
          },
        },
      },
    },
    notifications: {
      success: 'Saved settings successfully',
      error: 'An error occurred',
    },
  },
  exchange: {
    header: 'Labexchange Settings',
    import: {
      title: {
        ldt: 'LDT Import Settings',
        pdf: 'PDF Import Settings',
      },
      save: 'Save settings',
      importDir: {
        ldt: 'Import directory',
        pdf: 'PDF import directory',
      },
      sourceSection: 'Source files',
      subDirSection: 'Subdirectories',
      ldt2section: 'LDT2 settings',
      ldt3section: 'LDT3 settings',
      archiveDir: {
        ldt: 'LDT archive directory',
        pdf: 'PDF archive directory',
      },
      sourceEncoding: 'File encoding',
      ldt2ModeLaboratoryGroupIdentification: {
        title: 'LDT2 laboratory group identifier',
        values: {
          [LDT2ModeLaboratoryGroupIdentificationTypes.Ident8000]: 'Identify with 8000',
          [LDT2ModeLaboratoryGroupIdentificationTypes.Ident8300]: 'Identify with 8300',
          [LDT2ModeLaboratoryGroupIdentificationTypes.None]: 'No Identification',
        },
      },
      ldt2ModeLaboratoryGroupIdentificationValueList: 'LDT2 Identification value list',
      fileUpdateMode: {
        title: {
          ldt: 'LDT file update mode',
          pdf: 'PDF file update mode',
        },
        values: {
          [ExchangeFileUpdateModes.NoFileUpdate]: 'No file update',
          [ExchangeFileUpdateModes.UpdateDependingTNR]: 'Update depending TNR',
          [ExchangeFileUpdateModes.UpdateDependingTNRandLG]: 'Update depending TNR + LG',
        },
      },
      tnrFieldIdentification: 'TNR field identifier',
      ldt3ModeLaboratoryGroupIdentification: {
        title: 'LDT3 laboratory group identifier',
        values: {
          [LDT3ModeLaboratoryGroupIdentificationTypes.Ident7266]: 'Identify with 7266',
          [LDT3ModeLaboratoryGroupIdentificationTypes.DynObj]: 'Identify with DynObj and LDT field',
          [LDT3ModeLaboratoryGroupIdentificationTypes.None]: 'No Identification',
        },
      },
      ldt3ModeLaboratoryGroupIdentificationValueList: 'LDT3 Identification value list',
      ldt3Mode2ObjectAndField: 'LDT3 dynObj and field comma separated',
      directories: {
        add: 'Add directory',
        path: 'Path',
        description: 'You can define additional subdirectories for import.',
      },
      notifications: {
        success: 'Saved settings successfully',
        error: 'An error occurred',
      },
    },
  },
  order: {
    nav: {
      exportSettings: 'Export settings',
      mainMaterials: 'Main materials',
      data: 'Common Data',
    },
    rules: {
      header: 'Workflow Manager',
      name: 'Rule name',
      group: 'Group',
      description: 'Description',
      // eslint-disable-next-line id-denylist
      undefined: 'undefined',
      showAutomated: 'Show auto generated',
      conditions: {
        label: 'Conditions',
        add: 'Add condition',
        notOperator: 'Not operator',
        type: 'Condition type',
        is: 'is',
        isNot: 'is not',
        notSelected: 'Condition type not selected',
        types: {
          // [OrderRuleConditionType.ConditionSet]: 'Condition set',
          [OrderRuleConditionType.CostUnit]: 'Cost unit',
          [OrderRuleConditionType.Doctors]: 'Doctor',
          [OrderRuleConditionType.DoctorGroups]: 'Doctor Group',
          [OrderRuleConditionType.Gender]: 'Gender',
          [OrderRuleConditionType.Feature]: 'Feature',
          [OrderRuleConditionType.Form]: 'Form',
          [OrderRuleConditionType.FormType]: 'Form Type',
          [OrderRuleConditionType.Localization]: 'Localization',
          [OrderRuleConditionType.Product]: 'Product',
          [OrderRuleConditionType.Requirement]: 'Requirement',
          [OrderRuleConditionType.Status]: 'Status',
          [OrderRuleConditionType.UserType]: 'User Type',
        },
      },
      actions: {
        label: 'Actions',
        add: 'Add action',
        type: 'Action type',
        notSelected: 'Action type not selected',
        types: {
          [OrderRuleActionType.ShowText]: 'Show text',
          [OrderRuleActionType.ShowHint]: 'Show hint',
          [OrderRuleActionType.AddRequirement]: 'Add requirement',
          [OrderRuleActionType.RemoveRequirement]: 'Remove requirement',
          [OrderRuleActionType.MandatoryField]: 'Mandatory field',
          [OrderRuleActionType.StatusPrevention]: 'Prevent status',
          [OrderRuleActionType.Anamnesis]: 'Anamnesis',
          [OrderRuleActionType.FreetextMandatory]: 'Freetext mandatory',
          [OrderRuleActionType.DiagnosisMandatory]: 'Diagnosis mandatory',
          [OrderRuleActionType.IncompatibleRequirement]: 'Incompatible requirements',
          [OrderRuleActionType.WorkstationMandatory]: 'Workstation mandatory',
          [OrderRuleActionType.DialysisMandatory]: 'Dialysis mandatory',
        },
      },
      created_at: 'Created at',
      controls: {
        add: 'Add Rule',
        delete: 'Delete Rule',
      },
      preferences: {
        orderAutoRequirementHintRule: {
          label: 'Auto generate requirement hint rules',
          description: 'Include hints from ll_hinweis_zu_anf in modal after order execution',
        },
      },
    },
    export: {
      header: 'Export Settings',
      save: 'Save settings',
      fieldId: 'LDT Field ID',
      preAppendValue: 'Pre Append Value',
      notifications: {
        success: 'Saved settings successfully',
        error: 'An error occurred',
      },
      shortCodes: {
        [ShortCodeMode.ShortName]: 'Doctor short name',
        [ShortCodeMode.InternalCode]: 'Short code internal',
        [ShortCodeMode.ExternalCode]: 'Short code external',
      },
      exportTriggers: {
        [OrderExportTrigger.WaitForLaboratory]: 'Wait for lab',
        [OrderExportTrigger.TimeInMinutes]: 'Time in Minutes',
      },
      general: {
        cardTitle: 'General export settings',
        exportPath: 'Export path',
        requestPresentationLdtExport: 'Request presentation in export',
        requestPresentationOaAnswer: 'Request presentation in OAAnswer',
        ldtExportTrigger: 'Trigger for export',
        ldtExportDelayMin: 'Export delay in minutes',
      },
      ldt: {
        title: 'LDT Settings',
        furtherFields: 'Additional LDT export fields',
        patientPhone: 'Patient Phone',
        patientEmail: 'Patient E-Mail',
        crownRumpLength: 'Crown-rump length',
        nuchalTranslucency: 'Nuchal translucency',
        cycleLength: 'Cycle length',
        cycleDay: 'Cycle day',
        anamnesticInfo: 'Anamnestic information',
        bmi: 'BMI calculation export',
      },
      freeText: {
        title: 'Free text definition',
        defaultRequest: 'Default Request',
      },
      optionalFields: {
        title: 'Export optional LDT fields',
        add: 'Add field',
        export: 'export',
      },
      bak: {
        title: 'Microbiology',
        fields: 'Microbiology Fields',
        leftRight: 'Left right',
        operative: 'Operative',
        examination: 'Examination',
        examinationId: 'Examination ID',
        examinationText: 'Examination comment',
        material: 'Material',
        materialId: 'Material ID',
        materialText: 'Material comment',
        origin: 'Origin of material',
        originText: 'Origin comment',
        originSite: 'Site of sampling',
        notes: 'Notes',
      },
      veterinary: {
        title: 'Veterinary',
        fields: 'Veterinary fields',
        animalName: 'Animal name',
        animalBirthdate: 'Animal birthday',
        animalSpecies: 'Animal species',
        animalOwnerName: 'Owner name',
        animalOwnerInfo: 'Owner additional info',
        animalOwnerStreet: 'Owner street',
        animalOwnerHousenr: 'Owner house number',
        animalOwnerZip: 'Owner zip code',
        animalOwnerCity: 'Owner city',
        animalSize: 'Animal size',
        animalWeight: 'Animal weight',
        animalRace: 'Animal race',
        animalSpeciesOther: 'Other animal species',
        animalChipnr: 'Animal chip number',
        animalGender: 'Animal gender',
      },
      austrian: {
        tabTitle: 'Austria',
        fields: 'Austrian fields',
        title: 'Title',
        firstName: 'First name',
        lastName: 'Last name',
        birthday: 'Birthday',
        insuranceNumber: 'Insurance Number',
        country: 'Country',
        zip: 'Zip code',
        city: 'City',
        street: 'Street',
        houseNumber: 'House number',
        addressAdditional: 'Address additional info',
        employer: 'Employer',
        employerPlace: 'Place of employer',
        chargeType: 'Charge type',
      },
    },
    general: {
      realCancellation: 'Order cancellation',
      realCancellationDescription: 'Time span within which an order can be set to the cancelled status.',
      realCancellationTimeRange: 'Order Cancellation time range',
      reportsTimeRange: 'Reports time range',
      autocompleteEntries: 'Autocomplete Entries',
      alwaysShowBlocks: {
        label: 'Always show requirement blocks',
        description: 'If disabled, blocks will only be shown if the count of all requirements on screen exceed 100.',
      },
      blockSize: 'Wizard requirements block size',
      showPrePrint: 'Show Pre-print button',
      prePrintIsC2A: 'Pre-print button as c2a',
      showRoomNumber: 'Show room number',
      showRoomNumberDescription: 'Show the room number next to the doctors name in the order list',
      sampleInLaboratoryButton: {
        label: 'Sample in laboratory',
        description: 'Execute button is split up into execute and take sample in laboratory',
      },
      sampleInLaboratoryButtonFirst: 'Show sample in laboratory button first',
      hideAdditionalInfo: {
        label: 'Hide additional infos in wizard',
        description: 'Hides patient additional infos and order reason',
      },
      bakModalBlockButtons: {
        label: 'BAK Block Buttons',
        description: '',
      },
      disableHelgaImage: {
        label: 'Hide Helga images in modal views',
        description: '',
      },
      preferScheduleDateMode: {
        label: 'Prefer schedule date mode',
        description: 'Schedule date is mandatory, save and execute are swapped depending on schedule date.',
      },
      newOrderShowSelectPatient: {
        label: 'New order patient select modal initially opened',
        description: '',
      },
      searchDirectInput: {
        label: 'Direct Input in wizard search control',
        description: '',
      },
      showCostUnitSelect: {
        label: 'Show cost unit select',
        description: 'Shows a modal with cost unit select after a patient without cost unit is selected',
      },
      showWorkstationSelect: {
        label: 'Show workstation select',
        description: 'When executing the order without a workstation, a modal for workstation selection opens',
      },
      alwaysShowWorkstationSelect: {
        label: 'Always show workstation select',
        description: 'When executing the order the modal for workstation selection always opens',
      },
      rememberLastWorkstation: {
        label: 'Remember workstation',
        description: 'Selected workstation will be remembered and set by default',
      },
      popoverInvoiceTo: {
        label: 'Show invoice to select in popover',
        description: '',
      },
      separateInvoiceTo: {
        label: 'Separate invoice to costs',
        description: '',
      },
      popoverHideAnalyses: {
        label: 'Hide analyses info in popover',
        description: '',
      },
      printSettings: 'Print Settings',
      poolSettings: {
        title: 'Pool Settings',
        maxPoolSize: 'Maximum Pool Size ("Mega Pool")',
        showBeforeAfterDialysis: 'Show before or after dialysis in the wizard',
        beforeAfterDialysisMustBeSpecified: 'Before or after dialysis must be specified',
      },
      barcodeFactor: {
        title: 'Barcode Factor',
        defaultFactor: 'Default factor',
        showOriginalCount: {
          label: 'Don´t display factorized count',
          description: '',
        },
        sample: 'Sample',
        addSamples: 'Add Sample',
        factor: 'Factor',
      },
      printerMapping: {
        title: 'Printer Mapping',
        alias: 'Alias',
        override_printer_name: 'Drucker Name',
        override_barcode_printer_name: 'Barcode Drucker Name',
      },
      scheduleTimeEnabled: 'Enable schedule time',
      scheduleTimeInterval: 'Schedule time interval',
      scheduleTimeDefaultEnabled: 'Enable schedule default time',
      scheduleTimeDefault: 'Default schedule time',
      anamnesisShrinkMode: {
        label: 'Anamnesis form shrink mode',
        description: 'Uses smaller buttons, inputs and spacings in anamnesis form',
      },
      bakSettings: {
        title: 'BAK Settings',
        splitClinical: {
          label: 'Split each clinical requirement',
          description: 'Each clinical requirement gets its own barcode',
        },
        splitBak: {
          label: 'Split each microbiological requirement',
          description: 'Each microbiological requirement gets its own barcode',
        },
        multipleBakBarcodes: 'Barcode for each microbiological material',
        bakColor: 'Background color for microbiological requirements',
        bakFontColor: 'Font color for microbiological requirements',
      },
      sortPatientsDropdownByName: {
        label: 'Sort Patients in Dropdown By Name',
        description: '',
      },
      sortPoolPatientsByName: {
        label: 'Sort Pool Patients By Name',
        description: '',
      },
      copyToPatientActivationSwitch: {
        label: 'Activate copy to patient activation switch',
        description: '',
      },
    },
    printSettings: {
      groupLabel: {
        barcode: 'Barcode',
        form: 'Form',
      },
      barcodeMode: {
        label: 'Barcode mode',
        selectLabels: {
          [DoctorBarcodeMode.NoBarcodePrint]: 'No Barcode Printing',
          [DoctorBarcodeMode.PrintBarcodes]: 'Print Barcode',
          [DoctorBarcodeMode.PrintGboBarcodes]: 'GBO barcodes',
          [DoctorBarcodeMode.PrintFreeBarcodes]: 'Manually Entered Barcodes',
          [DoctorBarcodeMode.CentralBarcodes]: 'Central Barcodes',
        },
      },
      printRange: 'Print range',
      printRangeMin: 'Print range minimum',
      printRangeMax: 'Print range maximum',
      printPrefix: 'Print prefix',
      printPosition: 'Actual barcode',
      overwrite: 'Overvrite',
      useOwnRange: 'Use own range',
      useLaboratoryGroupRange: 'Use laboratory group range',
      availableCount: 'Available barcodes',
      nextBarcode: 'Next barcode',
      socketPrint: 'Socket-Print',
      statusHints: {
        ok: 'There are more barcodes available than the minimum value used',
        warning: 'There are fewer barcodes available than the minimum value used',
        error: 'There are fewer barcodes available than the half of the minimum value used',
      },
    },
    laboratoryGroupBarcodeSettings: {
      printRange: 'Print range',
      printRangeMin: 'Print range minimum',
      printRangeMax: 'Print range maximum',
      printPrefix: 'Print prefix',
      printPosition: 'Actual barcode',
      overwrite: 'Overvrite',
    },
    advancedAlphanumericBarcodeSettings: {
      noneAvailable: 'No barcode settings available',
      useState: 'Use state',
      firstPart: 'First part of barcode',
      secondPart: 'Second part of barcode',
      thirdPart: 'Third part of barcode',
      useStateLabels: {
        [AdvancedAlphanumericBarcodeUseState.USE]: 'In use',
        [AdvancedAlphanumericBarcodeUseState.REG]: 'Prepared / Blocked',
      },
    },
    realReRequestSettings: {
      mtlMail: 'MTL E-mail address',
      useLgMail: 'Use laboratory group mail address if a different lab group is set on the form',
      orderTimeframe: 'Order manipulation time window (in days)',
      reRequestLimit: 'Maximum limit in hours',
      reRequestBuffer: 'Execution buffer in minutes',
      reRequestApprove: 'Additional requirements are to be approved',
      reRequestNoExport: 'No LDT export after additional requirements',
      reRequestLimitSampleDate: 'Calculate the real re-request time limit based on the sample date',
    },
    data: {
      header: 'Data settings',
      save: 'Save settings',
      tabs: {
        requirements: 'Requirements',
        analyses: 'Analyses',
        materials: 'Materials',
        mainMaterials: 'Main materials',
      },
      labels: {
        requirements: {
          controls: {
            edit: {
              label: 'Edit requirement',
              notifications: {
                success: 'Edited requirement successfully',
              },
            },
          },
          columns: {
            requirement: 'Requirement',
            shortName: 'Short Name',
            externalName: 'External Name',
            alias: 'Alias',
            rerequestLimit: 'Rerequest Limit',
          },
        },
        analyses: {
          controls: {
            edit: {
              label: 'Edit analysis',
              notifications: {
                success: 'Edited analysis successfully',
              },
            },
          },
          columns: {
            sampleType: 'Sample Type',
            material: 'Material',
            amount: 'Amount',
          },
        },
        mainMaterials: {
          header: 'Main material settings',
          save: 'Save settings',
          controls: {
            add: {
              label: 'Add main material',
              notifications: {
                success: 'Added main material successfully',
              },
            },
            edit: {
              label: 'Edit main material',
              notifications: {
                success: 'Edited main material successfully',
              },
            },
            delete: {
              label: 'Delete main material',
              notifications: {
                confirm: 'Confirm delete',
                success: 'Deleted main material successfully',
              },
            },
          },
          notifications: {
            success: 'Saved settings successfully',
            error: 'An error occurred',
          },
          create: {
            cardTitle: 'Create main material',
            name: 'Name',
          },
          tabs: {
            detail: 'Detail',
          },
          labels: {
            id: 'Id',
            name: 'Name',
            noImage: 'Image not set',
            sampleImageSmall: 'Small sample image',
            sampleImageLarge: 'Large sample image',
            manufacturer: 'Manufacturer',
            unit: 'Unit',
            quantity: 'Quantity',
            sampleType: 'Sample type',
            substituteSampleTypes: 'Substitute sample types',
            sampleTypeLabel: 'Label',
            order: 'Order',
            laboratoryGroupShortCode: 'Lab group',
            comment: 'Comment',
            notice: 'Notice',
            upload: 'Click to upload',
          },
        },
        materials: {
          controls: {
            edit: {
              label: 'Edit material',
              notifications: {
                success: 'Edited material successfully',
              },
            },
          },
          columns: {
            sampleType: 'Sample Type',
            limit: 'Rerequest Limit',
          },
        },
      },
      notifications: {
        success: 'Saved settings successfully',
        error: 'An error occurred',
      },
      controls: {
        search: 'Search',
        requirement: {
          edit: {
            label: 'Edit requirement',
            form: {
              tabs: {
                general: 'General',
                price: 'Price',
                others: 'Others',
                additionalText: 'Additional Text',
              },
              shortName: 'Short name',
              externalName: 'External name',
              duration: 'Duration',
              priority: 'Priority',
              lisExport: 'LIS export',
              isCharged: 'Charge is active',
              isEbmCharged: 'Is EBM charged',
              laboratoryGroup: 'Laboratory group',
              freeTextAllowed: 'Freetext allowed',
              freeTextMandatory: 'Freetext mandatory',
              freeTextAsDate: 'Freetext as date',
              freeTextKey: 'Freetext LDT key',
              freeTextPreappendValue: 'Freetext preappand value',
              alias: 'Alias',
              infoUrl: 'Info Url',
              longName: 'Name',
              limit: 'Limit',
              priceGroups: {
                group1: {
                  price: 'Price 1',
                  maximumPrice: 'Maximum price 1',
                  maximumPriceComment: 'Maximum price comment 1',
                },
                group2: {
                  price: 'Price 2',
                  maximumPrice: 'Maximum price 2',
                  maximumPriceComment: 'Maximum price comment 2',
                },
                group3: {
                  price: 'Price 3',
                  maximumPrice: 'Maximum price 3',
                  maximumPriceComment: 'Maximum price comment 3',
                },
                group4: {
                  price: 'Price 4',
                  maximumPrice: 'Maximum price 4',
                  maximumPriceComment: 'Maximum price comment 4',
                },
                group5: {
                  price: 'Price 5',
                  maximumPrice: 'Maximum price 5',
                  maximumPriceComment: 'Maximum price comment 5',
                },
              },
              ebmPrice: 'EBM price',
              notifications: {
                success: 'Updated requirement successfully',
              },
              others: {
                requirementReplace: {
                  groupLabel: 'Requiremenet Replace Method',
                  buttonLabel: 'Replace Requirement Associations',
                  modalTitle: 'Enter a unique requirement short name (e.g. CHOL)',
                  // eslint-disable-next-line max-len
                  modalDescription: 'This function allows you to quickly and easily manipulate the current requirement assignment. By entering the unique abbreviation of the replacement requirement, all assignments of the current requirement are cancelled and assigned to the replacement requirement. The following assignments are adjusted: Slips, Quick Selections, Requirement Groups, Group Slip, Rules. ',
                  success: 'Requirement associations successfully replaced',
                  error: 'An error occurred while replacing requirement associations',
                },
                requirementFieldsOutput: {
                  groupLabel: 'Requiremenet Fields Output',
                },
              },
            },
          },
          delete: {
            label: 'Delete requirement',
            notifications: {
              confirm: 'Confirm delete',
              success: 'Deleted requirement successfully',
            },
          },
          add: {
            label: 'Add requirement',
            notifications: {
              success: 'Added requirement successfully',
            },
          },
        },
        analyses: {
          edit: {
            form: {
              amount: 'Amount',
            },
          },
        },
        materials: {
          edit: {
            form: {
              sampleType: 'Sample Type',
              limit: 'Rerequest Limit',
            },
          },
        },
        pagination: {
          loadNext: 'Load next',
          loadPrevious: 'Load previous',
        },
        filter: {
          common: {
            laboratoryGroup: 'Group',
            submitter: 'Submitter',
            source: 'Source',
            material: 'Material',
            sampleType: 'Sample Type',
            selectOne: 'Select',
          },
          requirements: {
            material: {
              _1: 'Without material',
              _2: 'Without main material',
            },
            source: {
              _1: 'Self defined',
              _2: 'Imported',
            },
          },
          analyses: {
            _1: 'Without material',
            _2: 'Without main material',
          },
          materials: {
            _1: 'With sample type',
            _2: 'Without sample type',
          },
        },
      },
    },
  },
  products: {
    doctorsEnabled: 'Enabled for doctors',
    patientsEnabled: 'Enabled for patients',
    appEnabled: 'Enabled for app',
    explicitLock: 'Explicit Lock',
    description: {
      enableExplicit: 'This product is disabled by default for this user type.',
      disableExplicit: 'This product is enabled by default for this user type.',
    },
  },
  features: {
    description: {
      enableExplicit: 'This feature is disabled by default for this user type.',
      disableExplicit: 'This feature is enabled by default for this user type.',
      enabledAll: 'This feature is generally enabled for this user type.',
      disabledAll: 'This feature is generally disabled for this user type.',
    },
  },
  settings: {
    global: {
      header: 'Global Settings',
    },
    links: {
      title: 'Links',
      entries: 'Entries',
      showToc: 'Show Terms and Conditions',
      showPrivacy: 'Show Privacy Statement',
      showContact: 'Show Contact Info',
      description: 'You can define additional links to be shown in the right bottom of the screen.',
      add: 'Add Link',
      save: 'Save links',
      name: 'Name',
      href: 'Location',
      product: 'Product',
      platform: 'Platform',
      platforms: {
        [LinkPlatform.All]: 'All',
        [LinkPlatform.Web]: 'Web',
        [LinkPlatform.App]: 'App',
      },
      products: {
        all: 'Everywhere',
        login: 'Login',
        reportquickaccess: 'Report Quick Access',
      },
    },
    auth: {
      header: 'Auth Config',
      login: 'Login',
      autoLock: 'Auto lock',
      twoFactor: 'Two-Factor-Auth',
      timeouts: 'Timeouts',
      misc: 'Miscellaneous',
      authTokenExpiryTime: 'Auth token expiry time',
      resetTokenExpiryTime: 'Password reset token expiry time',
      smsVerificationTokenExpiryTime: 'SMS verification token expiry time',
      lcRegistrationTokenDefaultExpiryTime: 'LC registration token default expiry time in hours',
      throttleLoginCount: {
        label: 'Throttle login count',
        description: 'After how many wrong attempts account gets locked',
      },
      throttleLoginTime: {
        label: 'Throttle login time',
        description: 'Time in which wrong attempts get counted',
      },
      identifierLength: {
        label: 'Identifier length',
        description: 'Length without Server ID (e.g. 1Aabcd is 4)',
      },
      passwordPolicyPattern: {
        label: 'Password policy',
        description: 'Regular expression for password policy',
      },
      enablePasswordVisibilityToggleButton: 'Enable password toggle button',
      logWrongPasswordCleartext: 'Log wrong password in cleartext',
      deviceApproveBrowsers: 'Browsers have to be approved',
      enableAcceptToc: 'TOC (if available) have to be accepted',
      enableHideEmail: 'Hide E-Mail Fields',
      disableUserServerId: {
        label: 'Disable user server id',
        description: 'User names dont get prefixed with server id',
      },
      enableAlternateLogin: {
        label: 'Enable alternate login',
        description: 'Identifier can also be found in x_user.alternative_kennung (SVNR)',
      },
      twoFactorAuthEnabled: 'Enable 2FA',
      twoFactorAppDisabled: 'Disable 2FA in App',
      twoFactorAuthIdentifier: 'Authenticator Identifier',
      twoFactorShowInUserList: 'Show status in user list',
    },
    system: {
      header: 'System',
      maintenance: {
        notice: {
          title: 'Maintenance Notice',
          prefix: 'Title',
          message: 'Message',
          enabled: 'Enable Maintenance Notice',
        },
      },
      banner: {
        title: 'Banner',
        message: 'Message',
        enabled: 'Enable Banner',
      },
      search: {
        title: 'Global search config',
        autocompleteEntries: {
          label: 'Autocomplete entries',
          description: 'Set the number of patients and doctors in autocomplete list',
        },
      },
      priceFormat: {
        title: 'Price format',
        decimalType: {
          label: 'Decimal type',
          description: 'Set the decimal to either dot, comma or locale aware',
          types: {
            [FormatPriceDecimalType.LocalString]: 'Local aware',
            [FormatPriceDecimalType.Comma]: 'Comma decimal separator',
            [FormatPriceDecimalType.Dot]: 'Dot decimal separator',
          },
        },
      },
      regexSearch: {
        title: 'Regex search',
        tnr: {
          enable: 'Enable tnr regex search',
          description: 'Automatically switch to tnr search if regex matches',
          regex: 'Regular expression for tnr Search',
        },
        exttnr: {
          enable: 'Enable exttnr regex search',
          description: 'Automatically switch to exttnr search if regex matches',
          regex: 'Regular expression for exttnr Search',
        },
        caseNumber: {
          enable: 'Enable case number regex search',
          description: 'Automatically switch to case number search if regex matches',
          regex: 'Regular expression for case number Search',
        },
        insuranceNumber: {
          enable: 'Enable insurance number regex search',
          description: 'Automatically switch to insurance number search if regex matches',
          regex: 'Regular expression for insurance number Search',
        },
      },
    },
    hierarchy: {
      title: 'Hierarchy',
      member: 'Member',
      owner: 'Owner',
      type: 'Hierarchy Type',
      edit: {
        label: 'Edit user',
      },
      remove: {
        label: 'Remove user',
        confirm: {
          title: 'Do you really want to remove the user from this hierarchy entity?',
        },
      },
      addUser: {
        label: 'Add user',
        existingUser: 'Existing user',
        newUser: 'New user',
        assign: 'Assign user',
        create: 'Create user',
        notifications: {
          success: 'Added user successfully',
        },
      },
    },
    groups: {
      laboratory: {
        title: 'Laboratory Groups',
        labels: {
          shortIdentifier: 'Identifier',
          title: 'Title',
          phone: 'Phone',
          fax: 'Fax',
          url: 'URL',
          address: 'Address',
          city: 'City',
          zip: 'ZIP',
          add1: 'Addendum 1',
          add2: 'Addendum 2',
          add3: 'Addendum 3',
        },
      },
      doctor: {
        title: 'Doctor Groups',
        labels: {
          name: 'Group Name',
          members: 'Members',
          owners: 'Owners',
          oagrp: 'Online Order Group',
          addMember: 'Add Doctor',
          member: 'Member',
          owner: 'Owner',
          reportNotification: 'Report Notification',
        },
        errors: {
          invalidGroupId: 'The specified doctor group does not exist.',
        },
      },
      labels: {
        newGroup: 'New Group',
        deleteGroup: 'Delete Group',
        editGroup: 'Edit Group',
        confirm: 'Confirm',
        save: 'Save changes',
      },
      totalMembers: '{COUNT} total members',
      notifications: {
        create: {
          success: 'Successfully created new group!',
          error: 'Something went wrong',
        },
        update: {
          success: 'Changes saved',
          error: 'Something went wrong',
          nothingToUpdate: 'No changes',
        },
        delete: {
          confirm: 'Do you really want to delete this group?',
          success: 'Group deleted',
          error: 'Something went wrong',
        },
      },
    },
    overtaker: {
      description: 'Overtake Order Entry Settings',
      overtake: 'Overtake Settings From Selected Doctor',
      success: 'Settings overtake was successful',
      error: 'There was a problem during settings overtake',
      overtakeCertificateAssignment: 'Overtake certificates',
      overtakeGrpCertificateAssignment: 'Overtake group certificates',
      overtakeAwizButtons: 'Overtake wizard buttons',
      overtakeRequirementProfiles: 'Overtake requirement profiles',
    },
  },
  server: {
    info: {
      header: 'Server Info',
      env: 'Environment',
      system: 'System',
    },
    logs: {
      header: 'Logs',
    },
    jobs: {
      header: 'Jobs',
      run: {
        header: 'Run Jobs',
        system: {
          title: 'Run Backend Jobs',
          clearCache: {
            title: 'Clear System Cache',
          },
        },
        elastic: {
          title: 'ElasticSearch',
          requirements: 'Import Requirements',
          patients: 'Import Patients',
          doctors: 'Import Doctors',
          doctorTopRequirements: 'Top Doctors Requirements',
        },
        runNow: 'Run now',
        notifications: {
          success: 'Job completed',
        },
      },
      dashboard: {
        header: 'Dashboard',
      },
    },
  },
  mails: {
    header: 'E-Mails',
    smtp: 'SMTP',
    groups: 'Groups',
    templates: 'Templates',
    types: {
      [MailTemplateType.AcceptedOrder]: 'Accepted Order',
      [MailTemplateType.Activate]: 'Activate',
      [MailTemplateType.AutomaticReportSend]: 'Automatic Report Send',
      [MailTemplateType.ContactForm]: 'Contact Form',
      [MailTemplateType.SendReport]: 'Send Report',
      [MailTemplateType.SendReportCopyToMe]: 'Copy To Me',
      [MailTemplateType.DeviceIdActivated]: 'Device Id Activated',
      [MailTemplateType.DeviceIdDeactivated]: 'Device Id Deactivated',
      [MailTemplateType.MessageToLab]: 'Message to Lab',
      [MailTemplateType.NewDeviceId]: 'New Device Id',
      [MailTemplateType.NewOrderLS]: 'New Order',
      [MailTemplateType.NewPassword]: 'New Password',
      [MailTemplateType.NewReport]: 'New Report',
      [MailTemplateType.CriticalResult]: 'Critical result',
      [MailTemplateType.PathologicalResult]: 'Pathological result',
      [MailTemplateType.CustomPush]: 'Custom Push',
      [MailTemplateType.PasswordPdf]: 'PDF Password',
      [MailTemplateType.Reactivate]: 'Reactivate',
      [MailTemplateType.ResendWelcome]: 'Resend welcome',
      [MailTemplateType.ResetPassword]: 'Reset Password',
      [MailTemplateType.ChangeOrderRequest]: 'Request_for_req',
      [MailTemplateType.RequestToLab]: 'request_to_lab',
      [MailTemplateType.RequestToLabMobile]: 'request_to_lab_mobile',
      [MailTemplateType.RequestToLabMobileConfirmation]: 'request_to_lab_mobile_authenticate',
      [MailTemplateType.SampleInLaborNotification]: 'Sample in laboratory',
      [MailTemplateType.RealRerequestApplied]: 'Additional request(s) applied',
      [MailTemplateType.RealRerequestMade]: 'Additional request(s) accepted',
      [MailTemplateType.RealRerequestStatement]: 'Real re-request statement',
      [MailTemplateType.WebshopOrderConfirmation]: 'webshop_order_confirmation',
      [MailTemplateType.Notification]: 'New Notification',
    },
    typeGroups: {
      user: 'Users',
      reports: 'Reports',
      orders: 'Orders',
      general: 'General',
    },
  },
  devices: {
    form: {
      name: 'Name',
      note: 'Note',
      expiration: 'Expiration (in hours)',
    },
    controls: {
      create: {
        label: 'Create API Key',
        success: {
          title: 'API Authentication Token',
          content: 'This is your token. Please remember it.',
        },
      },
      note: {
        label: 'Set Device Note',
      },
      createLabconnect: {
        label: 'Labconnect registration',
        success: {
          title: 'Labconnect registration token',
          // eslint-disable-next-line max-len
          content: 'Use this token as password in the Labconnect installer. The token will not be displayed again, expires automatically and cannot be revoked. A new token can be created any time, previous tokens are valid until expiration.',
        },
      },
      approve: {
        label: 'Approve device',
        confirm: {
          title: 'Confirm approval',
          content: 'Do you really want to approve this device?',
        },
      },
      deny: {
        label: 'Deny device',
        confirm: {
          title: 'Confirm deny',
          content: 'Do you really want to deny this device?',
        },
      },
      revoke: {
        label: 'Revoke login',
        confirm: {
          title: 'Confirm revocation',
          content: 'Do you really want to revoke this device?',
        },
      },
    },
    lastUsed: 'last used {TIME}',
    neverUsed: 'never used',
    status: {
      [DeviceStatus.Approved]: 'Approved',
      [DeviceStatus.Pending]: 'Pending',
      [DeviceStatus.Denied]: 'Denied',
      [DeviceStatus.Revoked]: 'Revoked',
    },
  },
  cases: {
    general: {
      casesOrderListOpened: 'Order list opened',
      disabledReportLaboratoryGroups: 'Disabled report laboratory groups',
    },
  },
};
