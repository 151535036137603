import cx from 'classnames';
import { ControlMenu } from 'components';
import React from 'react';
import { AggregatedSuperRequirementProperties, OrderWizardRequirement } from 'interfaces/api';
import { filter, includes } from 'lodash';
import { isProfile } from 'modules/orders/containers/OrderWizard/utils.tsx';
import * as Controls from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/Controls';
import { useOrdersContext } from 'modules/orders/providers';
import { useBasketContext } from 'modules/orders/containers/OrderWizard/providers';

export const useRequirementHeaderControls = () => {

  const { immutableRequirementIds } = useOrdersContext();
  const { profileRequirementsPartialInBasket, getBasketCount } = useBasketContext();

  return (requirement: OrderWizardRequirement | AggregatedSuperRequirementProperties, profileId?: number) => {

    const isInBasket = isProfile(requirement) ? profileRequirementsPartialInBasket(requirement) : getBasketCount(requirement) > 0;

    return filter([
      !isProfile(requirement) && <Controls.TogglePin key={'pin'} requirement={requirement}/>,
      isProfile(requirement) && !isInBasket && <Controls.InstantExecution key={'execute'} requirement={requirement}/>,
      profileId && !includes(immutableRequirementIds, requirement.id) && <Controls.AddBasket key={'addBasket'} requirement={requirement} profileId={profileId}/>,
    ]);
  };

};

export const RequirementPopoverControlsMenu = (props: { requirement: OrderWizardRequirement | AggregatedSuperRequirementProperties; profileId?: number }) => {

  const { requirement, profileId } = props;
  const getControls = useRequirementHeaderControls();

  return (
    <ControlMenu
      controls={getControls(requirement, profileId)}
      className={cx('header-controls')}
    />
  );

};
