import React, { useMemo } from 'react';
import cx from 'classnames';
import './Field.less';
import { Message } from 'interfaces';
import { Translate } from 'providers';

export interface ListItemFieldProps {
  label?: Message;
  value: Message;
  flipped?: boolean;
  className?: string;
  narrow?: boolean;
  wide?: boolean;
}

export const ListItemField: React.FC<ListItemFieldProps> = (props) => {

  const { label, value, flipped, narrow, wide } = props;

  const className = useMemo(() => cx('list-item-field', props.className, {
    'list-item-field-flipped': flipped,
    'list-item-field-narrow': narrow,
    'list-item-field-wide': wide,
  }), [props.className, flipped, narrow, wide]);

  return useMemo(() => (
    <div className={className}>
      {label && <span className={'list-item-field-label'}><Translate message={label}/></span>}
      <span className={'list-item-field-value'}><Translate message={value}/></span>
    </div>
  ), [label, value, className]);

};
