import { Feature, Gender, GeneralReportFilterType, ReadStatusType, ReportCharacteristic, ReportStatus, UserType } from 'interfaces/api';
import { Filter } from 'components';
import messages from 'messages';
import { Color } from 'interfaces';

const filterLabels = messages.reports.filters;

export const ReportStatusExtendedFilter: Filter = {
  label: filterLabels.status,
  filter: value => ({ quickStatus: value }),
  items: [{
    value: 'preliminary',
    label: messages.reports.status[ReportStatus.Preliminary],
    flag: Color.Silver,
  }, {
    value: 'partial',
    label: messages.reports.status[ReportStatus.Partial],
    flag: Color.Yellow,
  }, {
    value: 'finalNonPatho',
    label: messages.reports.status.finalNonPatho,
    flag: Color.Green,
  }, {
    value: 'finalPatho',
    label: messages.reports.status.finalPatho,
    flag: Color.Red,
  }, {
    value: 'finalBak',
    label: messages.reports.status.finalBak,
    flag: Color.DarkGray,
    guard: { feature: Feature.ObBakModule },
  }],
};

export const ReportStatusFilter: Filter = {
  label: filterLabels.status,
  filter: value => ({ status: value }),
  items: [{
    value: ReportStatus.Preliminary,
    label: messages.reports.status[ReportStatus.Preliminary],
  }, {
    value: ReportStatus.Partial,
    label: messages.reports.status[ReportStatus.Partial],
  }, {
    value: ReportStatus.Final,
    label: messages.reports.status[ReportStatus.Final],
  }],
};

export const ReportLockedFilter: Filter = {
  label: filterLabels.locked.label,
  filter: value => ({ locked: value }),
  guard: { feature: Feature.Blacklist },
  items: [
    { value: 'true', label: filterLabels.locked.values.onlyLocked },
    { value: 'false', label: filterLabels.locked.values.onlyUnlocked },
  ],
};

export const ReportGenderFilter: Filter = {
  label: filterLabels.gender,
  filter: value => ({ gender: value }),
  items: [
    { value: Gender.Male, label: messages.general.gender[Gender.Male] },
    { value: Gender.Female, label: messages.general.gender[Gender.Female] },
  ],
};

export const ReportReadStatusFilter: Filter = {
  label: filterLabels.readStatus,
  filter: value => ({ unread: value }),
  guard: { permitted: [UserType.ARZ, UserType.LAB, UserType.PAT] },
  items: [
    { value: ReadStatusType.Read, label: messages.reports.readStatusType[ReadStatusType.Read] },
    { value: ReadStatusType.Unread, label: messages.reports.readStatusType[ReadStatusType.Unread] },
  ],
};

export const ReportCharacteristicsFilter: Filter = {
  label: filterLabels.characteristics,
  filter: value => ({ characteristic: value }),
  items: [
    {
      value: ReportCharacteristic.NotPathological,
      label: messages.reports.characteristics[ReportCharacteristic.NotPathological],
    },
    {
      value: ReportCharacteristic.Pathological,
      label: messages.reports.characteristics[ReportCharacteristic.Pathological],
    },
    {
      value: ReportCharacteristic.Extreme,
      label: messages.reports.characteristics[ReportCharacteristic.Extreme],
    },
    {
      value: ReportCharacteristic.DeltaCheck,
      label: messages.reports.characteristics[ReportCharacteristic.DeltaCheck],
      guard: { preference: 'reportsDeltaCheckFilter' },
    },
  ],
};

export const ReportTypeFilter: Filter = {
  label: filterLabels.type,
  filter: value => ({ reportType: value }),
  items: [
    {
      value: GeneralReportFilterType.Microbiological,
      label: messages.reports.reportType[GeneralReportFilterType.Microbiological],
      guard: { feature: Feature.ObBakModule },
    },
    {
      value: GeneralReportFilterType.Chemical,
      label: messages.reports.reportType[GeneralReportFilterType.Chemical],
    },
    {
      value: GeneralReportFilterType.Poct,
      label: messages.reports.reportType[GeneralReportFilterType.Poct],
      guard: { feature: Feature.Poct },
    },
  ],
};

export const ReportDateFromFilter: Filter = {
  label: messages.general.filter.dateFrom,
  filter: value => ({ dateFrom: value }),
  isDate: { disableFuture: true },
};

export const ReportDateToFilter: Filter = {
  label: messages.general.filter.dateTo,
  filter: value => ({ dateTo: value }),
  isDate: { disableFuture: true },
};
