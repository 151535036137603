import React from 'react';
import { Order } from 'interfaces/api';
import { Translate } from 'providers';
import messages from 'messages';
import { Card } from 'components/Card';

type Props = {
  order: Order;
};

export default React.memo((props: Props) => {

  const {
    tnr,
    exttnr,
    hospitalStation,
  } = props.order;

  const {
    caseNumber,
    patientIdentification,
    hospitalBedNumber,
    hospitalRoomNumber,
    hospitalProfession,
  } = props.order.patient;

  return (
    <Card className={'info-popover'} title={tnr}>
      {exttnr && (
        <span>
          <b><Translate message={messages.reports.details.laboratoryNumber}/></b>&nbsp;
          {exttnr}
        </span>
      )}
      {caseNumber && (
        <span>
          <b><Translate message={messages.orders.additionalFields.caseNumber}/></b>&nbsp;
          {caseNumber}
        </span>
      )}
      {patientIdentification && (
        <span>
          <b><Translate message={messages.orders.additionalFields.patientIdentification}/></b>&nbsp;
          {patientIdentification}
        </span>
      )}
      {hospitalStation && (
        <span>
          <b><Translate message={messages.orders.additionalFields.hospitalStation}/></b>&nbsp;
          {hospitalStation}
        </span>
      )}
      {hospitalProfession && (
        <span>
          <b><Translate message={messages.orders.additionalFields.hospitalProfession}/></b>&nbsp;
          {hospitalProfession}
        </span>
      )}
      {hospitalRoomNumber && (
        <span>
          <b><Translate message={messages.orders.additionalFields.hospitalRoomNumber}/></b>&nbsp;
          {hospitalRoomNumber}
        </span>
      )}
      {hospitalBedNumber && (
        <span>
          <b><Translate message={messages.orders.additionalFields.hospitalBedNumber}/></b>&nbsp;
          {hospitalBedNumber}
        </span>
      )}
    </Card>
  );

});
