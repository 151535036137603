import React, { useCallback } from 'react';
import { isFunction } from 'utils/helpers';
import { ErrorBoundary, ErrorBoundaryHelga, GuardConfig, useGuard } from 'containers';
import { useLogger } from 'providers';

export type RenderCallback<TArgs> = (args?: TArgs) => React.ReactNode;

export type WithContentOuterProps<TArgs = any> = {
  children?: RenderCallback<TArgs> | React.ReactNode;
  component?: React.ComponentType<Partial<TArgs>>;
  guard?: GuardConfig;
};

export type WithContentInnerProps<TArgs = any> = {
  renderContent: (args?: TArgs) => React.ReactElement;
};

export const withContent = <TArgs, P extends WithContentOuterProps<TArgs>>(ComposedComponent: React.ComponentType<P>)
  : React.ComponentType<Omit<P, keyof WithContentInnerProps<TArgs>>> => {

  return (props: P) => {
    const [renderContent, rest] = useRenderContent(props);
    return <ComposedComponent {...(rest as any)} renderContent={renderContent}/>;
  };

};

export const useRenderContent = <TArgs, P extends WithContentOuterProps<TArgs>>(props: P): [(args?: TArgs) => React.ReactNode, Omit<P, keyof WithContentOuterProps<TArgs>>] => {

  const logger = useLogger();

  const { component: InjectedComponent, children, guard, ...rest } = props;

  const guardCheck = useGuard();

  const renderContent = useCallback((args?: TArgs): React.ReactNode => {

    if (guard && !guardCheck(guard || {}, () => true)) {
      return null;
    }

    if (InjectedComponent) {
      return (
        <ErrorBoundary>
          <InjectedComponent {...args as any}>
            {children}
          </InjectedComponent>
        </ErrorBoundary>
      );
    } else {
      try {
        return isFunction(children) ? children(args as any) : children;
      } catch (error) {
        logger.error(error);
        return <ErrorBoundaryHelga error={error}/>;
      }
    }
  }, [InjectedComponent, children, guardCheck]);

  return [renderContent, rest];

};
